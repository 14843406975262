<template>
  <div
    :style="styles"
    class="ts-row"
  >
    <slot />
  </div>
</template>

<script>
export default {
  /* eslint-disable-next-line */
  name:'ts-row',
  props: {
    tsType: {
      type: String,
      default: '',
    },
    tsW: {
      type: [Number, String],
      default: 12,
    },
    tsJustify: {
      type: String,
      default: null,
    },
    tsAlign: {
      type: String,
      default: null,
    },
  },
  computed: {
    styles() {
      const w = `${this.tsW * 100 / 12}%`;
      const inlineStyles = {
        alignItems: this.tsAlign,
        justifyContent: this.tsJustify,
        display: this.tsType,
        width: w,
      };
      return inlineStyles;
    },
  },
};
</script>

<style lang="css">
.ts-row {
  clear: both;
  overflow: hidden;
}
</style>
