<template>
  <div
    id="dropdown"
    ref="dropdown"
  >
    <div class="dropdown">
      <span class="dropdown-arrow" />
      <div class="dropdown-body">
        <slot name="dropdown-body" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable-next-line */
  name: 'ts-dropdown',
};
</script>

<style scoped>
#dropdown {
  position: absolute;
  z-index: 9;
  margin-top: 17px;
}
.dropdown {
  z-index: 20;
  display: block;
  border-radius: 4px;
  background: #fff;
  padding: 20px 0px 10px 0px;
  box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.2);
}
.dropdown-arrow {
  width: 50px;
  height: 25px;
  position: absolute;
  top: -25px;
  left: 70%;
  transform: translateX(-50%);
  overflow: hidden;
}
.dropdown-arrow::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: white;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 100%;
  left: 70%;
  box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.2);
}
</style>
