import axios from 'axios';

export default defineNuxtPlugin(({ provide }) => {
  // Create a custom axios instance with a custom baseURL
  const fetchForStaticFiles = axios.create({
    baseURL: useRuntimeConfig().public.STATIC_CDN,
  });

  // Inject to context as $staticFetch
  provide('staticFetch', fetchForStaticFiles);
});
