<template>
  <div
    class="cms-modal"
    :style="cssVars"
  >
    <div class="cms-modal__overlay">
      <div class="cms-modal__container">
        <!-- Header -->
        <header class="cms-modal__header">
          <button
            class="close-icon"
            @click="$emit('closeModal')"
            @keyup.enter="$emit('closeModal')"
          >
            <ModalCloseIcon />
          </button>
          <div class="title-wrapper">
            <h4 class="title">
              <slot name="title"></slot>
            </h4>
          </div>
        </header>

        <!-- Body -->
        <div class="cms-modal__body">
          <slot name="body"></slot>
        </div>

        <!-- Footer -->
        <footer class="cms-modal__footer">
          <slot name="footer">
            <FormButton @click="$emit('closeModal')">{{ $t('forms.close') }}</FormButton>
          </slot>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalCloseIcon from '../../svg/ModalCloseIcon.vue';
import FormButton from '../../Forms/FormButton';

export default {
  name: 'CmsModal',
  components: {
    ModalCloseIcon,
    FormButton,
  },
  computed: {
    ...mapGetters({
      headingColor: 'getThemeHeadingColor',
      overlayColor: 'getThemeAlertShadowOverlay',
    }),
    buttonColorOverrides() {
      const color = this.headingColor;
      if (!color) {
        return {};
      }

      return {
        '--button-outline': color,
        '--button-primary-bg': color,
        '--button-primary-bg-hover': color,
      };
    },
    cssVars() {
      return {
        '--overlay-color': this.overlayColor,
      };
    },
    submitEnabled() {
      return this.email.length && this.fullName.length && this.message.length && this.recaptchaStatus.length;
    },
  },
};
</script>

<style scoped lang="scss">
$primary-dark: #333333;

.cms-modal {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--overlay-color);
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__container {
    min-width: 600px;
    max-height: calc(100vh - 1rem);
    background: #ffffff;
    z-index: 100;
    padding: 24px 40px;

    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 599px) {
      min-width: unset;
      min-height: unset;
      padding: 1rem;
    }

    & .cms-modal {
      &__header {
        flex: 1;
        margin-bottom: 24px;
        .title-wrapper {
          .title {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            margin-right: 24px;
            letter-spacing: 0.8px;
            color: $primary-dark;
          }
        }
        .modal__close {
          display: flex;
          justify-content: flex-end;
          margin-bottom: -24px;
        }
      }
      &__body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
      &__footer {
        flex: 1;
        margin-top: 24px;
      }
    }
  }
}
.close-icon {
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
}
</style>
