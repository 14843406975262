<template>
  <FocusTrap
    active
    :initial-focus="getInitialFocus"
  >
    <div id="home">
      <div
        id="browser-modal"
        role="region"
        aria-label="Unsupported Browser Warning"
      >
        <div class="modal-content">
          <button
            class="close-icon"
            aria-label="Close Browser Warning Dialog"
            @click="closeModal()"
            @keydown.esc.stop="closeModal()"
            @keydown.enter.stop="closeModal()"
            @keydown.space.stop="closeModal()"
          >
            <img
              src="@/assets/cms-close-icon.svg"
              alt=""
            />
          </button>
          <div class="modal-description">
            <h2>Looks like you're using an unsupported browser</h2>
            <p>
              To get the best user experience, we recommend using one of our supported browsers. Click one of the icons
              below to download a new browser.
            </p>
          </div>
          <div class="browser-icons">
            <div class="icon">
              <a
                href="https://www.google.com/chrome/"
                target="_blank"
                alt="Google Chrome Browser"
                @keydown.enter.stop="goToLink('https://www.google.com/chrome/')"
              >
                <img
                  src="@/assets/browser-icons/chrome.svg"
                  alt=""
                />
                <p>Chrome</p>
              </a>
            </div>
            <div class="icon">
              <a
                href="https://support.apple.com/downloads/safari"
                target="_blank"
                alt="Apple Safari Browser"
                @keydown.enter.stop="goToLink('https://support.apple.com/downloads/safari')"
              >
                <img
                  src="@/assets/browser-icons/safari.svg"
                  alt=""
                />
                <p>Safari</p>
              </a>
            </div>
            <div class="icon">
              <a
                href="https://www.mozilla.org/en-US/firefox/"
                target="_blank"
                alt="Mozilla Firefox Browser"
                @keydown.enter.stop="goToLink('https://www.mozilla.org/en-US/firefox/')"
              >
                <img
                  src="@/assets/browser-icons/firefox.svg"
                  alt=""
                />
                <p>Firefox</p>
              </a>
            </div>
            <div class="icon">
              <a
                href="https://www.microsoft.com/en-us/edge"
                target="_blank"
                alt="Microsoft Edge Download"
                @keydown.enter.stop="goToLink('https://www.microsoft.com/en-us/edge')"
              >
                <img
                  src="@/assets/browser-icons/edge.svg"
                  alt=""
                />
                <p>Edge</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { FocusTrap } from 'focus-trap-vue';

export default {
  name: 'BrowserWarning',
  components: { FocusTrap },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    getInitialFocus() {
      return this.$el.querySelector('.close-icon');
    },
    goToLink(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
#browser-modal {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(56, 49, 71, 0.9);
  .modal-content {
    text-align: center;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    width: 525px;
    border-radius: 4px;
    .modal-description {
      margin: 20px auto;
      max-width: 350px;
      h2 {
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: #333;
      }
    }
    .browser-icons {
      margin-bottom: 20px;
      .icon {
        display: inline-block;
        width: 120px;
        cursor: pointer;
        a {
          text-decoration: inherit;
          color: inherit;
        }
      }
    }
    .close-icon {
      float: right;
      cursor: pointer;
      background: none;
      border: none;
      padding: 5px;
    }
  }
}
</style>
