import Cookies from 'js-cookie';
import fallbackText from '../../store-helpers/fallback_component_text.json';

const LANG_CODE_DEFAULT = 'en';
const LANG_DEFAULT = 'English';

export const state = () => ({
  languages: [],
  language: LANG_DEFAULT,
  locale: LANG_CODE_DEFAULT,
  componentTranslations: fallbackText,
});

export const getters = {
  getLanguages(state) {
    return state.languages;
  },
  getLanguage(state) {
    return state.locale;
  },
  getLocale(state) {
    return state.locale;
  },
  getLanguageText(state) {
    return state.language;
  },
  getGlobalTranslations(state) {
    return state.componentTranslations.globals;
  },
  getComponentTranslation: (state) => (componentName) => {
    if (!componentName) {
      throw new Error('You must provide a valid component name');
    }
    const nameWithoutSpaces = componentName.replace(/\s+/g, '');
    return state.componentTranslations[nameWithoutSpaces];
  },
  getHeaderTitles(state) {
    return state.componentTranslations.header;
  },
};

export const mutations = {
  setLanguages(state, payload) {
    state.languages = payload;
  },
  setLanguage(state, payload) {
    state.locale = payload;
  },
  setLanguageText(state, payload) {
    state.language = payload;
  },
  setComponentTranslations(state, payload) {
    state.componentTranslations = {
      ...state.componentTranslations,
      ...payload,
    };
  },
  setHeaderTitles(state, payload) {
    state.componentTranslations.header = payload;
  },
};

export const actions = {
  async updateLangCode({ commit }, shouldReload) {
    const language = Cookies.get('language') || LANG_DEFAULT;
    const langCode = Cookies.get('langCode') || LANG_CODE_DEFAULT;
    commit('setLanguage', langCode);
    commit('setLanguageText', language);

    if (shouldReload) {
      location.reload();
    }
  },
  async setLangCookies({ dispatch }, { language, code, shouldReload }) {
    Cookies.set('language', language || LANG_DEFAULT);
    Cookies.set('langCode', code || LANG_CODE_DEFAULT);

    await dispatch('updateLangCode', shouldReload);
  },
};
