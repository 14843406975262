<template>
  <div class="result">
    <div class="events-info">
      <div class="tag-and-time">
        <a
          class="tag"
          :href="`${item.link}/events`"
        >
          {{ $t('search.events.tagName') }}
        </a>
        <div class="time-ago">
          {{ formattedTime }}
        </div>
      </div>
      <h3 class="date">
        {{ monthAndDayOnly }}
      </h3>
      <a
        :href="link"
        class="title"
      >
        {{ item.title }}
      </a>
      <p
        v-if="item.description"
        class="description"
      >
        <span v-html="item.description" />
      </p>
      <a
        :href="eventLocation"
        v-if="item.venue"
        class="location"
        target="_blank"
      >
        {{ item.venue }}
      </a>
      <div class="time">
        {{ timesOnly }}
      </div>
      <div
        v-if="calendarButtonAvailable"
        class="calendar-wrapper"
      >
        <button
          class="calendar"
          @click="toggleCalendarOptions"
        >
          <img
            src="~/assets/calendar.svg"
            :alt="$t('search.athletics.calendar')"
          />
          <span>
            {{ $t('search.events.addToCalendar') }}
          </span>
        </button>
        <EventListButtons
          :show-calendar-buttons="showCalendarOptions"
          :event-data="item"
          :handle-back-button-click="handleBackButtonClick"
          :handle-blur="handleBlur"
          :color-overrides="colorOverrides"
          use-close-icon
        />
      </div>
      <div
        v-if="!searchCurrentSiteVal"
        class="org-name"
      >
        {{ item.secondary_organization.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import tinycolor from 'tinycolor2';
import { timeDifference } from '../../helpers/date-utils';
import EventListButtons from '../events/components/EventViews/List/EventListButtons.vue';

export default {
  name: 'EventPreview',
  components: {
    EventListButtons,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    searchCurrentSiteVal: Boolean,
  },
  data() {
    return {
      showCalendarOptions: false,
      // hiding the add to calendar button until v2 search is released
      calendarButtonAvailable: false,
    };
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.globals.info.primary_color,
    }),
    eventLocation() {
      return this.item.venue ? `http://maps.google.com/?q=${this.item.venue}` : '';
    },
    formattedTime() {
      return this.item.event_date ? timeDifference(this.item.event_date) : '';
    },
    link() {
      return `${this.item.link || ''}/events?id=${this.item.id}`;
    },
    monthAndDayOnly() {
      const splitText = this.item.formatted_date.split('from');
      return splitText[0].trim();
    },
    timesOnly() {
      const splitText = this.item.formatted_date.split('from');
      return splitText[1].trim();
    },
    colorOverrides() {
      return {
        '--button-primary-bg': tinycolor(this.primaryColor.hex).darken(10),
        '--button-primary-bg-hover': tinycolor(this.primaryColor.hex).darken(5),
        '--button-default-bg': this.primaryColor.hex,
        '--button-primary-text': '#FFFFFF',
      };
    },
  },
  methods: {
    handleBackButtonClick() {
      this.showCalendarOptions = false;
    },
    handleBlur() {
      this.showCalendarOptions = false;
    },
    toggleCalendarOptions() {
      this.showCalendarOptions = !this.showCalendarOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.result .events-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .tag-and-time {
    display: flex;
    align-items: center;
    margin-left: 2px;
    margin-bottom: 16px;
    .tag {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.4px;
      background: #f3f3f3;
      text-decoration: none;
      color: #333333;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
      border-radius: 20px;
      width: fit-content;
      margin-right: 12px;
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
    .time-ago {
      color: #747474;
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.4px;
    }
  }
  .date,
  .time,
  .title,
  .description,
  .org-name,
  .location {
    margin-left: 12px;
    color: #333;
  }
  .time,
  .title,
  .description {
    letter-spacing: 0.4px;
  }
  .date,
  .time,
  .title {
    font-weight: 700;
    margin-bottom: 12px;
  }
  .time,
  .description {
    line-height: 24px;
    font-size: 16px;
  }
  .date {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.8px;
  }
  .title {
    font-size: 20px;
    line-height: 16px;
    text-decoration: none;
    color: var(--link-color);
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: #662e80 solid 2px;
    }
  }
  .description {
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 8px;
  }
  .location {
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
    }
  }
  .calendar-wrapper {
    position: relative;
    .calendar {
      display: flex;
      padding: 8px 12px;
      margin-left: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      border: var(--page-builder-color) 1px solid;
      background: none;
      margin-top: 20px;
      margin-bottom: 24px;
      width: fit-content;
      cursor: pointer;
      &:focus {
        outline: #662e80 solid 2px;
      }
      img {
        margin-right: 8px;
      }
      span {
        color: var(--page-builder-color);
        font-size: 12px;
        font-weight: 450;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-decoration: none;
      }
    }
    .button-container.open {
      position: absolute;
      border-radius: 4px;
      background: var(--page-builder-color);
      width: 136px;
      padding: 4px;
      margin-left: 12px;
      margin-top: -20px;
      :deep(.back-calendar-button) {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
</style>
