<template>
  <cms-custom-html
    :theme="theme"
    :is-preview="true"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ViewCustomCode',
  props: {
    containerIdx: {
      type: Number,
      default: null
    },
    containerId: {
      type: String,
      default: null
    },
    componentIdx: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters(['getTheme', 'getHomepage', 'isThemePreview', 'getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    id() {
      if (this.isThemePreview) {
        return 'id';
      }
      return `${this.containerId}_${this.componentIdx}`;
    },
    theme() {
      return {
        ...this.getTheme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('custom_html'),
        globalThemeSettings: this.getGlobalThemeSettings
      };
    }
  }
};
</script>
