import { defineNuxtPlugin } from '#app';
import { plugin as Icons } from '@apptegy/icons';
import FormComponents from '@apptegy/form-components';
import * as ABuilder from '@apptegy/page-builder';
import { plugin as Cms } from '@cms/components';

import '@apptegy/styles';
import '@apptegy/form-components/dist/form-components.css';
import '@apptegy/page-builder/dist/style.css';
import '@cms/components/dist/style.css';

const { plugin: ABuilderPlugin } = ABuilder;

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(Icons);
  vueApp.use(ABuilderPlugin);
  vueApp.use(FormComponents);
  vueApp.use(Cms);
});
