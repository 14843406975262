<template>
  <svg
    tabindex="-1"
    class="button"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @keydown.enter.exact.prevent="onKeyDown()"
    @keydown.space.exact.prevent="onKeyDown()"
  >
    <circle
      cx="24"
      cy="24"
      r="24"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1464 14.1464C20.3417 13.9512 20.6583 13.9512 20.8536 14.1464L29.8536 23.1464C30.0488 23.3417 30.0488 23.6583 29.8536 23.8536L20.8536 32.8536C20.6583 33.0488 20.3417 33.0488 20.1464 32.8536C19.9512 32.6583 19.9512 32.3417 20.1464 32.1464L28.7929 23.5L20.1464 14.8536C19.9512 14.6583 19.9512 14.3417 20.1464 14.1464Z"
      fill="#333333"
    />
  </svg>
</template>
<script>
export default {
  name: 'Arrow',
  props: {
    fillColor: {
      type: String,
      default: 'white',
    },
    onKeyDown: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
