<template>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      :fill="fillColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      :d="setIcon"
    />
    </svg>
  </template>
  <script>
  export default {
    name: 'PlusMinus',
    data(){
        return {
            plusIcon: "M8.5 2H7.5V7.5H2V8.5H7.5V14H8.5V8.5H14V7.5H8.5V2Z",
            minusIcon: "M2 7.5V8.5H14V7.5H2Z"
        }
    },
    props: {
      fillColor: {
        type: String,
        default: '#515151'
      },
      expanded: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
      },
    },
    computed: {
        setIcon(){
            return this.expanded ? this.minusIcon : this.plusIcon
        }
    }
  };
  </script>
  <style scoped>
  </style>
  