<template>
  <div
    class="decorative-background"
    :class="`${version.toLowerCase()}-container`"
    :style="{'--header-accent-color': fillColor}"
  >
    <component
      :is="version.replace(/\s+/g, '')"
      :fill-color="fillColor"
      :flip-horizontal="flipHorizontal"
    />
  </div>
</template>

<script>
import WavyLines1 from './svg/WavyLines1.vue';
import WavyLines2 from './svg/WavyLines2.vue';
import LinesDivider from './svg/LinesDivider.vue';
import DotsDivider from './svg/DotsDivider.vue';

export default {
  name: 'DecorativeBackground',
  components: {
    WavyLines1, WavyLines2, LinesDivider, DotsDivider
  },
  props: {
    version: {
      type: String,
      default: ''
    },
    fillColor: {
      type: String,
      default: '#000'
    },
    flipHorizontal: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.decorative-background {
  width: 100%;
  @media (max-width: 959px) {
    display: none;
  }
}
.light-or-purple {
  &.wavylines1-container, &.wavylines2-container {
    margin-top: -20px;
    @media (max-width: 1150px) {
      margin-top: 0;
    }
  }
  &.wavylines2-container {
    @media (min-width: 1600px) and (max-width: 1730px) {
      margin-top: 0;
    }
  }
}

</style>
