<!--
do not delete this file, we want preexisting
links to /browse/ to redirect to /documents/
-->
<script setup>
import { useStore } from 'vuex';

definePageMeta({
  middleware: [
    function (to) {
      const store = useStore();
      return navigateTo(`${store.getters.getPathPrefix}/documents/${to.params.id}`);
    },
  ],
});
</script>
