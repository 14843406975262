import { removeFacebookTrackingId } from './links';
import NuxtServerInitApis from './api/nuxtServerInit';

/**
 * Helper class for initializing the application.
 */
export default class HomepageFetchHelper {
  /**
   * Initializes a new instance of the `HomepageFetchHelper` class.
   * @param {object} context - The nuxt context object.
   */
  constructor(context) {
    this.context = context;
    this.request = context.req;
    this.NuxtServerInitApis = NuxtServerInitApis(useNuxtApp());
    const routeName = useRouter().currentRoute.value.name;
    this.isThemePreview = ['alias-preview-token-id', 'preview-token-id'].includes(routeName);

    Object.freeze(this);
  }

  /**
   * Extracts data from the initial request.
   * @param {boolean} IS_DEV - Indicates if the environment is development.
   * @returns {{ host: string, cookies: {}, locale: string, domain: string, alias: string }} - The extracted data.
   * @property {string} host - The host from the request headers.
   * @property {object} cookies - Locale info from the cookies object extracted from the request headers.
   * @property {string} locale - The locale extracted from the cookies.
   * @property {string} domain - The domain extracted from the request headers.
   * @property {string} alias - The alias extracted from the request URL.
   */
  extractDataFromInitialRequest(IS_DEV) {
    const headersResponse = this.request.headers;
    const { host, cookie } = headersResponse;

    const alias = HomepageFetchHelper.getAlias(this.request.url);
    const [domain] = ((IS_DEV && process.env.DEV_DOMAIN) || host).split(':');
    const cookies = HomepageFetchHelper.getCookiesObject(cookie);
    const locale = cookies.langCode || '';

    return {
      host,
      cookies,
      locale,
      domain,
      alias,
    };
  }

  /**
   * Fetches organization details.
   * @async
   * @param {object} requestData - An object containing the request data.
   * @param {string} requestData.domain - The domain. ex: 'www.myschool.org'
   * @param {string} alias - The alias. ex: 'my-school'
   * @returns {Promise<object>} - A promise that resolves to the organization details.
   */
  async fetchOrgDetails(requestData, aliasFromRequest) {
    const { domain, alias } = requestData;
    let orgDetailsResponse;
    try {
      const pathToS3BucketFromRequestUrl = HomepageFetchHelper.convertDomainAndAliasToS3BucketPath(
        domain,
        alias || aliasFromRequest,
      );
      orgDetailsResponse = await this.NuxtServerInitApis.fromStaticCDN().fetchOrgDetails(pathToS3BucketFromRequestUrl);
    } catch (error) {
      orgDetailsResponse = await this.NuxtServerInitApis.fromThrillshareAPI().fetchOrgDetails(
        domain,
        alias || aliasFromRequest,
      );
    }
    return orgDetailsResponse.data;
  }

  /**
   * @async
   * @param {{ parent_organization_id: string, secondary_organization_id: string }} orgDetails
   * @returns {Promise<{files_config: {}, feature_flags: {}}>} - A promise that resolves to the metadata.json from `<static-cdn>/content/<parent_org_id>/<secondary_org_id>/metadata.json`. Fetches a metadata file that contains a list of each locale specific file and ISO timestamp of `last_static_update` and the feature flags. This can be compared to a matching key within the file to determine if the file has been updated since the last time it was generated in a specific language.
   */
  async fetchSiteMetadata(orgDetails) {
    const { parent_organization_id: parentOrgId } = orgDetails;
    try {
      const metadataResponse = await this.NuxtServerInitApis.fromStaticCDN().fetchSiteMetadata(orgDetails);

      const topLastStaticUpdates = metadataResponse.data?.last_static_update || {};
      const nestedConfigLastStaticUpdates = metadataResponse.data?.files_config?.last_static_update || {};
      const nestedConfigHomepageLastStaticUpdates =
        metadataResponse.data?.files_config?.homepage?.last_static_update || {};
      const mergedLastStaticUpdates = {
        ...topLastStaticUpdates,
        ...nestedConfigLastStaticUpdates,
        ...nestedConfigHomepageLastStaticUpdates,
      };
      // If metadata contains feature_flags, return metadata
      if (metadataResponse.data.feature_flags) {
        const featureFlagsData = metadataResponse.data.feature_flags;

        return {
          files_config: { last_static_update: mergedLastStaticUpdates },
          feature_flags: featureFlagsData,
        };
      }

      // If metadata does not yet contain feature_flags, try to fetch them from the parent S3 location and fallback to Thrillshare API
      // TODO: Remove this fallback once all orgs have been migrated to the new metadata.json format
      const featureFlagsData = await this.fetchFeatureFlags(parentOrgId);
      return {
        files_config: { last_static_update: mergedLastStaticUpdates },
        feature_flags: featureFlagsData,
      };
    } catch (error) {
      // If fetching metadata fails, try to fetch feature flags from Thrillshare API and assume no static files
      const featureFlagsResponse = await this.NuxtServerInitApis.fromThrillshareAPI().fetchFeatureFlags(parentOrgId);
      return {
        files_config: {},
        feature_flags: featureFlagsResponse.data,
      };
    }
  }

  /**
   * Fetches feature flags.
   * @async
   * @param {object} orgDetails - The organization details object containing `parent_organization_id` and `secondary_organization_id`
   * @returns {Promise<object>} - A promise that resolves to the feature flags data.
   */
  async fetchFeatureFlags(parentOrgId) {
    let featureFlagsResponse;
    try {
      featureFlagsResponse = await this.NuxtServerInitApis.fromStaticCDN().fetchFeatureFlags(parentOrgId);
    } catch (error) {
      featureFlagsResponse = await this.NuxtServerInitApis.fromThrillshareAPI().fetchFeatureFlags(parentOrgId);
    }
    return featureFlagsResponse.data;
  }

  /**
   * Fetches the site structure.
   * @async
   * @param {{ domain, alias, locale }} requestData - Object containing `domain`, `alias`, and `locale`.
   * @param {object} orgDetails - The organization details object containing `parent_organization_id` and `secondary_organization_id`
   * @param {{ files_config: Object, feature_flags: Object }} siteMetadata - The configuration for the static files and feature flags.
   * @param {boolean} isIntranet - Indicates if the organization is an intranet type.
   * @returns {Promise<object>} - A promise that resolves to the site structure.
   */
  async fetchSiteStructure(requestData, orgDetails, siteMetadata, isIntranet, authCookie) {
    let siteStructureResponse;
    const useS3 = siteMetadata.feature_flags.cms_stored_data_enabled;
    const lastStaticUpdate =
      siteMetadata.files_config?.last_static_update?.site_structure ||
      siteMetadata.files_config?.homepage?.last_static_update?.site_structure;
    if (isIntranet && authCookie) {
      siteStructureResponse = await this.fetchIntranetSiteStructure(requestData, orgDetails, authCookie);
    } else {
      if (useS3) {
        try {
          siteStructureResponse = await this.fetchSiteStructureFromS3AndValidate(
            requestData,
            orgDetails,
            lastStaticUpdate,
          );
        } catch (error) {
          siteStructureResponse = await this.fetchSiteStructureFromThrillshare(requestData, orgDetails);
        }
      } else {
        siteStructureResponse = await this.fetchSiteStructureFromThrillshare(requestData, orgDetails);
      }
    }
    return siteStructureResponse.data;
  }

  /**
   * Fetches site structure from S3 bucket or Thrillshare API based on freshness of translated data.
   * @async
   * @param {Object} requestData - Object containing `domain`, `alias`, and `locale`.
   * @param {Object} orgDetails - Object containing `primary_organization_id` and `secondary_organization_id`.
   * @param {Date} lastStaticUpdate - The last static update from the S3 site structure JSON.
   * @returns {Promise<Object>} - Promise that resolves to site structure object.
   */
  async fetchSiteStructureFromS3AndValidate(requestData, orgDetails, lastStaticUpdate) {
    let siteStructureResponse = await this.NuxtServerInitApis.fromStaticCDN().fetchSiteStructure(
      orgDetails,
      requestData.locale || 'en',
    );

    const isStaticDataFresh = HomepageFetchHelper.validateLastStaticUpdate(
      siteStructureResponse.data?.last_static_update,
      lastStaticUpdate,
    );

    if (!isStaticDataFresh) {
      siteStructureResponse = await this.fetchSiteStructureFromThrillshare(requestData, orgDetails);
    }
    return siteStructureResponse;
  }

  /**
   * Fetches the site structure for an intranet type secondary organization from the Thrillshare API.
   * @param {Object} requestData - The request data object containing `domain`, `alias`, and `locale`.
   * @param {object} orgDetails - The organization details object containing `parent_organization_id` and `secondary_organization_id`
   * @returns {Promise<Object>} - A promise that resolves with the site structure object.
   */
  async fetchIntranetSiteStructure(requestData, orgDetails, authCookie) {
    const { domain, alias, locale } = requestData;
    let siteStructureResponse;
    try {
      siteStructureResponse = await this.NuxtServerInitApis.fromThrillshareAPI().fetchIntranetSiteStructure(
        orgDetails.secondary_organization_id,
        locale,
        authCookie,
      );
    } catch (error) {
      console.error('Failed to fetch intranet site structure');
    }
    return siteStructureResponse;
  }

  /**
   * Fetches site structure from Thrillshare API, trying by secondary org id first and falling back to domain and alias.
   * @async
   * @param {Object} requestData - The request data object containing `domain`, `alias`, and `locale`.
   * @param {object} orgDetails - The organization details object containing `parent_organization_id` and `secondary_organization_id`
   * @returns {Promise<Object>} - A promise that resolves with the site structure object.
   */
  async fetchSiteStructureFromThrillshare(requestData, orgDetails) {
    const { domain, alias, locale } = requestData;
    let siteStructureResponse;
    try {
      siteStructureResponse = await this.NuxtServerInitApis.fromThrillshareAPI().fetchSiteStructureBySecOrgId(
        orgDetails.secondary_organization_id,
        locale,
      );
    } catch (error) {
      siteStructureResponse = await this.NuxtServerInitApis.fromThrillshareAPI().fetchSiteStructureByDomain(
        domain,
        alias,
        locale,
      );
    }
    return siteStructureResponse;
  }

  /**
   * Handles redirects.
   * @async
   * @param {Array<object>} redirectsData - The redirects data.
   * @returns {boolean} - Indicates if a redirect was performed.
   */
  async handleRedirects(redirectsData, nuxt) {
    const from = removeFacebookTrackingId('fbclid', this.request.url?.toLowerCase());
    const redirectItem = redirectsData.find((r) => {
      const lowerCasePath = r.content.path?.toLowerCase();
      return lowerCasePath === from || `/${lowerCasePath}` === from;
    });
    if (redirectItem) {
      nuxt.runWithContext(() => {
        navigateTo(redirectItem.content.url, {
          external: true,
        });
      });
      return true;
    }
    return false;
  }

  /**
   * Validates the last static update of a requested resource against the current file's last static update.
   * @static
   * @param {Date} currentFileLastStaticUpdate - The last time this file was uploaded to S3.
   * @param {Date} requestedResourceLastStaticUpdate - The last time this data was updated in Thrillshare ('site_structure'|'news'|'live_feed'|'events'|'athletics').
   * @returns {boolean} - Returns true if the last_static_update of the current file is not older than the last time that resource was updated.
   */
  static validateLastStaticUpdate(currentFileLastStaticUpdate, requestedResourceLastUpdate) {
    if (!currentFileLastStaticUpdate || !requestedResourceLastUpdate) return false;
    return currentFileLastStaticUpdate >= requestedResourceLastUpdate;
  }

  /**
   * Retrieves the alias from the URL.
   * @static
   * @param {string} url - The URL.
   * @returns {string|null} - The alias or null if not found.
   */
  static getAlias(url) {
    let alias = url;
    if (!alias) {
      return null;
    }
    if (alias.includes('__nuxt_error')) {
      // nuxt createError makes the internal page route a weird encoding like
      /// /__nuxt_error?url=%2Fo%2Faaa%2Fpages%....
      // so we need to create query params and strip out the url if it's an error page
      try {
        const query = new URLSearchParams(alias.replace('/__nuxt_error', ''));
        alias = query.get('url');
      } catch (e) {
        console.error(e);
      }
    }
    const match = alias.match(/^\/o\/([A-z0-9-_]+)/);
    return match ? match[1].replace(/-/g, ' ') : null;
  }

  /**
   * Converts a cookie string into an object.
   * @static
   * @param {string} cookie - The cookie string.
   * @returns {{ langCode: 'en', language: 'English' }} - The cookies object.
   */
  static getCookiesObject(cookie) {
    if (!cookie) {
      return {};
    }
    return cookie.split(';').reduce((cookies, cookieStr) => {
      const cookieArr = cookieStr.split('=');
      if (!cookieArr[0] || !cookieArr[1]) {
        return cookies;
      }
      return {
        ...cookies,
        [cookieArr[0].trim()]: cookieArr[1],
      };
    }, {});
  }

  /**
   * Converts the domain and alias to the S3 bucket path.
   * @static
   * @param {string} domain - The domain. ex: 'www.myschool.org'
   * @param {string} alias - The alias. ex: 'my-school'
   * @returns {string} - The S3 bucket path.
   */
  static convertDomainAndAliasToS3BucketPath(domain, alias) {
    const domainParts = domain.split('.');
    if (domainParts[0] === 'www') domainParts.shift();
    const pathToS3BucketFromDomain = domainParts.join('/');
    const fullPath = alias ? `${pathToS3BucketFromDomain}/${alias.replace(/\s/g, '-')}` : pathToS3BucketFromDomain;
    return fullPath.toLowerCase();
  }

  /**
   * Handles server errors.
   * @static
   * @param {object} error - The error object.
   * @param {Function} nuxtErrorFunc - The Nuxt error function.
   * @todo: context.error is not defined in this context
   * we need to handle this error in a middleware or wait until nuxt 3
   * This does work for asyncData and middleware, so our error page works in all cases except nuxtServerInit
   */
  static handleServerError(error, nuxtErrorFunc) {
    nuxtErrorFunc({
      statusCode: error.response?.status || 404,
      message: error.message || 'Page not found',
    });
  }

  /**
   * Retrieves the redirect URL for article URLs generated by Thrillshare from the CMS v1 to CMS v2 style URL path.
   * /article/id?org=alias -> /o/alias/article/id
   * @static
   * @param {object} req - The request object.
   * @returns {string|null} - The redirect URL or null if not found.
   */
  static getArticleRedirectUrl(req) {
    const path = req.url || '';
    const match = path.match(/\/article\/\d+\?org=[^/?&]+/);
    if (!match) {
      return null;
    }
    const [articleAndId, alias] = match[0].split('?org=');
    return `/o/${alias}${articleAndId}`;
  }

  /**
   * Checks if the given events have any outdated events based on the current date.
   *
   * @param {Array} entries - The array of entries to check.
   * @param {string} sectionType - The type of section to filter events for.
   * @returns {boolean} - Returns true if there are outdated events, false otherwise.
   * 'setHours' is used to remove the time from the date string, for comparing all-day events
   */
  static hasOutdatedEntries(entries, sectionType) {
    const nowDate = new Date().setHours(0, 0, 0, 0);

    return entries.some((entry) => {
      let startDate, endDate;

      switch (sectionType) {
        case 'athletics':
          startDate = new Date(entry.formatted_date).setHours(0, 0, 0, 0);
          return startDate < nowDate;
        case 'events':
          startDate = new Date(entry.start_at).setHours(0, 0, 0, 0);
          endDate = entry.end_at ? new Date(entry.end_at).setHours(0, 0, 0, 0) : null;
          return startDate < nowDate && endDate < nowDate;
        default:
          console.error(`Unhandled sectionType: ${sectionType}`);
          return false;
      }
    });
  }

  /**
   * Fetches APIs the old way if there is no org details to fetch in S3 or Thrillshare
   * @async
   * @param {object} requestData - An object containing the request data.
   * @param {string} requestData.domain - The domain. ex: 'www.myschool.org'
   * @param {string} requestData.alias - The alias. ex: 'my-school'
   * @param {string} requestData.locale - The locale. ex: 'en'
   * @returns {Promise<{ orgDetails, featureFlagsData, siteStructureData }>} - A promise that resolves to the { orgDetails, featureFlagsData, siteStructureData }
   * @todo: Remove this function once org details endpoint is available in Thrillshare
   */
  async fetchLegacyAPIs(requestData) {
    const { domain, alias, locale } = requestData;
    const { data: siteStructureData } = await this.NuxtServerInitApis.fromThrillshareAPI().fetchSiteStructureByDomain(
      domain,
      alias,
      locale,
    );
    const { data: featureFlagsData } = await this.NuxtServerInitApis.fromThrillshareAPI().fetchFeatureFlags(
      siteStructureData.config.parent_organization_id,
    );

    const orgDetails = {
      parent_organization_id: siteStructureData.config.parent_organization_id,
      secondary_organization_id: siteStructureData.config.secondary_organization_id,
    };
    return { orgDetails, featureFlagsData, siteStructureData };
  }
}
