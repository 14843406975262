export const FIELDS = Object.freeze([
  {
    id: 'fullName',
    type: 'TextInput',
    labelKey: 'nameLabel',
    placeholderKey: 'nameLabel',
    validators: ['required'],
    autocomplete: 'name',
  },
  {
    id: 'email',
    type: 'TextInput',
    labelKey: 'emailLabel',
    placeholderKey: 'emailLabel',
    validators: ['required', 'email'],
    autocomplete: 'email',
  },
  {
    id: 'message',
    type: 'TextArea',
    labelKey: 'messageLabel',
    placeholderKey: 'messagePlaceholder',
    validators: ['required'],
  },
]);
