export function getIcon({ folder_name, file_extension }) {
  if (folder_name) return `url("${new URL('@/assets/folder_icon.svg', import.meta.url)}")`;
  switch (file_extension) {
    case 'pdf':
      return `url("${new URL('@/assets/pdf_icon.svg', import.meta.url)}")`;
    case 'doc':
    case 'docx':
      return `url("${new URL('@/assets/doc_icon.svg', import.meta.url)}")`;
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'tsv':
      return `url("${new URL('@/assets/xls_icon.svg', import.meta.url)}")`;
    case 'jpg':
    case 'png':
    case 'jpeg':
      return `url("${new URL('@/assets/image_icon.svg', import.meta.url)}")`;
    case 'mp4':
      return `url("${new URL('@/assets/video.svg', import.meta.url)}")`;
    case 'pptx':
    case 'ppt':
      return `url("${new URL('@/assets/pptx_icon.svg', import.meta.url)}")`;
    case 'zip':
      return `url("${new URL('@/assets/zip_icon.svg', import.meta.url)}")`;
    case 'link':
      return `url("${new URL('@/assets/link_icon.svg', import.meta.url)}")`;
    case 'gdrive':
      return `url("${new URL('@/assets/google_drive_icon.svg', import.meta.url)}")`;
    default:
      return `url("${new URL('@/assets/default_doc_icon.svg', import.meta.url)}")`;
  }
}

export function getGlobalSearchIcon(file_extension) {
  switch (file_extension) {
    case 'pdf':
      return `url("${new URL('@/assets/search-icons/pdf.svg', import.meta.url)}")`;
    case 'doc':
    case 'docx':
      return `url("${new URL('@/assets/search-icons/default_doc.svg', import.meta.url)}")`;
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'tsv':
      return `url("${new URL('@/assets/search-icons/xls.svg', import.meta.url)}")`;
    case 'svg':
    case 'jpg':
    case 'png':
    case 'jpeg':
      return `url("${new URL('@/assets/search-icons/image.svg', import.meta.url)}")`;
    case 'mp4':
      return `url("${new URL('@/assets/search-icons/video.svg', import.meta.url)}")`;
    case 'mp3':
    case 'wav':
    case 'aac':
      return `url("${new URL('@/assets/search-icons/audio.svg', import.meta.url)}")`;
    case 'pptx':
    case 'ppt':
      return `url("${new URL('@/assets/search-icons/ppt.svg', import.meta.url)}")`;
    case 'zip':
      return `url("${new URL('@/assets/search-icons/zip.svg', import.meta.url)}")`;
    default:
      return `url("${new URL('@/assets/search-icons/default_doc.svg', import.meta.url)}")`;
  }
}
