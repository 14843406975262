import get from 'lodash/get';

export const getEventsUrl = (links, currSlug) => {
  const v2EventsUrl = get(links, 'events');
  const v4EventsMainUrl = get(links, 'v4.events.main');

  if (v4EventsMainUrl) {
    const v4EventSectionUrl = get(links, `v4.events.sections.${currSlug}`);

    if (currSlug && !v4EventSectionUrl) {
      throw new Error('Invalid Events Custom Section Slug');
    }

    const isMain = !(currSlug && v4EventSectionUrl);

    return isMain ? v4EventsMainUrl : v4EventSectionUrl;
  }

  return v2EventsUrl;
};

export const getSelectedFilter = (query) => {
  const { section_ids, filter_ids } = query;
  return (section_ids || filter_ids || '').split(',');
};

export const getSelectedFilterIds = (query) => {
  const { section_ids, filter_ids } = query;
  return {
    ...(section_ids ? { calendarsId: section_ids.split(',') } : {}),
    ...(filter_ids ? { filtersId: filter_ids.split(',') } : {}),
  };
};

const formatDate = (date) => date.toISOString().split('T')[0];

export const getMonthRange = (year, month) => {
  // Last Day of Current Month
  const startDate = new Date(year, month, 1);
  // 0 = Last Day of Previous Month, That's why you have to put month + 1
  const endDate = new Date(year, month + 1, 0);

  return {
    date: undefined,
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
};

export const getDayObject = (year, month, day) => {
  // Last Day of Current Month
  const startDate = new Date(year, month, day);

  return {
    date: formatDate(startDate),
    startDate: undefined,
    end_date: undefined,
  };
};

export const extractDateObject = (date) => {
  const [year, month, day] = date.split('-');
  return { month: month - 1, day, year };
};

export const getDateParams = (params, currentYear, currentMonth, commit) => {
  const { start_date, end_date, date } = params;

  if (date) {
    commit('setCurrentDate', extractDateObject(date));
    return { date };
  }

  if (start_date && end_date) {
    commit('setCurrentDate', extractDateObject(start_date));
    return { start_date, end_date };
  }

  return getMonthRange(currentYear, currentMonth);
};

export const getEventSearchDateParams = (previousMonthsSearch = 6) => {
  const today = new Date();
  // when using the search feature on the events page, we only want
  // events from 6 months ago to anytime in the future to appear
  today.setMonth(today.getMonth() - previousMonthsSearch);

  return {
    date: undefined,
    start_date: formatDate(today),
  };
};

export const getCurrentFormattedDate = () => ({
  end_date: formatDate(new Date()),
});

export const setViewType = (view, rootState, rootGetters) => {
  if (view) return view;
  return rootState.events.searchText ? 'search-view' : get(rootGetters.getGlobals, 'settings.eventsPage.view_type');
};
