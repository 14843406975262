import { computed } from 'vue';
import { useRuntimeConfig } from '#imports';

export const useIntranet = () => {
  const config = useRuntimeConfig();
  const isIntranet = computed(() => !!config?.public?.IS_INTRANET);
  return {
    isIntranet,
  };
};
