<template>
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      :stroke="showBorder ? fillColor : '#fff'"
      cx="23"
      cy="22.9999"
      r="22.5"
      fill="#fff"
    />
    <path
      :stroke="fillColor"
      d="M16 23.0004H29"
    />
    <rect
      :fill="fillColor"
      x="24.2426"
      y="18.7072"
      width="1"
      height="7"
      transform="rotate(-45 24.2426 18.7072)"
    />
    <rect
      :fill="fillColor"
      x="29.1923"
      y="22.2422"
      width="1"
      height="7"
      transform="rotate(45 29.1923 22.2422)"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgArrow',
  props: {
    fillColor: {
      type: String,
      default: '#fff'
    },
    showBorder: {
      type: Boolean,
      default: true
    }
  }
};
</script>
