<template>
  <div class="result">
    <div class="tag-and-date">
      <a
        class="tag"
        :href="`${item.link}/news`"
      >
        {{ $t('search.articles.tagName') }}
      </a>
      <div class="date">
        <span>{{ formattedDateAndTime }}</span>
      </div>
    </div>
    <div class="news-info">
      <a
        :href="link"
        class="title"
      >
        {{ item.title }}
      </a>
      <p class="snippet">
        {{ item.snippet }}
      </p>
      <p
        v-if="!searchCurrentSiteVal && !isMobile"
        class="org-name"
      >
        {{ item.secondary_organization.name }}
      </p>
      <div
        v-if="item.image_url"
        class="image"
      >
        <img
          :src="item.image_url"
          :alt="item.caption"
        />
      </div>
      <div
        v-if="!searchCurrentSiteVal && isMobile"
        class="org-name-mobile"
      >
        {{ item.secondary_organization.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { monthDayYear } from '../../helpers/date-utils';

export default {
  name: 'ArticlePreview',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isMobile: Boolean,
    searchCurrentSiteVal: Boolean,
  },
  computed: {
    link() {
      return `${this.item.link || ''}/article/${this.item.id}`;
    },
    formattedDateAndTime() {
      return monthDayYear(this.item.publish_date);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../global/scss/mixins/_breakpoints.scss';

.result {
  box-sizing: border-box;
  .tag-and-date {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .tag {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.4px;
      background: #f3f3f3;
      text-decoration: none;
      color: #333333;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
      border-radius: 20px;
      width: fit-content;
      margin-left: 2px;
      margin-right: 12px;
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
    .date {
      color: #747474;
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.4px;
    }
  }
  .news-info {
    display: grid;
    grid-template-columns: auto 138px;
    grid-template-rows: repeat(3, auto);
    padding-right: 32px;
    min-height: 96px;
    .title,
    .snippet,
    .org-name {
      grid-column-start: 1;
      margin-left: 12px;
    }
    .title {
      color: var(--link-color);
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.4px;
      text-decoration: none;
      margin-bottom: 8px;
      &:hover {
        text-decoration: underline;
      }
    }
    .snippet {
      color: #333;
      font-size: 16px;
      font-weight: 450;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-right: 87px;
      height: fit-content;
    }
    .org-name {
      color: #333333;
      margin-top: 20px;
    }
    .image {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: span 3;
      width: 138px;
      height: 138px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }
}
@include screen('xs') {
  .result {
    display: block;
    .news-info {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      .snippet {
        margin-bottom: 4px;
      }
      .image {
        grid-column-start: 1;
        grid-row-start: 3;
        margin-left: 12px;
        margin-top: 12px;
      }
      .org-name-mobile {
        margin-top: 16px;
        margin-left: 12px;
      }
    }
  }
}
</style>
