<template>
  <div class="view-sticky-footer">
    <cms-sticky-footer
      v-if="stickyFooterSetUp"
      :theme="stickyFooterOptions"
      :content="homepage.sticky_footer"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ViewStickyFooter',
  computed: {
    ...mapState(['theme', 'homepage']),
    ...mapGetters({
      getGlobalThemeSettings: 'getGlobalThemeSettings',
      getComponentTranslation: 'translation/getComponentTranslation',
    }),
    stickyFooterOptions() {
      return {
        ...this.theme.sticky_footer.options,
        translations: this.getComponentTranslation('sticky_footer'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    stickyFooterSetUp() {
      return this.homepage?.sticky_footer?.pill_button || this.homepage?.sticky_footer?.icon_buttons;
    },
  },
};
</script>
