<template>
  <div
    v-show="item.name && item.id"
    class="result form-item"
  >
    <div class="tag-and-time">
      <a
        class="tag"
        :href="`${item.link}/forms`"
      >
        {{ $t('search.forms.tagName') }}
      </a>
      <div class="published-date">
        <span>{{ $t('search.forms.published') }} {{ formattedDateAndTime }}</span>
      </div>
    </div>
    <div class="form-item-text-container">
      <a
        class="name"
        :href="`${item.link}/forms/${item.id}`"
        target="_blank"
      >
        {{ item.name }}
      </a>
      <div
        v-if="item.description"
        class="description"
      >
        {{ item.description }}
      </div>
    </div>
    <p class="org-name">
      {{ item.secondary_organization.name }}
    </p>
  </div>
</template>

<script>
import { monthDayYear } from '../../helpers/date-utils';

export default {
  name: 'FormPreview',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formattedDateAndTime() {
      return this.item?.publish_date ? monthDayYear(this.item.publish_date) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item {
  box-sizing: border-box;
  .tag-and-time {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tag,
    .published-date {
      line-height: 12px;
      letter-spacing: 0.4px;
      font-size: 14px;
      font-weight: 500;
    }
    .tag {
      background: #f3f3f3;
      color: #333333;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
      border-radius: 20px;
      width: fit-content;
      margin-left: 2px;
      margin-right: 12px;
      text-decoration: none;
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
    .published-date {
      color: #747474;
    }
  }
  .form-item-text-container {
    padding: 32px 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #ceecec;
    margin-right: 8px;
    width: auto;
    .name,
    .title,
    .org-name {
      letter-spacing: 0.4px;
    }
    .name {
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      line-height: 16px;
      color: var(--link-color);
      &:hover {
        text-decoration: underline;
      }
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
    .description {
      color: #747474;
      font-size: 16px;
      margin-top: 15px;
    }
  }
  .org-name {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 16px;
  }
}
</style>
