<template>
  <div>
    <cms-alert-modal-separate
      v-if="!hideAlerts.modal && containsModalAlerts"
      :globals="globals"
      :modal-array="modalAlerts"
      @close="closeAlert"
    />
    <cms-alert-banner-separate
      v-if="!hideAlerts.banner && containsBannerAlerts"
      :globals="globals"
      :banner-array="sortOpenAlertsByFeatured(openBannerAlerts)"
      @close="closeAlert"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SeparateAlert',
  props: {
    activeAlerts: {
      type: Array,
      default: () => [],
    },
    hideAlerts: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
  computed: {
    ...mapState(['globals']),
    modalAlerts() {
      const globalModalalert = this.getFirstAvailableModalAlertByType({ featured: true });
      const localModalalert = this.getFirstAvailableModalAlertByType({ featured: false });
      return [globalModalalert, localModalalert].filter((alert) => !!alert);
    },
    bannerAlerts() {
      return this.activeAlerts.filter((alert) => alert.style === 'banner');
    },
    containsModalAlerts() {
      return this.modalAlerts.length > 0;
    },
    containsBannerAlerts() {
      return this.bannerAlerts.length > 0;
    },
    closedAlertsFromSessionStorage() {
      return JSON.parse(window.sessionStorage.getItem('thrillshare_alert_banner')) || {};
    },
    openBannerAlerts() {
      if (!this.containsBannerAlerts || !import.meta.client) return [];

      return this.bannerAlerts.filter((alert) => !this.closedAlertsFromSessionStorage[alert.id]);
    },
  },
  methods: {
    getFirstAvailableModalAlertByType({ featured }) {
      return this.activeAlerts.find(
        (alert) =>
          !this.closedAlertsFromSessionStorage[alert.id] && alert.style === 'light_box' && alert.featured === featured,
      );
    },
    sortOpenAlertsByFeatured(openAlerts) {
      if (!openAlerts.length) return [];
      return openAlerts.slice().sort((a, b) => b.featured - a.featured);
    },
    /**
     * @param {*} closeObject { alertId: 1, closedByKeyboard: false }
     * Emits the close event to the parent component
     * to close the alert modal or banner
     */
    closeAlert(closeObject) {
      this.$emit('close', closeObject);
    },
  },
};
</script>
