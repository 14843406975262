<template>
  <div>
    <cms-our-schools
      v-if="ourSchoolsSetUp"
      :theme="ourSchoolsOptions"
      :content="homepage.our_schools[id]"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'ViewOurSchools',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview']),
    ...mapGetters(['getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    id() {
      if (this.isThemePreview) {
        return 'preview_id';
      }
      return `${this.containerId}_${this.componentIdx}`;
    },
    ourSchoolsOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('ourSchools'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    ourSchoolsSetUp() {
      return !isEmpty(this.homepage?.our_schools?.[this.id]?.content);
    },
  },
};
</script>
