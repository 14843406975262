<template>
  <nav class="cms-pagination" :style="cssVars">
    <ul>
      <li class="prev">
        <nuxt-link
          v-if="currentPage > 1"
          class="image-button"
          :to="{
            path: $route.path,
            query: { ...$route.query, [pageNameParam]: currentPage - 1 }
          }"
        >
          <arrow
            :fill-color="cssVars['--primary-color']"
            title="Previous Page"
          />
        </nuxt-link>
        <span
          v-else
          class="image-button disabled"
        >
          <arrow
            :fill-color="cssVars['--disabled-link']"
            title="Previous Page"
          />
        </span>
      </li>
      <li
        v-for="(item, index) in paginationWithDots"
        :key="index"
        :class="{ active: item == currentPage }"
      >
        <nuxt-link
          v-if="item !== '...'"
          :to="{
            path: $route.path,
            query: { ...$route.query, [pageNameParam]: item }
          }"
        >
          {{ item }}
        </nuxt-link>
        <span v-else>{{ item }}</span>
      </li>
      <li class="next">
        <nuxt-link
          v-if="currentPage < totalPages"
          class="image-button"
          :to="{
            path: $route.path,
            query: { ...$route.query, [pageNameParam]: currentPage + 1 }
          }"
        >
          <arrow
            :fill-color="cssVars['--primary-color']"
            title="Next Page"
          />
        </nuxt-link>
        <span
          v-else
          class="image-button disabled"
        >
          <arrow
            :fill-color="cssVars['--disabled-link']"
            title="Next Page"
          />
        </span>
      </li>
      <li class="jump">
        <label for="jump-number">{{ translations.jump_to_page }}</label>
        <input
          id="jump-number"
          v-model="pageJump"
          type="number"
          min="1"
          :max="totalPages"
          @keyup.enter="goToPage"
        />
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import Arrow from './svg/Arrow.vue';
import { paginationWithDots } from '../../helpers/pagination';

export default {
  name: 'Pagination',
  components: {
    Arrow
  },
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 1
    },
    pageNameParam: {
      type: String,
      default: 'page_no'
    }
  },
  data() {
    return {
      pageJump: null
    };
  },
  computed: {
    ...mapGetters(['getGlobals']),
    ...mapGetters('translation', ['getComponentTranslation']),
    cssVars() {
      return {
        '--primary-color': get(this.getGlobals, 'info.primary_color.hex', '#000000'),
        '--secondary-color': get(this.getGlobals, 'info.secondary_color.hex', '#000000'),
        '--disabled-link': '#E1E1E1'
      };
    },
    paginationWithDots() {
      return paginationWithDots(this.currentPage, this.totalPages);
    },
    translations() {
      return this.getComponentTranslation('pagination');
    }
  },
  methods: {
    goToPage() {
      if (this.pageJump > 0 && this.pageJump <= this.totalPages) {
        return navigateTo({
          query: { ...this.$route.query, [this.pageNameParam]: this.pageJump }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$default-text-color: #000;
.cms-pagination {
  * {
    box-sizing: border-box;
  }
  max-width: 90%;
  margin: 0 auto;
  padding: 18px 0;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      line-height: 24px;
      display: flex;
      align-items: center;
      a,
      span {
        margin: 0 14px;
        text-decoration: none;
        color: var(--links-color);
        cursor: pointer;
        &.image-button {
          padding: 0;
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px;
          border-color: var(--primary-color);
          border-radius: 2px;
          &.disabled {
            border-color: var(--disabled-link);
          }

          :deep(svg) {
            height: 11px;
          }
        }
      }
      span {
        cursor: default;
      }
      &.next :deep(svg) {
        transform: rotate(180deg);
      }
      &.active a {
        color: $default-text-color;
        font-weight: 700;
        border-bottom: solid 3px;
        border-color: var(--secondary-color);
        padding-top: 4px;
      }

      &.results {
        color: #747474;
      }
      &.jump {
        color: $default-text-color;
        font-weight: 500;
        input {
          margin: 0 0 0 10px;
          padding: 9px;
          border: solid 1px #fbfbfb;
          background: #fbfbfb;
          border-bottom: solid 1px #747474;
          width: 40px;
          text-align: center;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
      }
    }
  }
}
</style>
