/* eslint-disable camelcase */
import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';

export const EVENT_VIEWS = Object.freeze({
  LIST_VIEW: {
    componentName: 'EventsListView',
    iconName: 'ListViewIcon',
    displayName: 'List View',
  },
  CALENDAR_VIEW: {
    componentName: 'EventsCalendarView',
    iconName: 'CalendarViewIcon',
    displayName: 'Calendar View',
  },
  SEARCH_VIEW: {
    componentName: 'EventsSearchView',
  },
});

export const MONTHS = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]);

export const SEARCH_TABS = Object.freeze(['Upcoming', 'Past']);

export const buildSearchTabList = (intl) =>
  SEARCH_TABS.map((tab, idx) => ({
    label: intl.$t(`events.searchTabs.${camelCase(tab)}`),
    value: tab,
    idx,
  }));

export const buildMonthsTabList = (intl) =>
  MONTHS.map((month, idx) => ({
    label: intl.$t(`events.labelMonths.${camelCase(month)}`),
    fullName: intl.$t(`events.fullMonths.${camelCase(month)}`),
    idx,
    value: month.toLowerCase(),
  }));

export const buildEventViewsList = (intl) => {
  const calendarViews = { ...EVENT_VIEWS };
  // Excluse Search View from Event List View tabs
  delete calendarViews.SEARCH_VIEW;

  return Object.entries(calendarViews).reduce(
    (accum, [key, meta]) => ({
      ...accum,
      [key]: {
        ...meta,
        displayName: intl.$t(`events.eventViews.${camelCase(meta.displayName)}`),
      },
    }),
    {},
  );
};

// Removing only Timezone part of the Date
export const stripTimeZone = (dateString) => dateString.split('.000')[0];
export const stripTime = (dateString) => dateString.split('T')[0];

export const formatEventTime = (dateString, locale = 'en-US') => {
  const parsedDate = Date.parse(stripTimeZone(dateString));
  const formatter = new Intl.DateTimeFormat(locale, {
    timeStyle: 'short',
  });
  return formatter.format(parsedDate);
};

export const formatEventDay = (dateString, locale = 'en-US') => {
  const parsedDate = Date.parse(stripTimeZone(dateString));
  const formatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
  });
  return formatter.format(parsedDate);
};

export const formatEventMonth = (dateString, locale = 'en-US') => {
  const parsedDate = Date.parse(stripTimeZone(dateString));
  const formatter = new Intl.DateTimeFormat(locale, {
    month: 'long',
  });
  return formatter.format(parsedDate).toLowerCase();
};

export const stringToDate = (dateString) => new Date(Date.parse(stripTimeZone(dateString)));

// This should preferably be moved to the state to be initialized only once.
export const MONTH_TAB_LIST = buildMonthsTabList;
export const EVENT_VIEW_LIST = buildEventViewsList;
export const SEARCH_TAB_LIST = buildSearchTabList;

const DEFAULT_CAL_OPTIONS = {
  editable: false,
  overlap: true,
};

export const VIEW_TYPES = Object.freeze({
  MONTH: 'dayGridMonth',
  WEEK: 'timeGridWeek',
});

export const formatReadableDate = (event, viewType) => {
  const { formatted_start, formatted_end, all_day } = event;
  const start = dayjs(formatted_start);
  const end = dayjs(formatted_end);
  const isMultiDay = start.date() !== end.date();

  if (viewType === VIEW_TYPES.WEEK && isMultiDay && !all_day) {
    return `${start.format('MMM D hh:mm A')} UNTIL ${end.format('MMM D hh:mm A')}`;
  }

  const date = `${start.format('D MMM')}${isMultiDay ? ` - ${end.format('D MMM')}` : ''}`;

  const hFormat = 'h:mm A';
  const hour = all_day ? 'All Day' : `from ${start.format(hFormat)} to ${end.format(hFormat)}`;

  return `${date} ${hour}`;
};

export const formatEvent = ({ id, title, start, end, allDay, currentEvent, options, start_at, viewType }) => ({
  id,
  title: `${
    currentEvent.all_day || VIEW_TYPES.WEEK === viewType ? '' : dayjs(currentEvent.formatted_start).format('h:mm A')
  } ${title}`,
  start,
  end,
  allDay,
  // Metadata
  extendedProps: {
    ...currentEvent,
    readableDate: formatReadableDate(currentEvent, viewType),
  },
  ...options,
});

export const formatCalendarEvents =
  (viewType = VIEW_TYPES.MONTH, options = DEFAULT_CAL_OPTIONS) =>
  (events, currentEvent) => {
    const { title, all_day, formatted_start, formatted_end, start_at } = currentEvent;
    const id = `cal-event-${currentEvent.id}`;

    if (!viewType || viewType === VIEW_TYPES.MONTH) {
      const allDay = true; // To prevent hour display in calendar items
      const start = dayjs(formatted_start).startOf('day').format();
      const end = dayjs(formatted_end).add(1, 'days').startOf('day').format();

      return [
        ...events,
        formatEvent({
          id,
          title,
          start,
          end,
          allDay,
          currentEvent,
          options,
          start_at,
          viewType,
        }),
      ];
    }

    /**
     * Handling Week View Events
     */
    const mStart = dayjs(formatted_start);
    const mEnd = dayjs(formatted_end);
    const start = mStart.format();
    const end = mEnd.format();

    // Same day events are pretty straightforward
    if (mStart.isSame(mEnd, 'day') && !all_day) {
      return [
        ...events,
        formatEvent({
          id,
          title,
          start,
          end,
          allDay: all_day,
          currentEvent,
          options,
          start_at,
          viewType,
        }),
      ];
    }

    // Multi day events with assigned hours
    return [
      ...events,
      formatEvent({
        id,
        title,
        start: mStart.startOf('day').format(),
        end: mEnd.add(1, 'days').startOf('day').format(),
        allDay: true, // This will make the event be displayed on the "All Day" row of the calendar
        currentEvent,
        options,
        start_at,
        viewType,
      }),
    ];
  };

export const TOOLTIP_POSITIONING = (parentElement, containerElement, viewType) => {
  const isWeekView = viewType === VIEW_TYPES.WEEK;
  const parentElementBounding = isWeekView
    ? parentElement.parentNode.getBoundingClientRect()
    : parentElement.getBoundingClientRect();
  const dayColumn = isWeekView
    ? parentElement.parentNode.parentNode.parentNode.getBoundingClientRect()
    : parentElement.parentNode.getBoundingClientRect();
  const offSetPercentage = isWeekView
    ? parentElement.parentNode.style.left.replace(/[^\d.]|\.(?=.*\.)/g, '')
    : parentElement.style.left.replace(/[^\d.]|\.(?=.*\.)/g, '');
  const offSet = (offSetPercentage * dayColumn.width) / 100;

  const tooltipMaxHeight = 500;
  const tooltipMaxWidth = 300;

  const floatRight = containerElement.getBoundingClientRect().left + tooltipMaxWidth >= parentElementBounding.left;
  const floatTop =
    parentElement.getBoundingClientRect().bottom + 50 + tooltipMaxHeight >=
    containerElement.parentElement.getBoundingClientRect().bottom
      ? {
          '--tooltip-align-items': 'flex-end',
          '--tooltip-arrow-top': '-17px',
        }
      : null;
  const floatBottom =
    containerElement.getBoundingClientRect().top + tooltipMaxHeight >= parentElement.getBoundingClientRect().top
      ? {
          '--tooltip-align-items': 'flex-start',
          '--tooltip-arrow-top': '8px',
        }
      : null;

  return {
    '--tooltip-arrow-top': '-5px',
    '--tooltip-content-max-height': `${tooltipMaxHeight}px`,
    '--tooltip-align-items': 'center',
    '--tooltip-left-position': floatRight ? `${dayColumn.width - offSet}px` : '-305px',
    '--tooltip-flex-direction': floatRight ? 'row-reverse' : 'row',
    ...floatBottom,
    ...floatTop,
  };
};

export const getSpecificListOrCalView = ({ currentView, listViewType, calendarViewType }) => {
  if (currentView === 'EventsListView') {
    return listViewType === 'month' ? 'list-month' : 'list-all';
  }
  return calendarViewType === 'dayGridMonth' ? 'cal-month' : 'cal-week';
};
