<template>
  <div class="result">
    <div class="live-feed-info">
      <div class="tag-and-time">
        <a
          class="tag"
          :href="`${item.link}/live-feed`"
          >{{ $t('search.liveFeed.tagName') }}</a
        >
        <div class="time-ago">
          {{ formattedTime }}
        </div>
      </div>
      <div class="status">
        <a :href="link">
          {{ item.status }}
        </a>
      </div>
      <div
        v-if="!searchCurrentSiteVal && !isMobile"
        class="org-name"
      >
        {{ item.secondary_organization.name }}
      </div>
    </div>
    <div
      v-if="item.live_feed_images.length > 0"
      class="live-feed-images"
    >
      <div class="first-image">
        <img
          :src="item.live_feed_images[0].image_url"
          :alt="item.live_feed_images[0].caption"
        />
      </div>
      <div
        v-if="item.live_feed_images.length > 1"
        class="thumbnails-wrapper"
      >
        <div
          v-for="image in item.live_feed_images.slice(1, 4)"
          :key="image.id"
          class="live-feed-image-thumbnail"
          :style="backgroundImage(image, image.id)"
        >
          <img
            :src="image.image_url"
            :alt="image.caption"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!searchCurrentSiteVal && isMobile"
      class="org-name-mobile"
    >
      {{ item.secondary_organization.name }}
    </div>
  </div>
</template>

<script>
import { timeDifference } from '../../helpers/date-utils';

export default {
  name: 'LiveFeedPreview',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isMobile: Boolean,
    searchCurrentSiteVal: Boolean,
  },
  computed: {
    formattedTime() {
      return this.item?.published_at ? timeDifference(this.item.published_at) : '';
    },
    link() {
      return `${this.item.link || ''}/live-feed#${this.item.id}`;
    },
  },
  methods: {
    backgroundImage(post, id) {
      return {
        backgroundImage: `'https://${post.image_url}${id}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../global/scss/mixins/_breakpoints.scss';

.result {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .live-feed-info {
    .tag-and-time {
      display: flex;
      align-items: center;
      .tag {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.4px;
        background: #f3f3f3;
        cursor: pointer;
        padding: 6px 12px;
        border-radius: 4px;
        border-radius: 20px;
        width: fit-content;
        margin-left: 2px;
        margin-right: 12px;
        text-decoration: none;
        color: #333333;
        &:focus {
          outline: #662e80 solid 2px;
        }
      }
      .time-ago {
        color: #747474;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.4px;
      }
    }
    .author {
      color: #333333;
      margin-bottom: 12px;
      margin-left: 12px;
    }
    .status {
      font-size: 18px;
      margin: 24px 0 24px 12px;
      a {
        text-decoration: none;
        color: var(--link-color);
        &:hover {
          text-decoration: underline;
        }
        &:focus {
          outline: #662e80 solid 2px;
        }
      }
    }
    .org-name {
      margin-left: 12px;
    }
  }
  .live-feed-images {
    .first-image {
      width: 138px;
      height: 138px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
    .thumbnails-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 8px;
      .live-feed-image-thumbnail {
        margin: 0 4.5px;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        background-size: cover;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
        &:first-of-type {
          margin-left: 0;
        }
        &:nth-of-type(3) {
          margin-right: 0;
        }
        &:nth-of-type(3)::after {
          content: attr(data-before);
          color: white;
          display: flex;
          justify-content: center;
          font-size: 20px;
          text-shadow: 1px 1px 1px #000;
        }
      }
    }
    margin-right: 35px;
    margin-left: 88px;
  }
}
@include screen('xs') {
  .result {
    display: block;
    .live-feed-images {
      margin-left: 0;
    }
    .live-feed-info {
      .tag-and-time .tag {
        margin-left: 0;
      }
      .status {
        margin-left: 0;
      }
      .author {
        margin-left: 0;
      }
    }
    .org-name-mobile {
      margin-top: 20px;
    }
  }
}
</style>
