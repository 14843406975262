<template>
  <cms-video
    v-if="videoSetUp"
    :theme="videoOptions"
    :content="homepage.video[id]"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'ViewVideo',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview']),
    ...mapGetters(['getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    id() {
      return this.isThemePreview ? 'preview_id' : `${this.containerId}_${this.componentIdx}`;
    },
    videoOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('video'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    videoSetUp() {
      const videoList = this.homepage.video?.[this.id]?.videos || [];
      return !isEmpty(videoList) && videoList.some((video) => video.url);
    },
  },
};
</script>
