<!-- eslint-disable vuejs-accessibility/tabindex-no-positive -->
<template>
  <div
    id="lightbox"
    ref="lightbox"
    role="dialog"
    aria-labelledby="lightbox"
    aria-modal="true"
    aria-label="Image Modal (press escape to close)"
    tabindex="-1"
    data-testid="lightbox"
    @keydown.esc.exact.prevent="close()"
    @keydown.tab="onTabEvent"
  >
    <div class="main">
      <div class="header">
        <div
          class="numbers"
        >
          {{ index + 1 }} {{ translations.of }} {{ images.length }}
        </div>
        <div
          class="close"
          @click="close"
        >
          <button
            ref="closeButtonWrapper"
            class="svg-wrapper"
            data-testid="lightbox-close"
            type="button"
            tabindex="4"
          >
            <svg
              ref="closeButton"
              aria-label="Close"
              class="button"
              tabindex="-1"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @keydown.enter.exact.prevent="close()"
              @keydown.space.exact.prevent="close()"
            >
              <circle
                cx="24"
                cy="24"
                r="24"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.1464 17.1464C17.3417 16.9512 17.6583 16.9512 17.8536 17.1464L23.9999 23.2929L30.1463 17.1464C30.3416 16.9512 30.6582 16.9512 30.8534 17.1464C31.0487 17.3417 31.0487 17.6583 30.8534 17.8536L24.707 24L30.8534 30.1464C31.0487 30.3417 31.0487 30.6583 30.8534 30.8536C30.6582 31.0488 30.3416 31.0488 30.1463 30.8536L23.9999 24.7071L17.8536 30.8536C17.6583 31.0488 17.3417 31.0488 17.1464 30.8536C16.9512 30.6583 16.9512 30.3417 17.1464 30.1464L23.2928 24L17.1464 17.8536C16.9512 17.6583 16.9512 17.3417 17.1464 17.1464Z"
                fill="#333333"
              />
            </svg>
            <span class="sr-only">Close Modal</span>
          </button>
        </div>
      </div>
      <div class="image-wrapper">
        <div
          v-if="multipleImages"
          class="left-arrow main arrows"
          @click="moveBack"
        >
          <button
            ref="leftArrow"
            data-testid="lightbox-left-arrow"
            class="svg-wrapper"
            type="button"
            tabindex="3"
          >
            <LightboxLeftArrow @onKeyDown="moveBack" />
            <span class="sr-only">Previous Image</span>
          </button>
        </div>
        <img
          class="image"
          :src="images[index].url"
          :alt="images[index].alt_text"
        >
        <div
          v-if="multipleImages"
          class="right-arrow main arrows"
          @click="moveForward"
        >
          <button
            ref="rightArrowWrapper"
            class="svg-wrapper"
            data-testid="lightbox-right-arrow"
            type="button"
            tabIndex="2"
          >
            <LightboxRightArrow
              ref="rightArrow"
              @onKeyDown="moveForward"
            />
            <span class="sr-only">Next Image</span>
          </button>
        </div>
      </div>
      <div class="spacer" />
    </div>
    <span
      id="lightbox-focus-buffer"
      tabindex="1"
      @keyup.tab="$event.shiftKey ? $refs.closeButtonWrapper.focus() : $refs.rightArrowWrapper.focus()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LightboxLeftArrow from './svg/LightboxLeftArrow.vue';
import LightboxRightArrow from './svg/LightboxRightArrow.vue';

export default {
  name: 'Lightbox',
  components: {
    LightboxLeftArrow,
    LightboxRightArrow
  },
  props: {
    translations: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      index: 0,
      focusableElements: []
    };
  },
  computed: {
    ...mapGetters({ images: 'getLightboxImages', prevFocused: 'getPrevFocusedElem', startingindex: 'getLightBoxCurrentIndex' }),
    multipleImages() {
      return this.images.length > 1;
    }
  },
  mounted() {
    if (this.multipleImages) {
      this.$refs.rightArrow.$el.focus();
    } else {
      this.$refs.closeButton.focus();
    }
    this.index = this.startingindex;

    this.initializeFocusTrap();
  },
  methods: {
    initializeFocusTrap() {
      this.focusableElements = this.$refs.lightbox
        .querySelectorAll('[href], [tabindex]:not([tabindex="-1"]):not(#lightbox-focus-buffer)');
    },
    onTabEvent(event) {
      const { focusableElements } = this;
      if (focusableElements.length < 2) {
        event.preventDefault();
        focusableElements[0].focus();
        return;
      }

      if (
        event.shiftKey === false &&
        (event.target === this.$refs.closeButtonWrapper || event.target.parentElement === this.$refs.closeButtonWrapper)
      ) {
        event.preventDefault();
        this.$refs.rightArrowWrapper.focus();
      } else if (
        event.shiftKey === true &&
        (event.target === focusableElements[1] || event.target.parentElement === focusableElements[1])
      ) {
        event.preventDefault();
        this.$refs.rightArrowWrapper.focus();
      }
    },
    moveBack() {
      if (this.index === 0) {
        this.index = this.images.length - 1;
        return;
      }
      this.index = (this.index - 1) % this.images.length;
    },
    moveForward() {
      this.index = (this.index + 1) % this.images.length;
    },
    close() {
      this.$store.commit('closeLightbox');
      if (this.prevFocused) this.prevFocused.focus();
    },
  }
};
</script>

<style lang="scss" scoped>
#lightbox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .85);
  * {
    pointer-events: auto; /* allows clicking lightbox in page preview */
  }
  &:focus {
    outline: none;
  }
}
.left-arrow,
.right-arrow,
.close {
  width: 48px;
  height: 48px;
  .svg-wrapper {
    padding: unset;
    background: none;
    border: none;
    margin: unset;
    cursor: pointer;
  }
  .svg-wrapper:focus,
  .button:focus {
      outline: none;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .svg-wrapper:focus > .button {
    outline: 1px solid #4D90FE;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
.left-arrow {
  margin-right: 10px;
}
.right-arrow {
  margin-left: 10px;
}
.button {
  &:hover {
    circle {
      fill: #DDDDDD;
    }
    cursor: pointer;
  }
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: 15px;
}
.numbers {
  color: white;
}
.image {
  max-width: 100%;
  max-height: 80vh;
  height: auto;
}
.image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
  max-width: 80vw;
}
.spacer {
  height: 64px;
  margin-bottom: auto;
  flex-shrink: 2;
}
@media screen and (max-width: 960px) {
  .arrows {
    position: absolute;
    top: calc(100% + 15px);
    height: unset;
  }
  .right-arrow {
    right: 15%;
  }
  .left-arrow {
    left: 15%;
  }
  .header {
    margin-left: 10px;
    margin-right: 10px;
  }
  .image-wrapper {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
</style>
