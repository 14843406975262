/**
 * Client side integration for appsignal
 */
import Appsignal from '@appsignal/javascript';
import { plugin } from '@appsignal/plugin-path-decorator';
import { errorHandler } from '@appsignal/vue';

export default defineNuxtPlugin((context) => {
  if (!useRuntimeConfig().public.APPSIGNAL_ACTIVE) {
    return;
  }

  const { APPSIGNAL_CLIENT_API_KEY, APP_REVISION } = useRuntimeConfig().public;
  const appsignal = new Appsignal({
    key: APPSIGNAL_CLIENT_API_KEY,
    namespace: 'client_side',
    revision: APP_REVISION,
  });
  appsignal.use(plugin());

  context.vueApp.config.errorHandler = errorHandler(appsignal, context.vueApp);

  // Inject to context as $appsignal
  context.provide('appsignal', appsignal);
});
