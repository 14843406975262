<template>
  <div>
    <cms-buttons
      v-if="buttonsSetUp"
      :theme="buttonsOptions"
      :content="homepage.buttons[id]"
      :logo="watermarkLogo"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'ViewButtons',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview', 'globals']),
    ...mapGetters(['getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    id() {
      if (this.isThemePreview) {
        return 'preview_id';
      }
      return `${this.containerId}_${this.componentIdx}`;
    },
    buttonsOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('buttons'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    buttonsSetUp() {
      return !isEmpty(this.homepage?.buttons?.[this.id]?.content);
    },
    watermarkLogo() {
      return this.globals.watermark?.url || this.globals.logo.url;
    },
  },
};
</script>
