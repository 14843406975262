<template>
  <div
    class="dots-divider"
  >
    <svg
      width="4000"
      height="18"
      viewBox="0 0 4000 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        v-for="(n, idx) in 3"
        :key="idx"
        x1="0"
        :y1="yOffset(idx)"
        x2="4000"
        :y2="yOffset(idx)"
        :stroke="fillColor"
        stroke-dasharray="2, 6"
        stroke-width="2"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'DotsDivider',
  props: {
    fillColor: {
      type: String,
      default: '#000',
    }
  },
  methods: {
    yOffset(idx) {
      return (idx * 8) + 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.dots-divider {
  margin-top: 6px;
  overflow: hidden;
}
</style>
