export default defineNuxtRouteMiddleware(({ route, store }) => {
  const featureFlagsRequiredForPage = route?.meta?.[0]?.featureFlags || [];

  if (!featureFlagsRequiredForPage.length) {
    return;
  } else {
    const featureFlagsData = store.state.featureFlags;

    // check each feature flag required by enabled feature flags and if any are false, redirect to 404
    if (featureFlagsRequiredForPage.some((flag) => !featureFlagsData[flag])) {
      return abortNavigation({
        statusCode: 404,
        message: 'Page not found'
      });
    }
  }
});
