import validate from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45auth_45global from "/app/cms_public/middleware/00.auth.global.js";
import enabled_45feature_45flags_45global from "/app/cms_public/middleware/enabled-feature-flags.global.js";
import manifest_45route_45rule from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45auth_45global,
  enabled_45feature_45flags_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "intranet-roles": () => import("/app/cms_public/middleware/intranet-roles.js"),
  "page-content": () => import("/app/cms_public/middleware/page-content.js"),
  "page-preview-auth": () => import("/app/cms_public/middleware/page-preview-auth.js"),
  "preview-auth": () => import("/app/cms_public/middleware/preview-auth.js")
}