export function extractUrlParams(stringUrl, domain = '') {
  if (!stringUrl) { return { url: null, params: {} }; }

  try {
    let urlOrigin = '';
    const { origin, pathname, search } = new URL(stringUrl);
    urlOrigin = origin;
    if (domain) {
      const domainURL = new URL(domain);
      urlOrigin = domainURL.origin;
    }
    const urlParams = new URLSearchParams(search.slice(1));
    const params = {};
    Array.from(urlParams.entries()).forEach(([key, value]) => {
      params[key] = value;
    });

    return { url: `${urlOrigin}${pathname}`, params };
  } catch (e) {
    console.error(`Error while parsing url ${stringUrl}`, e);
    return { url: null, params: {} };
  }
}
export function paginationWithDots(currentPage, totalPages) {
  let delta = 1;
  if (currentPage - delta <= 0) {
    delta = 2;
  }
  if (currentPage + delta > totalPages) {
    delta = 2;
  }
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range = [];
  const rangeWithDots = [];
  let item;

  for (let i = 1; i <= totalPages; i += 1) {
    if (i === 1 || i === totalPages || (i >= left && i < right)) {
      range.push(i);
    }
  }

  range.forEach((i) => {
    if (item) {
      if (i - item !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    item = i;
  });

  return rangeWithDots;
}
