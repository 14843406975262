<template>
  <div class="form-input">
    <input
      type="text"
      :class="hasErrors && 'has-errors'"
      :data-testid="id"
      :ref="id"
      :id="id"
      :name="id"
      :placeholder="placeholder"
      :value="modelValue"
      @input="handleUpdate"
      :style="{
        width: width,
        outlineColor: outline ? '-webkit-focus-ring-color' : 'transparent',
      }"
      :disabled="disabled"
      :aria-label="ariaLabel"
      :autocomplete="autocomplete"
      :aria-describedby="ariaDescribedby"
      @mousedown="outline = false"
      @keyup.tab="outline = true"
    />
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: [String, Boolean],
      default: false,
    },
    hasErrors: Boolean,
    width: {
      type: String,
      default: '250px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: '',
    },
    ariaDescribedby: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      outline: false,
    };
  },
  methods: {
    handleUpdate() {
      this.$emit('update:modelValue', this.$refs[this.id].value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/color_vars';

.form-input {
  display: flex;
  position: relative;

  input {
    --input-bg-color: #{$primary-gray};
    --input-border-color: #{$secondary-gray};
    --input-font-size: 1rem;
    --input-font-color: #{$primary-dark};
    --input-placeholder-color: #{$color-grey-50};
    --input-hover-bg-color: #{$primary-gray};
    --input-disabled-bg-color: #{$primary-gray};
    --input-disabled-border-color: #{$secondary-gray};
    --input-disabled-placeholder-color: #{$primary-dark};
    --input-error-color: #{$primary-red};
    --input-placeholder-font-family: var(--font-family);

    height: 24px;
    padding: 8px;
    background-color: var(--input-bg-color);
    border: none;
    border-bottom: 1px solid var(--input-border-color);
    border-radius: 4px 4px 0 0;
    transition: 0.2s ease;
    color: var(--input-font-color);
    transition: background-color 0.5s;

    &[type='search'] {
      -webkit-appearance: none;
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }

    &::placeholder {
      font-family: var(--input-placeholder-font-family);
      color: var(--input-placeholder-color);
    }

    &:hover {
      background-color: var(--input-hover-bg-color);
    }

    &:disabled {
      background-color: var(--input-disabled-bg-color);
      border-color: var(--input-disabled-border-color);

      &::placeholder {
        color: var(--input-disabled-placeholder-color);
      }
    }

    &.has-errors {
      border: 1px solid var(--input-error-color);

      &:focus {
        border-color: var(--input-error-color);
      }
    }
  }
}
</style>
