import axios from 'axios';

const STATUS = Object.freeze({
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
});

export const state = () => ({
  requestStatus: STATUS.INITIAL
});

export const getters = {
  getIsReqInitial(state) {
    return state.requestStatus === STATUS.INITIAL;
  },
  getIsReqError(state) {
    return state.requestStatus === STATUS.ERROR;
  },
  getIsReqSuccess(state) {
    return state.requestStatus === STATUS.SUCCESS;
  },
  getIsReqLoading(state) {
    return state.requestStatus === STATUS.LOADING;
  },
  getReqStatus(state) {
    return state.requestStatus;
  }
};

export const mutations = {
  setReqStatus(state, payload) {
    if (!payload || !STATUS[payload]) {
      console.error('Invalid requestStatus');
      return;
    }

    state.requestStatus = payload;
  },
  initializeReqStatus(state) {
    state.requestStatus = STATUS.INITIAL;
  }
};

export const actions = {
  async sendContactEmail({ rootState, commit }, { formData, writableApi }) {
    commit('setReqStatus', STATUS.LOADING);
    const url = `${writableApi}api/v4/o/${rootState.secondaryOrgId}/cms/staff_email`;
    try {
      const { email, fullName, message, member, recaptcha_response } = formData;
      const res = await axios.post(url, {
        from_email: email,
        from_name: fullName,
        to_staff_id: member.id,
        body: message,
        'g-recaptcha-response': recaptcha_response,
      });

      if (!res) {
        throw new Error('Undefined response');
      }

      commit('setReqStatus', STATUS.SUCCESS);
    } catch (e) {
      console.error('There was an error making the request.. ', e);
      commit('setReqStatus', STATUS.ERROR);
    }
  }
};
