<template>
  <div
    :class="[
      'ts-xs-'+tsXs,
      'ts-sm-'+tsSm,
      'ts-lg-'+tsLg,
    ]"
    :style="{
      'order':tsOrder,
      'justify-content':tsJustify,
      'display':tsType,
      'align-items':tsAlign,
      'margin-left':tsOffset*100/12+'%',
      'width':tsW*100/12+'%'}"
    class="ts-col"
  >
    <slot />
  </div>
</template>

<script>
export default {
  /* eslint-disable-next-line */
  name:'ts-col',
  props: {
    tsW: {
      type: [Number, String],
      default: 12,
    },
    tsXs: {
      type: [Number, String],
      default: '',
    },
    tsSm: {
      type: [Number, String],
      default: '',
    },
    tsLg: {
      type: [Number, String],
      default: '',
    },
    tsOffset: {
      type: [Number, String],
      default: 0,
    },
    tsType: {
      type: String,
      default: null,
    },
    tsJustify: {
      type: String,
      default: null,
    },
    tsAlign: {
      type: String,
      default: null,
    },
    tsOrder: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>

<style lang="css" scoped>
.ts-col {
  float: left;
}
.ts-lg-12 {
  width: 100% !important;
}
.ts-lg-11 {
  width: 91.66666666666667% !important;
}
.ts-lg-10 {
  width: 83.33333333333333% !important;
}
.ts-lg-9 {
  width: 75% !important;
}
.ts-lg-8 {
  width: 66.66666666666667% !important;
}
.ts-lg-7 {
  width: 58.33333333333333% !important;
}
.ts-lg-6 {
  width: 50% !important;
}
.ts-lg-5 {
  width: 41.66666666666667% !important;
}
.ts-lg-4 {
  width: 33.33333333333333% !important;
}
.ts-lg-3 {
  width: 25% !important;
}
.ts-lg-2 {
  width: 16.66666666666667% !important;
}
.ts-lg-1 {
  width: 8.333333333333333% !important;
}


@media only screen and (max-width: 900px) {
.ts-sm-12 {
  width: 100% !important;
}
.ts-sm-11 {
  width: 91.66666666666667% !important;
}
.ts-sm-10 {
  width: 83.33333333333333% !important;
}
.ts-sm-9 {
  width: 75% !important;
}
.ts-sm-8 {
  width: 66.66666666666667% !important;
}
.ts-sm-7 {
  width: 58.33333333333333% !important;
}
.ts-sm-6 {
  width: 50% !important;
}
.ts-sm-5 {
  width: 41.66666666666667% !important;
}
.ts-sm-4 {
  width: 33.33333333333333% !important;
}
.ts-sm-3 {
  width: 25% !important;
}
.ts-sm-2 {
  width: 16.66666666666667% !important;
}
.ts-sm-1 {
  width: 8.333333333333333% !important;
}
}

  @media only screen and (max-width: 600px) {
  .ts-xs-12 {
    width: 100% !important;
  }
  .ts-xs-11 {
    width: 91.66666666666667% !important;
  }
  .ts-xs-10 {
    width: 83.33333333333333% !important;
  }
  .ts-xs-9 {
    width: 75% !important;
  }
  .ts-xs-8 {
    width: 66.66666666666667% !important;
  }
  .ts-xs-7 {
    width: 58.33333333333333% !important;
  }
  .ts-xs-6 {
    width: 50% !important;
  }
  .ts-xs-5 {
    width: 41.66666666666667% !important;
  }
  .ts-xs-4 {
    width: 33.33333333333333% !important;
  }
  .ts-xs-3 {
    width: 25% !important;
  }
  .ts-xs-2 {
    width: 16.66666666666667% !important;
  }
  .ts-xs-1 {
    width: 8.333333333333333% !important;
  }
}
</style>
