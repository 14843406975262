const component = {
  NEWS: 'News',
  LIVE_FEED: 'Live Feed',
  EVENTS: 'Events',
  STATS: 'Stats',
  PROGRAMS: 'Programs',
  OUR_SCHOOLS: 'Our Schools',
  ATHLETICS: 'Athletics',
  SPOTLIGHT: 'Spotlight',
  BUTTON: 'Button Bar',
  PARALLAX: 'Parallax',
  CUSTOM_CODE: 'Custom Code',
  CUSTOM_COMPONENT: 'Custom Component',
  MEDIA_CUSTOM_CODE: 'Media Custom Code',
  ABOUT: 'About',
  VIDEO: 'Video',
  TIMELINE: 'Timeline',
};

export default Object.freeze(component);
