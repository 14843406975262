<template>
  <div
    id="view-container"
    class="view-container"
    :style="setContainerBackgroundColors"
  >
    <div
      v-for="position in sortOrder"
      :key="container.components[position].id"
      :class="[setContainerClass(position), { 'accent-styling': accentStyling }]"
      :style="setComponentBackgroundColor(position)"
      class="homepage-component"
      data-testid="homepage-component"
    >
      <!-- <client-only> -->
      <component
        :is="container.components[position].name.replace(/ /g, '')"
        v-if="container.components[position].name"
        :component-idx="position"
        :container-idx="containerIdx"
        :container-id="container.id"
        :class="{ 'sticky-container': hasColumnSticky }"
        :dots-rectangle="dotsRectangleOptions"
      />
      <!-- </client-only> -->
    </div>
    <DecorativeBackground
      v-if="showDecorativeBackground && !hasDotsRectangle"
      :version="decorativeBackgroundType"
      :fill-color="decorativeBackgroundColor"
      :flip-horizontal="isLeftOneThird"
      :class="isLightOrPurple && 'light-or-purple'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import componentEnums from './enums/component-enums';
import containerEnums from './enums/container-enums';
import decorativeBackgroundEnums from './enums/decorative-background-enums';
import LiveFeed from './live_feed/ViewLiveFeed.vue';
import News from './news/ViewNews.vue';
import Events from './events/ViewEvents.vue';
import Programs from './custom/programs/ViewPrograms.vue';
import OurSchools from './custom/our_schools/ViewOurSchools.vue';
import ButtonBar from './custom/buttons/ViewButtons.vue';
import Stats from './custom/stats/ViewStats.vue';
import Spotlight from './custom/spotlights/ViewSpotlights.vue';
import Athletics from './athletics/ViewAthletics.vue';
import CustomCode from './custom/custom_code/ViewCustomCode.vue';
import Parallax from './custom/parallax/ViewParallax.vue';
import About from './custom/about/ViewAbout.vue';
import Video from './custom/video/ViewVideo.vue';
import Timeline from './custom/timeline/ViewTimeline.vue';
import MediaCustomCode from './custom/media_custom_code/ViewMediaCode.vue';
import CustomComponent from './custom/custom_component/ViewCustomComponent.vue';
import DecorativeBackground from './theme_accents/DecorativeBackground.vue';
import screenWidthTracker from '../pages/forms/components/mixins/screen-width-tracker';

export default {
  components: {
    componentEnums,
    containerEnums,
    decorativeBackgroundEnums,
    CustomComponent,
    LiveFeed,
    News,
    Events,
    Programs,
    OurSchools,
    Spotlight,
    Stats,
    Athletics,
    ButtonBar,
    CustomCode,
    Parallax,
    About,
    // eslint-disable-next-line vue/no-reserved-component-names
    Video,
    Timeline,
    MediaCustomCode,
    DecorativeBackground,
  },
  mixins: [screenWidthTracker],
  props: {
    container: {
      type: Object,
      default: () => {},
    },
    containerIdx: {
      type: Number,
      default: null,
    },
    isLastContainer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['accentStyling']),
    layout() {
      return this.container.type;
    },
    hasColumnSticky() {
      if (this.hasCustomCode) return false;

      return this.layout === containerEnums.ONE_THIRD || this.showDotsRectangle;
    },
    showDecorativeBackground() {
      return (
        (this.isLeftOneThird || this.isRightOneThird) &&
        !this.decorativeBackgroundNone &&
        this.accentStyling &&
        !this.isLastContainer &&
        !this.hasCustomCode
      );
    },
    showDotsRectangle() {
      return this.showDecorativeBackground && this.hasDotsRectangle && this.hasNews;
    },
    hasDotsRectangle() {
      return this.decorativeBackgroundType === decorativeBackgroundEnums.DOTS_RECTANGLE;
    },
    dotsRectangleOptions() {
      return {
        show: this.showDotsRectangle,
        color: this.decorativeBackgroundColor,
        flip: this.isLeftOneThird,
      };
    },
    hasNews() {
      return this.container.components.some((component) => component.name.includes(componentEnums.NEWS));
    },
    hasCustomCode() {
      return this.container.components.some((component) =>
        [componentEnums.CUSTOM_CODE, componentEnums.MEDIA_CUSTOM_CODE].includes(component.name),
      );
    },
    decorativeBackgroundNone() {
      return this.decorativeBackgroundType === decorativeBackgroundEnums.NONE;
    },
    isLeftOneThird() {
      return this.layout === containerEnums.LEFT_SIDEBAR;
    },
    isRightOneThird() {
      return this.layout === containerEnums.RIGHT_SIDEBAR;
    },
    setContainerBackgroundColors() {
      if (this.layout === containerEnums.ONE_THIRD) {
        return null;
      }

      return {
        '--bg-ratio': this.bgRatio,
        '--first-color': this.bgColors[0],
        '--second-color': this.bgColors[1] || this.bgColors[0],
      };
    },
    bgColors() {
      return this.container.components.map((component) => get(component.options, 'bg_color.hex', '#fff'));
    },
    bgRatio() {
      if (this.layout === containerEnums.HALF_SIDEBAR) {
        return '50%';
      }
      if (this.isRightOneThird) {
        return 'calc((100% / 3) * 2)';
      }
      return 'calc(100% / 3)';
    },
    decorativeBackgroundType() {
      return get(this.container, 'decorativeBackground.type', 'None');
    },
    decorativeBackgroundColor() {
      return get(this.container, 'decorativeBackground.color.hex', '#000');
    },
    isLightOrPurple() {
      const styles = this.container.components.map((component) => get(component.options, 'style', 'none'));
      return styles.some((style) => ['purple', 'light'].includes(style));
    },
    isMobile() {
      return this.screenSize <= 1280;
    },
    isThreeColumnLayout() {
      return this.layout === containerEnums.ONE_THIRD;
    },
    isFullLayout() {
      return this.layout === containerEnums.FULL;
    },
    sortOrder() {
      let order = [0, 1];
      if (this.isThreeColumnLayout && this.isMobile) {
        order = [0, 2, 1];
      } else if (this.isThreeColumnLayout && !this.isMobile) {
        order = [0, 1, 2];
      } else if (this.isFullLayout) {
        order = [0];
      }
      return order;
    },
  },
  methods: {
    setComponentBackgroundColor(idx) {
      const { options } = this.container.components[idx];
      return { '--component-bg-color': get(options, 'bg_color.hex', '#fff') };
    },
    setContainerClass(idx) {
      const containerClass = ['first', 'fullwidth'];
      if (idx === 1) {
        containerClass[0] = 'last';
      }
      switch (this.layout) {
        case containerEnums.LEFT_SIDEBAR:
          containerClass[1] = 'left-sidebar';
          break;
        case containerEnums.RIGHT_SIDEBAR:
          containerClass[1] = 'right-sidebar';
          break;
        case containerEnums.HALF_SIDEBAR:
          containerClass[1] = 'half-sidebar';
          break;
        case containerEnums.ONE_THIRD:
          containerClass[1] = 'one-third';
          if (idx === 1) {
            containerClass[0] = 'middle';
          } else if (idx === 2) {
            containerClass[0] = 'last';
          }
          break;
        default:
          break;
      }
      return containerClass;
    },
  },
};
</script>

<style scoped lang="scss">
$one-third: calc(100% / 3);

.view-container {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  background: linear-gradient(to right, var(--first-color) var(--bg-ratio), var(--second-color) var(--bg-ratio));
  .homepage-component {
    z-index: 1;
    @media (max-width: 959px) {
      background-color: var(--component-bg-color);
    }
  }
  .active {
    border: 4px solid #009ddb;
  }
  .first.fullwidth {
    width: 100%;
  }
  .first.left-sidebar {
    width: $one-third;
    @media (max-width: 1200px) {
      width: $one-third;
    }
    @media (max-width: 959px) {
      width: 100%;
    }
  }
  .last.left-sidebar {
    flex: 1;
  }
  .first.right-sidebar {
    flex: 1;
  }
  .last.right-sidebar {
    width: $one-third;
    @media (max-width: 1200px) {
      width: $one-third;
    }
    @media (max-width: 959px) {
      width: 100%;
    }
  }
  .half-sidebar {
    width: 50%;
    @media (max-width: 959px) {
      width: 100%;
    }
  }
  .one-third {
    width: $one-third;
    background-color: var(--component-bg-color);
    @media (min-width: 960px) and (max-width: 1280px) {
      width: 50%;
      &.middle {
        width: 100%;
      }
    }
    @media (max-width: 959px) {
      width: 100%;
    }
  }
  .sticky-container {
    position: sticky;
    top: 0;
    height: auto;
  }
}
</style>
