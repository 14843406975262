<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :stroke="fillColor"
      d="M1 11L6 6L1 1"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgArrowPointer',
  props: {
    fillColor: {
      type: String,
      default: '#333333'
    }
  }
};
</script>
