<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title v-if="title">{{ title }}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.35363 0.646447C6.54889 0.841709 6.54889 1.15829 6.35363 1.35355L1.70718 6L6.35363 10.6464C6.54889 10.8417 6.54889 11.1583 6.35363 11.3536C6.15837 11.5488 5.84178 11.5488 5.64652 11.3536L0.292969 6L5.64652 0.646447C5.84178 0.451184 6.15837 0.451184 6.35363 0.646447Z"
      :fill="fillColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'Arrow',
  props: {
    fillColor: {
      type: String,
      default: '#000',
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>
