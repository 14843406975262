<template>
  <cms-about
    v-if="aboutSetUp"
    :theme="aboutOptions"
    :content="aboutContentByVersion"
    :watermark="watermarkLogo"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { stripHtml } from '../../../../helpers/strings.js';

export default {
  name: 'ViewAbout',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview', 'aboutContent', 'globals']),
    ...mapGetters(['getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    id() {
      if (this.isThemePreview) {
        return 'preview_id';
      }
      return `${this.containerId}_${this.componentIdx}`;
    },
    aboutOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('about'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    aboutSetUp() {
      const content = this.homepage.about?.[this.id] || {};
      const hasContent =
        (content.has_image && !isEmpty(content.image)) ||
        !isEmpty(content.links) ||
        !isEmpty(stripHtml(content.component_text));
      return hasContent;
    },
    watermarkLogo() {
      return this.globals.watermark?.url;
    },
    aboutContentByVersion() {
      return this.isThemePreview ? this.aboutContent[this.aboutOptions.version] : this.homepage.about[this.id];
    },
  },
};
</script>
