<template>
  <button
    :style="colorOverrides ? colorStyles : {}"
    :class="[
      'a-button',
      `a-button--${variant}`,
      `a-button--mode-${mode}`,
      `a-button--size-${size}`,
      selected && `is-selected`,
      fullWidth && `full-width`,
    ]"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
const BUTTON_VARIANTS = ['default', 'primary', 'secondary', 'custom'];
const BUTTON_MODES = ['default', 'error', 'success', 'warning', 'info'];
const BUTTON_SIZES = ['small', 'medium'];
const validate = (availableValues) => (propVal) => availableValues.includes(propVal);

export default {
  name: 'FormButton',
  props: {
    /**
     * Defines the variant of the Button.
     * @values default, primary, secondary, tertiary, link
     */
    variant: {
      type: String,
      default: 'default',
      validator: validate(BUTTON_VARIANTS),
    },
    /**
     * Changes the intent and color of the Button
     * @values default, error, success, warning, info
     */
    mode: {
      type: String,
      default: 'default',
      validator: validate(BUTTON_MODES),
    },
    /**
     * Changes size of the Button
     * @values small, medium
     */
    size: {
      type: String,
      default: 'medium',
      validator: validate(BUTTON_SIZES),
    },
    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Selected state
     */
    selected: {
      type: Boolean,
      default: false,
    },
    /**
     * Override Colors for buttons coming from theme config
     */
    colorOverrides: {
      type: Object,
      default: null,
    },
    /**
     * Overrides button width to fit container
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorStyles() {
      return {
        '--button-outline': '#F3F3F3',
        '--button-default-bg': '#F3F3F3',
        // Disabled
        '--button-disabled-bg': '#F3F3F3',
        '--button-disabled-text': '#919191',
        // Primary
        '--button-primary-bg': '#F3F3F3',
        '--button-primary-bg-hover': '#F3F3F3',
        '--button-primary-text': '#F3F3F3',
        '--button-border': '1px solid transparent',
        '--button-border-active': '1px solid transparent',
        '--button-border-radius': '3px',
        // Secondary
        '--button-secondary-bg': '#adadad',
        '--button-secondary-bg-hover': '#adadad',
        '--button-secondary-text': '#333333',
        ...this.colorOverrides,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/color_vars';

.a-button {
  &--mode-default {
    --button-outline: #{$color-primary-purple};
    --button-disabled-bg: #{$color-grey-20};
    --button-disabled-text: #{$color-grey-40};
    --button-primary-bg: #{$color-primary-purple};
    --button-primary-bg-hover: #{$color-purple-60};
    --button-primary-text: #{$color-primary-white};
    --button-secondary-bg: #{$color-purple-20};
    --button-secondary-bg-hover: #{$color-purple-30};
    --button-secondary-text: #{$color-primary-purple};
    --button-tertiary-bg: #{$color-primary-white};
    --button-tertiary-bg-hover: #{$color-purple-30};
    --button-tertiary-text: #{$color-primary-purple};
    --button-tertiary-border: #{$color-purple-20};
    --button-tertiary-border-hover: #{$color-purple-30};
    --button-link-text: #{$color-primary-purple};
    --button-link-text-hover: #{$color-purple-60};
    --button-select-border: #{$color-primary-purple};
    --button-select-border-hover: #{$color-primary-purple};
    --button-not-selected-bg-hover: #{$color-purple-20};
    --button-not-selected-border-hover: #{$color-purple-30};
  }

  &--mode-error {
    --button-outline: #{$color-red};
    --button-disabled-bg: #{$color-grey-20};
    --button-disabled-text: #{$color-grey-40};
    --button-primary-bg: #{$color-red};
    --button-primary-bg-hover: #{$color-red-60};
    --button-primary-text: #{$color-primary-white};
    --button-secondary-bg: #{$color-red-20};
    --button-secondary-bg-hover: #{$color-red-30};
    --button-secondary-text: #{$color-red};
    --button-tertiary-bg: #{$color-primary-white};
    --button-tertiary-bg-hover: #{$color-red-30};
    --button-tertiary-text: #{$color-red};
    --button-tertiary-border: #{$color-red-20};
    --button-tertiary-border-hover: #{$color-red-30};
    --button-link-text: #{$color-red};
    --button-link-text-hover: #{$color-red-60};
  }

  border-radius: var(--button-border-radius);
  border: var(--button-border);
  outline: none;
  appearance: none;
  background: var(--button-default-bg);
  margin: 4px;
  user-select: none;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;

  &:before,
  &:after {
    box-sizing: content-box;
  }
  &:focus {
    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      border: 1px solid var(--button-outline);
      border-radius: 4px;
    }
  }
  &:disabled {
    background-color: var(--button-disabled-bg);
    color: var(--button-disabled-text);
    cursor: default;
    outline: none;
    &:hover {
      background-color: var(--button-disabled-bg);
    }
  }

  &--size {
    &-medium {
      line-height: 20px;
      padding: 6px 16px;
      &.a-button--tertiary {
        padding: 5px 15px;
      }
    }

    &-small {
      line-height: 16px;
      padding: 4px 8px;
      &.a-button--tertiary {
        padding: 3px 7px;
      }
    }
  }

  &:hover,
  &.is-selected {
    background: var(--button-primary-bg-hover);
    border-radius: var(--button-border-radius);
    border: var(--button-border-active);
  }

  &--primary {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
    &:hover {
      background-color: var(--button-primary-bg-hover);
      filter: brightness(0.9);
    }
  }

  &--secondary {
    background-color: var(--button-secondary-bg);
    color: var(--button-secondary-text);
    &:hover {
      background-color: var(--button-secondary-bg-hover);
    }
  }

  &--custom {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
    &:hover {
      background-color: var(--button-primary-bg-hover);
    }
  }

  &.full-width {
    flex: 1;
    width: 100%;
    justify-content: center;
  }
}
</style>
