/**
 * Dispatches a custom event on a specified target with any given data.
 *
 * Simplifies the process of creating and dispatching custom events by automatically wrapping
 * data in a `detail` object, as required by the CustomEvent API.
 *
 * @param {string} eventName - The name of the custom event.
 * @param {*} data - Any data to be passed with the event. This will be placed inside the `detail` property.
 * @param {Object} [target=window] - The target on which to dispatch the event. Most common use cases of this would be an EventTarget in the web api such as Document or Window. Defaults to the window object.
 * @throws {TypeError} If the eventName is not a string.
 */
function dispatchCustomEvent(eventName, data, target = window) {
  if (typeof eventName !== 'string') {
    throw new TypeError('eventName must be a string');
  }

  if (typeof target !== 'object' || target === null) {
    throw new TypeError('target must be an object');
  }

  try {
    const event = new CustomEvent(eventName, { detail: data });
    target.dispatchEvent(event);
  } catch (error) {
    console.error('Failed to dispatch custom event:', error);
  }
}

/**
 * Dispatches a custom event for client work window.
 *
 * @param {string} name - The name of the section.
 * @yields {CustomEvent} Dispatches a custom event with the section name to the client work window.
 * @throws {TypeError} If the section is not a string.
 */
const clientWorkWindowEvent = (name) => {
  if (typeof name !== 'string') throw new Error(`clientWorkWindowEvent parameter must be a string`);

  dispatchCustomEvent('clientWorkEvent', { name }, window);
};

export { clientWorkWindowEvent };
