<template>
  <div class="result">
    <div class="tag-and-time">
      <a
        class="tag"
        :href="`${item.link}/staff`"
      >
        {{ $t('search.staff.tagName') }}
      </a>
      <div
        v-if="item.department"
        class="department"
      >
        {{ item.department }}
      </div>
    </div>
    <div class="directory-info">
      <div class="personal-info">
        <a
          :href="link"
          class="name"
        >
          {{ item.full_name }}
        </a>
        <p
          v-if="item.title"
          class="title"
        >
          {{ item.title }}
        </p>
        <p
          v-if="!isMobile"
          class="org-name"
        >
          {{ item.secondary_organization.name }}
        </p>
      </div>
      <div
        v-if="contactInfoExists"
        class="contact-section"
      >
        <div class="contact-heading">
          {{ $t('search.staff.contact') }}
        </div>
        <p
          v-if="item.phone_number"
          class="phone"
        >
          <img
            src="~/assets/phone.svg"
            :alt="$t('search.staff.makeCall')"
          />
          <span class="contact-info">{{ item.phone_number }}</span>
        </p>
        <p
          v-if="item.email && emailSettings === 'display_emails'"
          class="email"
        >
          <img
            src="~/assets/envelope.svg"
            :alt="$t('search.staff.sendEmail')"
          />
          <a
            :href="`mailto:${item.email}`"
            class="contact-info"
            >{{ item.email }}</a
          >
        </p>
        <p
          v-if="isMobile"
          class="org-name"
        >
          {{ item.secondary_organization.name }}
        </p>
      </div>
      <div
        v-if="item.avatar_url"
        class="directory-image"
      >
        <img
          :src="item.avatar_url"
          :alt="$t('search.staff.avatar', { name: item.full_name })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'DirectoryPreview',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isMobile: Boolean,
  },
  computed: {
    ...mapState({
      emailSettings: (state) => state.settings.staffEmails,
    }),
    link() {
      return `${this.item.link || ''}/staff?id=${this.item.id}`;
    },
    contactInfoExists() {
      return !isEmpty(this.item.phone_number) || !isEmpty(this.item.email);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../global/scss/mixins/_breakpoints.scss';

.result {
  box-sizing: border-box;
  .tag-and-time {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tag,
    .department {
      line-height: 12px;
      letter-spacing: 0.4px;
      font-size: 14px;
      font-weight: 500;
    }
    .tag {
      background: #f3f3f3;
      color: #333333;
      text-decoration: none;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
      border-radius: 20px;
      width: fit-content;
      margin-left: 2px;
      margin-right: 12px;
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
    .department {
      color: #747474;
    }
  }
  .directory-info {
    padding: 32px 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #ceecec;
    margin-right: 8px;
    min-height: 138px;
    width: auto;
    .personal-info {
      width: 300px;
      margin-bottom: 16px;
      .name,
      .title,
      .org-name {
        letter-spacing: 0.4px;
        color: #333;
      }
      .name {
        font-size: 20px;
        font-weight: 700;
        line-height: 16px;
        text-decoration: none;
        color: var(--link-color);
        &:hover {
          text-decoration: underline;
        }
        &:focus {
          outline: #662e80 solid 2px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        margin-top: 16px;
      }
      .org-name {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .contact-section {
      margin-left: 24px;
      width: 45%;
      .contact-heading {
        color: #333333;
        font-size: 20px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-bottom: 16px;
      }
      .phone,
      .email {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background: #f3f3f3;
        margin: 6px 0;
        width: fit-content;
        img {
          margin-right: 8px;
        }
        .contact-info {
          color: #747474;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
      }
      .email a {
        cursor: pointer;
        text-decoration: none;
        &:focus-within {
          outline: #662e80 solid 2px;
        }
      }
    }
    .directory-image {
      margin-left: 24px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }
}
@include screen('xs') {
  .result .directory-info {
    .personal-info .title {
      margin-top: 12px;
    }
    .contact-section {
      margin-left: 0;
      width: 100%;
      .contact-heading {
        margin-bottom: 8px;
      }
      .org-name {
        margin-top: 20px;
      }
    }
  }
}
</style>
