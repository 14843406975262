const NuxtServerInitApis = ({ $axios, $staticFetch }) => ({
  fromStaticCDN: () => ({
    fetchOrgDetails: (pathToS3BucketFromRequestUrl) => {
      const url = `/domains/${pathToS3BucketFromRequestUrl}/organization_details.json`;
      return $staticFetch.get(url);
    },
    fetchSiteMetadata: (orgDetails) => {
      const url = `/content/${orgDetails.parent_organization_id}/${orgDetails.secondary_organization_id}/metadata.json`;
      return $staticFetch.get(url);
    },
    fetchFeatureFlags: (parentOrganizationId) => {
      const url = `/content/${parentOrganizationId}/feature_flags.json`;
      return $staticFetch.get(url);
    },
    fetchSiteStructure: (orgDetails, locale) => {
      const url = `/content/${orgDetails.parent_organization_id}/${orgDetails.secondary_organization_id}/${locale}/homepage_structure.json`;
      return $staticFetch.get(url);
    },
  }),

  fromThrillshareAPI: () => ({
    fetchIntranetOrgDetails: (secondaryOrganizationId) => {
      const url = `/api/v6/secondary_organizations/${secondaryOrganizationId}/info`;
      return $axios.get(url);
    },
    fetchOrgDetails: (domain, alias) => {
      const url = 'api/v4/cms/organization_details';
      return $axios.get(url, {
        params: {
          domain,
          ...(alias && { alias }),
        },
      });
    },
    fetchFeatureFlags: (parentOrganizationId) => {
      const url = `api/v4/p/${parentOrganizationId}/feature_flags`;
      return $axios.get(url);
    },
    fetchIntranetSiteStructure: (secondaryOrganizationId, locale, authCookie) => {
      const url = `api/v6/intranet/site_structures`;
      return $axios.get(url, {
        headers: {
          Authorization: authCookie,
        },
        params: {
          secondary_organization_id: secondaryOrganizationId,
          locale: locale || 'en',
        },
      });
    },
    fetchSiteStructureBySecOrgId: (secondary_organization_id, locale) => {
      const url = 'api/v4/cms/site_structure';
      return $axios.get(url, {
        params: {
          secondary_organization_id,
          locale: locale || 'en',
        },
        cache: {
          ignoreCache: false,
        },
      });
    },
    fetchSiteStructureByDomain: (domain, alias, locale) => {
      const url = 'api/v4/cms/site_structure';
      return $axios.get(url, {
        params: {
          domain,
          ...(alias && { alias }),
          locale: locale || 'en',
        },
        cache: {
          ignoreCache: false,
        },
      });
    },
  }),
});
export default NuxtServerInitApis;
