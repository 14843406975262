<template>
  <cms-parallax
    v-if="parallaxContent"
    :config="parallaxSetUp"
  />
</template>

<script>
import { mapState } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'ViewButtons',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview']),
    id() {
      return this.isThemePreview ? 'preview_id' : `${this.containerId}_${this.componentIdx}`;
    },
    parallaxSetUp() {
      return this.homepage.parallax[this.id];
    },
    parallaxContent() {
      return !isEmpty(this.homepage?.parallax?.[this.id]?.background_image);
    },
  },
};
</script>
