<template>
  <svg
    class="button"
    tabindex="-1"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @keydown.enter.exact.prevent="onKeyDown()"
    @keydown.space.exact.prevent="onKeyDown()"
  >
    <circle
      r="24"
      transform="matrix(-1 0 0 1 24 24)"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.8536 14.1464C27.6583 13.9512 27.3417 13.9512 27.1464 14.1464L18.1464 23.1464C17.9512 23.3417 17.9512 23.6583 18.1464 23.8536L27.1464 32.8536C27.3417 33.0488 27.6583 33.0488 27.8536 32.8536C28.0488 32.6583 28.0488 32.3417 27.8536 32.1464L19.2071 23.5L27.8536 14.8536C28.0488 14.6583 28.0488 14.3417 27.8536 14.1464Z"
      fill="#333333"
    />
  </svg>
</template>
<script>
export default {
  name: 'Arrow',
  props: {
    fillColor: {
      type: String,
      default: 'white',
    },
    onKeyDown: {
      type: Function,
      default: () => {}
    },
  }
};
</script>
