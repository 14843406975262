import { clientWorkWindowEvent } from '../helpers/event-dispatch-helpers';


/**
 * Checks if the pagination has changed between two route transitions.
 * @param {Object} to - The destination route object.
 * @param {Object} from - The origin route object.
 * @returns {boolean} - Returns true if the pagination has changed, false otherwise.
 */
const checkPagination = (to, from) => {
  const toPage = to.query?.page_no;
  const fromPage = from.query?.page_no;
  return toPage && toPage !== fromPage;
};

export default defineNuxtPlugin(({ $router }) => {
  $router.afterEach((to, from) => {
    if (!from.name) return false;
    const paginationTrigger = checkPagination(to, from);
    if (paginationTrigger) {
      requestAnimationFrame(() => {
        clientWorkWindowEvent('paginationChange');
        return;
      });
    }
    clientWorkWindowEvent('internalRouteChange');
  });
});
