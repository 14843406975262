<template>
  <div class="lines-divider">
    <div
      v-for="(n, idx) in 3"
      :key="idx"
      class="accent"
    />
  </div>
</template>
<script>
export default {
  name: 'LinesDivider'
};
</script>

<style lang="scss" scoped>
.lines-divider {
  margin-top: 6px;
  .accent {
    width: 100%;
    height: 1px;
    margin-bottom: 6px;
    background-color: var(--header-accent-color);
  }
}
</style>
