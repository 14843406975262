<template>
  <div
    v-show="showCalendarButtons"
    class="button-container"
    :class="showCalendarButtons && 'open'"
  >
    <AddToCalendar
      v-for="(type, idx) in CALENDARS"
      :key="idx"
      :calendar-type="type"
      :event-data="eventData"
      :color-overrides="colorOverrides"
    />
    <FormButton
      variant="default"
      tabindex="0"
      :full-width="true"
      :color-overrides="colorOverrides"
      class="back-calendar-button"
      @click="handleBackButtonClick"
      @keydown.enter.exact="handleBackButtonClick"
      @keydown.tab.exact="handleBlur"
    >
      <img
        v-if="useCloseIcon"
        src="../../../../../assets/close-icon.svg"
        :alt="$t('search.closeOptions')"
      />
      <span v-else>{{ $t('events.back') }}</span>
    </FormButton>
  </div>
</template>

<script>
import { CALENDARS } from '../../../../../helpers/calendar.helper';
import FormButton from '../../../../global/Forms/FormButton';
import AddToCalendar from '../shared/AddToCalendar';

export default {
  name: 'EventListButtons',
  components: {
    FormButton,
    AddToCalendar,
  },
  props: {
    handleBackButtonClick: {
      type: Function,
      default: () => {},
    },
    handleBlur: {
      type: Function,
      default: () => {},
    },
    colorOverrides: {
      type: Object,
      default: null,
    },
    eventData: {
      type: Object,
      default: null,
    },
    showCalendarButtons: Boolean,
    useCloseIcon: Boolean,
  },
  data() {
    return {
      CALENDARS,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../../../global/scss/mixins/flex_mixin';
@import '../../../../global/scss/mixins/breakpoints';

.button-container {
  @include flex-row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;

  @media (min-width: $md) {
    @include flex-column;
    justify-content: stretch;
  }

  &.open {
    @include flex-column;
    justify-content: stretch;
  }

  & p.button-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    text-align: center;
    letter-spacing: 0.4px;

    color: #333333;
  }
  .back-calendar-button:hover {
    color: #ffffff;
  }
}

.calendar-buttons {
  @include flex-column;
}
</style>
