import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin(({ vueApp }) => {
  // TODO do not execute on all pages
  vueApp.use(VueReCaptcha, {
    siteKey: useRuntimeConfig().public.RECAPTCHA_V3_SITE_KEY || 'fake-key',
    loaderOptions: {
      autoHideBadge: true,
    },
  });
});
