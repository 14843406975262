export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive('click-outside', {
    mounted(el, binding) {
      const { bubble } = binding.modifiers;
      const handler = function handler(e) {
        if (bubble || (!el.contains(e.target) && el !== e.target)) {
          binding.value(e);
        }
      };
      el.vueClickOutside = handler;
      document.addEventListener('click', handler);
    },
    unmounted(el) {
      document.removeEventListener('click', el.vueClickOutside);
      el.vueClickOutside = null;
    },
  });
});
