import revive_payload_client_1gvn6ozUSe from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yguEpdejGH from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JEf1Wy4yOP from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8DqY6c6uqa from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Y6u1DbYK7f from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uDxSEmsNR6 from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sUBWrM2qJB from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/cms_public/.nuxt/components.plugin.mjs";
import prefetch_client_UajpUcWxMT from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_encoding@0.1.13_eslint@9.21.0_su5i2nv3zxoxzus5opjsydha7q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_WEUayfBVmT from "/app/cms_public/node_modules/.pnpm/@apptegy+nuxt-auth@2.1.3_magicast@0.3.5_rollup@4.34.8/node_modules/@apptegy/nuxt-auth/dist/runtime/plugins/auth.js";
import plugin_9vuemtLesE from "/app/cms_public/node_modules/.pnpm/@apptegy+nuxt-intl@2.1.0_magicast@0.3.5_rollup@4.34.8/node_modules/@apptegy/nuxt-intl/dist/runtime/plugin.mjs";
import plugin_C9EJeEmr7F from "/app/cms_public/node_modules/.pnpm/nuxt3-vuex-module@1.1.2_vue@3.5.12_typescript@5.7.3_/node_modules/nuxt3-vuex-module/src/plugin.js";
import _00_staticFetch_T3Cfca6Cds from "/app/cms_public/plugins/00.staticFetch.js";
import _02_axios_client_LTXr1tzQVf from "/app/cms_public/plugins/02.axios.client.js";
import appsignal_client_uxZI1jvyNS from "/app/cms_public/plugins/appsignal.client.js";
import apptegyComponents_8cwWUkONTI from "/app/cms_public/plugins/apptegyComponents.js";
import click_outside_oytHm5ka8v from "/app/cms_public/plugins/click-outside.js";
import client_work_state_client_urfUnQS0xA from "/app/cms_public/plugins/client-work-state.client.js";
import google_analytics_client_vKtiHLcz65 from "/app/cms_public/plugins/google-analytics.client.js";
import intl_EbJJrNoJ81 from "/app/cms_public/plugins/intl.js";
import pagination_route_change_event_client_dYWcYIpCWk from "/app/cms_public/plugins/pagination-route-change-event.client.js";
import recaptcha_client_ePVGy12Odm from "/app/cms_public/plugins/recaptcha.client.js";
import v_calendar_client_Eahmfkhy07 from "/app/cms_public/plugins/v-calendar.client.js";
export default [
  revive_payload_client_1gvn6ozUSe,
  unhead_yguEpdejGH,
  router_JEf1Wy4yOP,
  payload_client_8DqY6c6uqa,
  navigation_repaint_client_Y6u1DbYK7f,
  check_outdated_build_client_uDxSEmsNR6,
  chunk_reload_client_sUBWrM2qJB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UajpUcWxMT,
  auth_WEUayfBVmT,
  plugin_9vuemtLesE,
  plugin_C9EJeEmr7F,
  _00_staticFetch_T3Cfca6Cds,
  _02_axios_client_LTXr1tzQVf,
  appsignal_client_uxZI1jvyNS,
  apptegyComponents_8cwWUkONTI,
  click_outside_oytHm5ka8v,
  client_work_state_client_urfUnQS0xA,
  google_analytics_client_vKtiHLcz65,
  intl_EbJJrNoJ81,
  pagination_route_change_event_client_dYWcYIpCWk,
  recaptcha_client_ePVGy12Odm,
  v_calendar_client_Eahmfkhy07
]