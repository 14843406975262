<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 7L12 11L4 11L8 7Z"
      :fill="fillColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'Caret',
  props: {
    fillColor: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
  .caret-closed {
    transform: rotate(180deg);
  }
  .caret-open {
    transform: rotate(0deg);
  }
</style>
