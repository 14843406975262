<template>
  <textarea
    :class="['form-textarea', hasErrors && 'has-errors']"
    :ref="id"
    :id="id"
    :name="id"
    :required="required"
    :disabled="disabled"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :aria-describedby="ariaDescribedby"
    :value="modelValue"
    @input="handleUpdate"
  ></textarea>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasErrors: Boolean,
    required: Boolean,
    disabled: Boolean,
    autocomplete: {
      type: String,
      default: '',
    },
    ariaDescribedby: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleUpdate() {
      this.$emit('update:modelValue', this.$refs[this.id].value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/color_vars';

.form-textarea {
  --textarea-bg-color: #{$primary-gray};
  --textarea-border-color: #{$secondary-gray};
  --textarea-font-size: 1rem;
  --textarea-font-color: #{$primary-dark};
  --textarea-placeholder-font-family: var(--font-family);
  --textarea-placeholder-color: #{$color-grey-50};
  --textarea-hover-bg-color: #{$primary-gray};
  --textarea-disabled-bg-color: #{$primary-gray};
  --textarea-disabled-border-color: #{$secondary-gray};
  --textarea-disabled-placeholder-color: #{$primary-dark};

  --textarea-color: #{$primary-dark};
  --textarea-focus-border-color: #{$secondary-gray};

  --textarea-error-color: #{$primary-red};

  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  background: var(--textarea-bg-color);
  padding: 12px;
  border: none;
  border-bottom: 1px solid var(--textarea-border-color);
  border-radius: 4px 4px 0 0;
  line-height: 1.4em;
  color: var(--textarea-color);
  font-family: var(--textarea-placeholder-font-family);
  transition: 0.2s ease;

  &::placeholder {
    font-family: var(--textarea-placeholder-font-family);
    color: var(--textarea-placeholder-color);
  }

  &:focus {
    border-bottom-color: var(--textarea-focus-border-color);
    outline: none;
  }

  &:hover {
    background-color: var(--textarea-hover-bg-color);
  }

  &:disabled {
    background-color: var(--textarea-disabled-bg-color);
    border-color: var(--textarea-disabled-border-color);

    &::placeholder {
      color: var(--textarea-disabled-placeholder-color);
    }
  }

  &.has-errors {
    border: 1px solid var(--textarea-error-color);

    &:focus {
      border-color: var(--textarea-error-color);
    }
  }
}
</style>
