<template>
  <form-button
    variant="custom"
    mode="default"
    class="add-to-calendar-button"
    :class="{ disabled: this.iCalError }"
    tabindex="0"
    :full-width="true"
    :color-overrides="colorOverrides"
    @click="handleClick"
    @keydown.enter.exact.stop="handleClick"
  >
    {{ $t(`events.calendarType.${calendarType}`) }}
  </form-button>
</template>

<script>
import {
  CALENDARS,
  validate,
  buildICalEvent,
  downloadToFile,
  buildOnlineCalendarUrl,
} from '../../../../../helpers/calendar.helper';
import FormButton from '../../../../global/Forms/FormButton';

export default {
  name: 'AddToCalendar',
  components: { FormButton },
  props: {
    calendarType: {
      type: String,
      default: CALENDARS.Google,
      validator: validate(CALENDARS),
    },
    eventData: {
      type: Object,
      default: () => ({}),
    },
    colorOverrides: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      CALENDARS,
      iCalError: false,
    };
  },
  methods: {
    iCalDownload(content) {
      downloadToFile(content, `${this.eventData.title}.ics`);
    },
    handleClick() {
      if (this.iCalError) {
        return;
      }
      if (this.calendarType === CALENDARS.iCal) {
        const iCalResult = buildICalEvent(this.eventData);
        if (iCalResult.error?.errors.length > 0) {
          console.error(...iCalResult.error.errors);
          this.iCalError = true;
          return;
        }
        this.iCalDownload(iCalResult.value);
        return;
      }

      window.open(buildOnlineCalendarUrl(this.calendarType, this.eventData), '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../global/scss/color_vars';

.add-to-calendar-button {
  color: #000000;
  &:hover {
    color: #ffffff;
  }
  &.disabled {
    background-color: $color-grey-10;
    color: $color-grey-40;
  }
}
</style>
