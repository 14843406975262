<template>
  <div id="error">
    <p>We couldn't find the page you are looking for. We apologize for the inconvenience. Please click here to go Home, or here to back to the previous page.</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style scoped lang="scss">
#error {
  min-height: 50vh;
  padding: 40px 0;
  p{
    text-align: center;
  }
}
</style>
