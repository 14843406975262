<template>
  <div id="view-footer">
    <template v-if="getTheme.footer.options.version !== 'CustomFooter'">
      <cms-footer
        v-if="footerSetUp"
        :theme="footerOptions"
        :content="getHomepage.footer"
        :organizations="getOrganizations"
        :current-domain="getHost"
        :ios-link="getIOSLink"
        :android-link="getAndroidLink"
      />
    </template>
    <template v-else>
      <cms-footer
        :theme="footerOptions"
        :content="getHomepage.footer"
        :organizations="getOrganizations"
        :current-domain="getHost"
        :ios-link="getIOSLink"
        :android-link="getAndroidLink"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'ViewFooter',
  computed: {
    ...mapGetters([
      'getHomepage',
      'getTheme',
      'getOrganizations',
      'getIOSLink',
      'getAndroidLink',
      'getHost'
    ]),
    ...mapGetters('translation', ['getComponentTranslation']),
    footerSetUp() {
      return this.getHomepage.footer && !isEmpty(this.getHomepage.footer);
    },
    footerOptions() {
      return {
        ...this.getTheme.footer.options,
        translations: this.getComponentTranslation('footer')
      };
    }
  }
};
</script>

<style scoped lang="scss"></style>
