import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

// const hideLogs = process.env.NODE_ENV !== 'development' || process.argv.includes('--silent');
const hideLogs = process.env.NODE_ENV !== 'development';

const buildUrl = ({ url, params, method }) => {
  const escapedParams = Object.entries(params || {}).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
  return `${method.toUpperCase()} - ${url}${escapedParams ? `?${escapedParams}` : ''}`;
};

const logRequest = (config) => {
  console.log(`[ Making client side request to ${buildUrl(config)}]`);
  return config;
};

const logError = () => (error) => {
  const { response, config } = error;
  const code = response && response.status;
  const errorMessage = `[ Failed Request to: ${buildUrl(config)} with status code ${code} ]`;
  // eslint-disable-next-line no-param-reassign
  error.message = errorMessage;
  // eslint-disable-next-line no-param-reassign
  error.name = 'Failed Request';

  console.error(errorMessage);
};

export default defineNuxtPlugin(({ provide }) => {
  const authCookie = useCookie('auth-token');
  const axiosInstance = axios.create({
    baseURL: useRuntimeConfig().public.AXIOS_BROWSER_BASE_API_URL,
    headers: {
      Authorization: authCookie.value ? authCookie.value : undefined,
    },
    transformRequest: [
      (data, headers) => {
        headers['Content-Type'] ||= 'application/json';
        // We need to strinfify the JSON only when content type is JSON, otherwise it needs to be plain data
        return headers['Content-Type'] === 'application/json' ? JSON.stringify(data) : data;
      },
    ],
  });

  const axiosWithCache = setupCache(axiosInstance, {
    cache: {
      ttl: 15 * 60 * 1000,
    }
  });

  if (!hideLogs && !process.env.AXIOS_LISTENERS_LOADED) {
    // TODO should these be rejecting promises? https://github.com/axios/axios?tab=readme-ov-file#interceptors
    axiosWithCache.interceptors.request.use(logRequest);
    axiosWithCache.interceptors.response.use((res) => res, logError);
    process.env.AXIOS_LISTENERS_LOADED = true;
  }
  provide('axios', axiosWithCache);
});
