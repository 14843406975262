<template>
  <cms-alert-modal
    v-if="!hideAlerts.modal && isAlertModal && openModal"
    :globals="globals"
    :alert-content="activeLegacyAlert.content"
    :translations="componentTranslations.alert_modal"
    @close="closeAlertModal"
  />
  <cms-alert-banner
    v-else-if="!hideAlerts.banner && isAlertBanner"
    :globals="globals"
    :alert-content="activeLegacyAlert.content"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LegacyAlert',
  props: {
    activeAlerts: {
      type: Array,
      default: () => [],
    },
    hideAlerts: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { openModal: false };
  },
  computed: {
    ...mapState(['globals']),
    ...mapState('translation', ['componentTranslations']),
    activeLegacyAlert() {
      return this.activeAlerts.length > 0 ? this.activeAlerts[0] : {};
    },
    isAlertModal() {
      return Object.keys(this.activeLegacyAlert).length > 0 && this.activeLegacyAlert.light_box;
    },
    isAlertBanner() {
      return Object.keys(this.activeLegacyAlert).length > 0 && !this.activeLegacyAlert.light_box;
    },
  },
  beforeMount() {
    this.openModal = Object.keys(this.activeLegacyAlert).length > 0 && this.isAlertModal && !this.hideAlerts.modal;
  },
  methods: {
    closeAlertModal(closeObject) {
      this.openModal = false;
      const objectToEmit = {
        alertId: 'legacy',
        closedByKeyboard: closeObject.closedByKeyboard,
      };
      this.$emit('close', objectToEmit);
    },
  },
};
</script>
