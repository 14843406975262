<template>
  <div class="alert-container">
    <component
      :is="isLegacyAlert ? 'LegacyAlert' : 'SeparateAlert'"
      v-if="hasAlertsToDisplay"
      :active-alerts="activeAlerts"
      :hide-alerts="hideAlerts"
      @close="handleAlertClose"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SeparateAlert from './SeparateAlert.vue';
import LegacyAlert from './LegacyAlert.vue';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'AlertContainer',
  components: {
    SeparateAlert,
    LegacyAlert,
  },
  props: {
    hideAlerts: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['keyboard-close'],
  data() {
    return {
      dismissedAlertsInSessionStorage: {},
    };
  },
  computed: {
    ...mapState(['featureFlags', 'secondaryOrgId', 'alertBannerConfig', 'alertBannerSeparate']),
    isLegacyAlert() {
      return !this.featureFlags.cms_separate_alert_banner_enabled;
    },
    publishedAlerts() {
      if (this.isLegacyAlert) return this.alertBannerConfig.enabled ? [this.alertBannerConfig] : [];

      return this.alertBannerSeparate?.filter((banner) => banner.status === 'published')?.reverse() || [];
    },
    activeAlerts() {
      if (!this.publishedAlerts.length || this.$route.name === 'page-preview-slug') return [];
      if (this.hasNoDismissedAlerts) return this.filterAlertsToDisplay(this.publishedAlerts);

      const nonDismissedAlerts = this.publishedAlerts.filter((item) => {
        const itemId = this.isLegacyAlert ? 'legacy' : item.id.toString();
        return !this.dismissedAlertIds.has(itemId);
      });

      return this.filterAlertsToDisplay(nonDismissedAlerts);
    },
    hasAlertsToDisplay() {
      return !isEmpty(this.activeAlerts);
    },
    orgSpecificAlerts() {
      return this.dismissedAlertsInSessionStorage[this.secondaryOrgId] || {};
    },
    hasNoDismissedAlerts() {
      return isEmpty(this.dismissedAlertsInSessionStorage) || isEmpty(this.orgSpecificAlerts);
    },
    dismissedAlertIds() {
      return Object.entries(this.orgSpecificAlerts).reduce((acc, [key, value]) => {
        if (value === 'hidden') {
          acc.add(key);
        }
        return acc;
      }, new Set());
    },
    isHomepageOrPreview() {
      return ['index', 'preview-token-id', 'alias-index', 'alias-preview-token-id'].includes(this.$route.name);
    },
  },
  beforeMount() {
    this.dismissedAlertsInSessionStorage = this.getSessionStorage();
  },
  methods: {
    handleAlertClose(alertObject) {
      const { alertId, closedByKeyboard } = alertObject;
      if (closedByKeyboard) {
        this.$nextTick(() => this.$emit('keyboard-close'));
      }
      this.updateDismissedAlerts(alertId);
    },
    updateDismissedAlerts(alertId) {
      const orgBanners = {
        ...this.orgSpecificAlerts,
        [alertId]: 'hidden',
      };
      const objectToStore = {
        ...this.dismissedAlertsInSessionStorage,
        [this.secondaryOrgId]: orgBanners,
      };
      window.sessionStorage.setItem('thrillshare_alert_banner', JSON.stringify(objectToStore));
      this.dismissedAlertsInSessionStorage = objectToStore;
    },
    filterAlertsToDisplay(activeAlertArray) {
      return this.isLegacyAlert
        ? this.filterLegacyAlerts(activeAlertArray)
        : this.filterSeparateAlerts(activeAlertArray);
    },
    filterLegacyAlerts(activeAlertArray) {
      return activeAlertArray.filter((alert) => {
        const isLegacyAlert = alert.enabled && !alert.secondary_organization_id;
        const shouldDisplay = this.isHomepageOrPreview || alert.display_on_all_pages;
        return isLegacyAlert && shouldDisplay;
      });
    },
    filterSeparateAlerts(activeAlertArray) {
      return this.isHomepageOrPreview
        ? activeAlertArray
        : activeAlertArray.filter((alert) => alert.display_location === 'all_pages');
    },
    getSessionStorage() {
      if (!process.client) return {};

      try {
        return JSON.parse(window.sessionStorage.getItem('thrillshare_alert_banner')) || {};
      } catch (error) {
        console.error('Error reading from sessionStorage:', error);
        return {};
      }
    },
  },
};
</script>
