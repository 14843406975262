import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBreadcrumbs, LazyBrowserWarning, LazyDSCLCopycatsTabs, LazyDSCLCmsModal, LazyDocumentBreadcrumbs, LazyErrorPage, LazyFiltersDropdown, LazyFormsFormButton, LazyFormsFormCheckbox, LazyFormsFormGroup, LazyFormsTextArea, LazyFormsTextInput, LazyLightbox, LazyLoadingIndicator, LazyPagination, LazyConstantsStaffConstants, LazySvgArrow, LazySvgArrowPointer, LazySvgButtonArrow, LazySvgButtonTopBar, LazySvgCaret, LazySvgChevron, LazySvgLightboxLeftArrow, LazySvgLightboxRightArrow, LazySvgModalCloseIcon, LazySvgPlusMinus, LazyTsCol, LazyTsDropdown, LazyTsRow } from '#components'
const lazyGlobalComponents = [
  ["Breadcrumbs", LazyBreadcrumbs],
["BrowserWarning", LazyBrowserWarning],
["DSCLCopycatsTabs", LazyDSCLCopycatsTabs],
["DSCLCmsModal", LazyDSCLCmsModal],
["DocumentBreadcrumbs", LazyDocumentBreadcrumbs],
["ErrorPage", LazyErrorPage],
["FiltersDropdown", LazyFiltersDropdown],
["FormsFormButton", LazyFormsFormButton],
["FormsFormCheckbox", LazyFormsFormCheckbox],
["FormsFormGroup", LazyFormsFormGroup],
["FormsTextArea", LazyFormsTextArea],
["FormsTextInput", LazyFormsTextInput],
["Lightbox", LazyLightbox],
["LoadingIndicator", LazyLoadingIndicator],
["Pagination", LazyPagination],
["ConstantsStaffConstants", LazyConstantsStaffConstants],
["SvgArrow", LazySvgArrow],
["SvgArrowPointer", LazySvgArrowPointer],
["SvgButtonArrow", LazySvgButtonArrow],
["SvgButtonTopBar", LazySvgButtonTopBar],
["SvgCaret", LazySvgCaret],
["SvgChevron", LazySvgChevron],
["SvgLightboxLeftArrow", LazySvgLightboxLeftArrow],
["SvgLightboxRightArrow", LazySvgLightboxRightArrow],
["SvgModalCloseIcon", LazySvgModalCloseIcon],
["SvgPlusMinus", LazySvgPlusMinus],
["TsCol", LazyTsCol],
["TsDropdown", LazyTsDropdown],
["TsRow", LazyTsRow],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
