export default defineNuxtPlugin(({ $intl, $appsignal, appsignal }) => {
  // get user's locale from auth
  const userLocale = 'en-US';

  // (optional) set error handler
  const customErrorHandler = (err) => {
    $appsignal?.sendError(err);
  };

  // the plugin will handle matching a locale if the given value is null or not supported,
  // using navigator.languages in a client env, and the ACCEPT-LANGUAGE header in a server env,
  // then falling back to the set default locale if none match
  $intl.$setLocale(userLocale, customErrorHandler);
});
