const background = {
  NONE: 'None',
  WAVY_LINES_1: 'Wavy Lines 1',
  WAVY_LINES_2: 'Wavy Lines 2',
  DOTS_DIVIDER: 'Dots Divider',
  LINES_DIVIDER: 'Lines Divider',
  DOTS_RECTANGLE: 'Dots Rectangle'
};

export default Object.freeze(background);
