<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6464 11.3536C12.8417 11.5488 13.1583 11.5488 13.3536 11.3536C13.5488 11.1583 13.5488 10.8417 13.3536 10.6464L12.6464 11.3536ZM8 6L8.35355 5.64645C8.15829 5.45118 7.84171 5.45118 7.64645 5.64645L8 6ZM2.64645 10.6464C2.45118 10.8417 2.45118 11.1583 2.64645 11.3536C2.84171 11.5488 3.15829 11.5488 3.35355 11.3536L2.64645 10.6464ZM13.3536 10.6464L8.35355 5.64645L7.64645 6.35355L12.6464 11.3536L13.3536 10.6464ZM7.64645 5.64645L2.64645 10.6464L3.35355 11.3536L8.35355 6.35355L7.64645 5.64645Z"
      :fill="fillColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'Chevron',
  props: {
    fillColor: {
      type: String,
      required: true
    }
  },
};
</script>

<style scoped>
  .chevron-closed {
    transform: rotate(180deg);
  }
  .chevron-open {
    transform: rotate(0deg);
  }
</style>
