<template>
  <div
    class="dots-rectangle"
    :class="flipHorizontal && 'flipped'"
  >
    <svg
      width="874"
      height="98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        v-for="(n, idx) in 13"
        :key="idx"
        x1="0"
        :y1="yOffset(idx)"
        x2="874"
        :y2="yOffset(idx)"
        :stroke="fillColor"
        stroke-dasharray="2, 6"
        stroke-width="2"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'DotsRectangle',
  props: {
    fillColor: {
      type: String,
      default: '#000',
    },
    flipHorizontal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    yOffset(idx) {
      return (idx * 8) + 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.dots-rectangle {
  margin: 48px auto 0 32px;
  padding-bottom: 6px;
  display: flex;
  width: 81%;
  height: auto;
  overflow: hidden;
  svg {
    max-width: 100%;
    overflow: hidden;
  }
  &.flipped {
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 32px;
  }
  @media screen and (max-width: 959px) {
    display: none;
  }
}
</style>
