const MINUTE_IN_MILLISECONDS = 60 * 1000;
const IN_MINUTES_THRESHOLD = 45;
const HOUR_IN_MINUTES = 60;
const DAY_IN_MINUTES = 24 * HOUR_IN_MINUTES; // 1440 minutes
const ONE_DAY_THRESHOLD = 42 * HOUR_IN_MINUTES; // 2520 minutes
const MONTH_IN_MINUTES = 30 * DAY_IN_MINUTES; // 43200 minutes
const YEAR_IN_MINUTES = 12 * MONTH_IN_MINUTES + 5 * DAY_IN_MINUTES; // 525600 minutes ('how do you measure a year in the life?')

/**
 * Returns a string representing the time elapsed since the given UTC timestamp.
 * @param {string} from - The UTC timestamp to calculate the elapsed time from.
 * @param {string} $t - The translation function to use for the returned string.
 * @returns {string} - A string representing the elapsed time since the given timestamp with 'ago' concatenated on.
 */
export const getTimeAgoStringFromUTC = (from, $t) => {
  const fromDate = new Date(from);
  const toDate = new Date();

  const totalMinutes = Math.floor((toDate - fromDate) / MINUTE_IN_MILLISECONDS);
  const distanceInMinutes = Math.abs(totalMinutes);
  return distanceOfTimeInWords(distanceInMinutes, $t);
};

/**
 * Returns a string representing the distance of time between two dates.
 * @param {Date} from - The date to calculate the distance of time from.
 * @param {string} $t - The translation function to use for the returned string.
 * @returns {string} - A string representing the distance of time between the two dates.
 */
const distanceOfTimeInWords = (distanceInMinutes, $t) => {
  if (distanceInMinutes === 0) {
    return $t('dateTime.distanceInWords.lessThanAMinute');
  }

  // less than 45 minutes, return 'X minutes ago'
  if (distanceInMinutes < IN_MINUTES_THRESHOLD) {
    return $t('dateTime.distanceInWords.xMinutes', { count: distanceInMinutes });
  }

  // less than 24 hours, return 'X hour(s) ago'
  if (distanceInMinutes < DAY_IN_MINUTES) {
    const count = Math.round(distanceInMinutes / HOUR_IN_MINUTES);
    return $t('dateTime.distanceInWords.aboutXHours', { count });
  }

  // less than 42 hours, return '1 day ago'
  if (distanceInMinutes < ONE_DAY_THRESHOLD) {
    return $t('dateTime.distanceInWords.xDays', { count: 1 });
  }

  // less than 30 days, return 'X days ago'
  if (distanceInMinutes < MONTH_IN_MINUTES) {
    const count = Math.round(distanceInMinutes / DAY_IN_MINUTES);
    return $t('dateTime.distanceInWords.xDays', { count });
  }

  // less than 2 months, return 'about X month(s) ago'
  if (distanceInMinutes < 2 * MONTH_IN_MINUTES) {
    const count = Math.round(distanceInMinutes / MONTH_IN_MINUTES);
    return $t('dateTime.distanceInWords.aboutXMonths', { count });
  }

  // less than 12 months, return 'X months ago'
  if (distanceInMinutes < YEAR_IN_MINUTES) {
    const count = Math.round(distanceInMinutes / MONTH_IN_MINUTES);
    return $t('dateTime.distanceInWords.xMonths', { count });
  }

  // over 12 months
  const MINUTES_IN_QUARTER_YEAR = 3 * MONTH_IN_MINUTES; // 129,600 minutes
  const MINUTES_IN_THREE_QUARTERS_YEAR = 9 * MONTH_IN_MINUTES; // 388,800 minutes

  const count = Math.floor(distanceInMinutes / YEAR_IN_MINUTES);
  const remainder = distanceInMinutes % YEAR_IN_MINUTES;

  // less than 3 months into year, return 'about X year(s) ago'
  if (remainder < MINUTES_IN_QUARTER_YEAR) {
    return $t('dateTime.distanceInWords.aboutXYears', { count });
  }

  // less than 9 months into year, return 'over X year(s) ago'
  if (remainder < MINUTES_IN_THREE_QUARTERS_YEAR) {
    return $t('dateTime.distanceInWords.overXYears', { count });
  }

  // over 9 months into year, return 'almost X + 1 years ago'
  return $t('dateTime.distanceInWords.almostXYears', { count: count + 1 });
};
