<template>
  <div>
    <cms-spotlight
      v-if="spotlightSetup"
      :theme="spotlightOptions"
      :content="homepage.spotlights[id]"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'ViewSpotlights',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview']),
    ...mapGetters(['getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation', 'getGlobalTranslations']),
    id() {
      return this.isThemePreview ? 'preview_id' : `${this.containerId}_${this.componentIdx}`;
    },
    spotlightOptions() {
      return {
        ...this.theme?.containers?.[this.containerIdx]?.components?.[this.componentIdx]?.options,
        translations: {
          ...this.getComponentTranslation('spotlight'),
          ...this.getGlobalTranslations,
        },
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    spotlightSetup() {
      const spotlight = this.homepage?.spotlights?.[this.id];
      return !isEmpty(spotlight?.content) || !isEmpty(spotlight?.nested_content);
    },
  },
};
</script>
