<template>
  <cms-stats
    v-if="statsSetUp"
    :theme="statOptions"
    :content="statsContentByVersion"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'ViewStats',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview', 'statsContent']),
    ...mapGetters(['getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    id() {
      return this.isThemePreview ? 'preview_id' : `${this.containerId}_${this.componentIdx}`;
    },
    statOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('stats'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    statsSetUp() {
      if (this.statOptions.version === 'S4') {
        return this.isThemePreview
          ? this.statsContent[this.statOptions.version].nested_content
          : !isEmpty(this.homepage?.stats?.[this.id]?.nested_content);
      }
      return !isEmpty(this.homepage?.stats?.[this.id]?.content);
    },
    statsContentByVersion() {
      return this.isThemePreview ? this.statsContent[this.statOptions.version] : this.homepage.stats[this.id];
    },
  },
};
</script>

<style></style>
