import {
  indexVuexStore,
  eventsindexVuexStore,
  staffindexVuexStore,
  translationindexVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      'events': {
        ...eventsindexVuexStore.default ?? eventsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'staff': {
        ...staffindexVuexStore.default ?? staffindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      'translation': {
        ...translationindexVuexStore.default ?? translationindexVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore