import debounce from 'lodash/debounce';

export default {
  data() {
    return {
      screenSize: '',
      debouncedSetScreenSize: () => {}
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setScreenSize();
    });
    this.debouncedSetScreenSize = debounce(this.setScreenSize, 100);
    window.addEventListener('resize', this.debouncedSetScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedSetScreenSize);
  },
  methods: {
    setScreenSize() {
      this.screenSize = document.documentElement.clientWidth;
    }
  }
};
