/* eslint-disable camelcase */

import * as Ics from 'ics';
import { stringToDate } from '../components/events/components/helpers/events.helper';

export const CALENDARS = Object.freeze({
  Google: 'google',
  Outlook: 'outlook',
  iCal: 'iCal',
});

const formatCalendarString = (string, replaceValue = '+') => encodeURIComponent(string).replace(/%20/g, replaceValue);
const formatDate = (date) => (date ? date.toISOString().replace(/-|:|\.\d+/g, '') : null);

const formatEventType = (date, allDay, isEndDate = false) => {
  if (isEndDate && allDay) date.setDate(date.getDate() + 1);
  if (!allDay) return formatDate(date);
  return formatDate(date).split('T')[0];
};

export const CALENDAR_LINK_BUILDERS = {
  [CALENDARS.Google]: {
    url: 'http://www.google.com/calendar/render?action=TEMPLATE&trp=false',
    parameters({ title, location, details, start_at, end_at, all_day }) {
      const parameters = {
        text: formatCalendarString(title),
        location: location ? formatCalendarString(location) : null,
        details: formatCalendarString(details),
      };

      if (start_at && end_at) {
        const start = formatEventType(stringToDate(start_at), all_day);
        const end = formatEventType(stringToDate(end_at), all_day, true);
        parameters.dates = `${start}/${end}`;
      }

      return parameters;
    },
  },
  [CALENDARS.Outlook]: {
    url: 'https://outlook.live.com/calendar/0/action/compose?',
    parameters({ title, location, details, start_at, end_at }) {
      return {
        subject: formatCalendarString(title, ' '),
        location: formatCalendarString(location, ' '),
        body: formatCalendarString(details, ' '),
        startdt: start_at,
        enddt: end_at,
      };
    },
  },
};

export const validate = (availableValues) => (propValue) => Object.values(availableValues).includes(propValue);

const buildTimeData = ({ start_at, end_at }) => {
  const start = stringToDate(start_at);
  const end = stringToDate(end_at);
  const [start_year, start_month, start_day, start_hour, start_minute] = [
    start.getFullYear(),
    start.getMonth() + 1,
    start.getDate(),
    start.getHours(),
    start.getMinutes(),
  ];
  const [end_year, end_month, end_day, end_hour, end_minute] = [
    end.getFullYear(),
    end.getMonth() + 1,
    end.getDate(),
    end.getHours(),
    end.getMinutes(),
  ];

  const diff = end - start;
  const durationInMinutes = Math.floor(diff / 60000);

  return {
    start_year,
    start_month,
    start_day,
    start_hour,
    start_minute,
    end_year,
    end_month,
    end_day,
    end_hour,
    end_minute,
    durationInMinutes,
  };
};

const buildGeo = ({ latitude, longitude }) => {
  if (!longitude || !latitude) {
    return {};
  }

  return {
    geo: { lat: Number.parseFloat(latitude), lon: Number.parseFloat(longitude) },
  };
};

const buildLocation = ({ venue, address, city_state, state, zip }) => {
  const location = [venue, address, city_state, state, zip].reduce((accum, partial) => {
    if (!partial) {
      return accum;
    }

    return accum ? `, ${partial}` : partial;
  }, null);

  return location ? { location } : {};
};

const buildCategories = ({ filter_name }) => (filter_name ? { categories: filter_name } : {});

export const buildICalEvent = (eventData) => {
  const { title, description, all_day } = eventData;
  const {
    start_year,
    start_month,
    start_day,
    start_hour,
    start_minute,
    end_year,
    end_month,
    end_day,
    end_hour,
    end_minute,
  } = buildTimeData(eventData);
  const location = buildLocation(eventData);
  const geo = buildGeo(eventData);
  const categories = buildCategories(eventData);
  const endDate = all_day
    ? { end: [end_year, end_month, end_day + 1] }
    : { end: [end_year, end_month, end_day, end_hour, end_minute] };

  return Ics.createEvent({
    start: [start_year, start_month, start_day, ...(all_day ? [] : [start_hour, start_minute])],
    ...endDate,
    title,
    description,
    ...(typeof window !== 'undefined' ? { url: window.location.href } : {}),
    ...location,
    ...geo,
    ...categories,
    status: 'CONFIRMED',
    busyStatus: 'BUSY',
  });
};

export const downloadToFile = (content, filename, contentType = 'text/calendar') => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
};

export const buildOnlineCalendarUrl = (calType, eventData) => {
  const { title, description, start_at, end_at, all_day } = eventData;
  let url = CALENDAR_LINK_BUILDERS[calType].url;
  const parameters = CALENDAR_LINK_BUILDERS[calType].parameters({
    title,
    details: description,
    start_at,
    end_at,
    all_day,
    ...buildLocation(eventData),
  });

  Object.entries(parameters).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    url += `&${key}=${value}`;
  });

  return url;
};

export const VIEW_TYPES = Object.freeze({
  MONTH: 'dayGridMonth',
  WEEK: 'timeGridWeek',
});
