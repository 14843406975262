<template>
  <div
    class="loader"
    :style="styles"
  />
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';

export default {
  name: 'LoadingIndicator',
  props: {
    size: {
      type: Number,
      default: 45,
    }
  },

  computed: {
    ...mapState(['globals']),
    styles() {
      return {
        '--spinner-width': `${this.size.toString()}px`,
        '--spinner-height': `${this.size.toString()}px`,
        '--spinner-border': `${(this.size * 0.1).toString()}px solid ${get(this.globals, 'info.primary_color.hex', '#000000')}80`,
        '--spinner-borderTop': `${(this.size * 0.1).toString()}px solid ${get(this.globals, 'info.primary_color.hex', '#000000')}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  margin: 20px auto;
  border-radius: 50%;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
  width: var(--spinner-width);
  height: var(--spinner-height);
  border: var(--spinner-border);
  border-top: var(--spinner-borderTop);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
