/**
 * @cookieString full cookie strings coming from the header
 * @cookieName name of the item you're looking for
 * @cookieDefault (optional) default value to be set for that cookie
 */
export function extractCookie(cookieString, cookieName, cookieDefault = null) {
  if (!cookieString) return null;

  try {
    return cookieString.split(';')
      .filter((str) => str.includes(cookieName))
      .reduce(
        (_, str) => str.trim().split('=')[1],
        cookieDefault
      );
  } catch (e) {
    console.error('Defaulting to Null - ', e.message);
    return null;
  }
}

export function removePrecedingSlash(url) {
  if (url && url.startsWith('/')) {
    return url.slice(1);
  }
  return url;
}

export function removeFacebookTrackingId(key, url) {
  if (url.indexOf('?') === -1 || url.indexOf(key) === -1) {
    return url;
  }

  const urlParts = url.split('?');
  const splitParams = urlParts[1].split('&');
  const refinedParams = splitParams.filter((param) => param.indexOf(key) !== 0).join('&');

  return refinedParams ? `${urlParts[0]}?${refinedParams}` : urlParts[0];
}
