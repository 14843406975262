import get from 'lodash/get';

/**
 * Formats the page title by combining the page name and a specified attribute from the source.
 *
 * @param {Object} source - The source object containing the attribute.
 * @param {string} attr - The attribute key to retrieve from the source object.
 * @param {string} pageName - The name of the page to include in the title.
 * @returns {string} The formatted page title. "pageName | source[attr]"
 */
export function formatPageTitle(source, attr, pageName) {
  return `${pageName} | ${get(source, attr, '')}`;
}

/**
 * Removes HTML tags from a given string.
 *
 * @param {string} [text=''] - The string from which HTML tags should be removed.
 * @returns {string} - The string without HTML tags.
 */
export const stripHtml = (text = '') => text.replace(/(<([^>]+)>)/gi, '');

/**
 * Replaces HTML entities in a string with their corresponding characters.
 *
 * @param {string} str - The string containing HTML entities.
 * @returns {string} - The string with HTML entities replaced by their corresponding characters.
 */
export const replaceHTMLEntities = (str = '') => {
  const entities = {
    '&amp;': '&',
    '&nbsp;': ' ',
    '&apos;': "'",
    '&#39;': "'",
    '&quot;': '"',
    '&#44;': ',',
    '&gt;': '>',
    '&lt;': '<',
  };
  const entityStrings = new RegExp(Object.keys(entities).join('|'), 'gi');
  return str.replace(entityStrings, (matched) => entities[matched.toLowerCase()]);
};
