<template>
  <div class="wavy-lines-2">
    <svg
      viewBox="0 0 1920 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          v-for="(path, idx) in paths"
          :key="idx"
          :transform="flipHorizontal && 'scale (-1, 1)'"
          :transform-origin="flipHorizontal && 'center'"
          fill-rule="evenodd"
          clip-rule="evenodd"
          :d="path"
          :fill="fillColor"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'WavyLines2',
  props: {
    fillColor: {
      type: String,
      default: '#000',
    },
    flipHorizontal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      paths: [
        'M-491.446 142.564C-235.954 227.827 58.4146 267.852 133.699 277.358C247.308 291.737 374.437 257.639 522.33 217.973C527.599 216.559 532.895 215.139 538.217 213.713C692.968 172.266 870.394 126.442 1078.77 123.723C1268.59 121.259 1371.22 174.149 1447.21 247.415C1484.08 282.967 1514.7 323.329 1545.98 364.573C1546.96 365.855 1547.93 367.139 1548.91 368.423C1581.2 410.989 1614.43 454.35 1656.14 494.092L1657.09 492.957C1615.48 453.305 1582.32 410.033 1550.01 367.462C1549.03 366.167 1548.05 364.872 1547.07 363.578C1515.8 322.36 1485.12 281.917 1448.17 246.285C1371.88 172.735 1268.9 119.738 1078.77 122.207C870.208 124.928 692.641 170.794 537.885 212.243C532.613 213.655 527.368 215.061 522.149 216.461C374.118 256.164 247.223 290.197 133.88 275.852C58.6066 266.347 -235.651 226.335 -491.009 141.116C-618.701 98.5022 -736.583 44.6105 -812.502 -22.3645C-850.456 -55.8475 -877.88 -92.5626 -890.826 -132.726C-903.766 -172.867 -902.267 -216.521 -882.267 -263.958L-883.562 -264.569C-903.699 -216.807 -905.237 -172.754 -892.17 -132.22C-879.112 -91.708 -851.488 -54.7799 -813.414 -21.1919C-737.277 45.9749 -619.179 99.936 -491.446 142.564Z',
        'M-472.512 139.83C-219.627 220.325 66.7 259.266 142.345 268.591C256.728 282.683 382.033 251.256 527.769 214.704C532.726 213.461 537.707 212.212 542.712 210.958C694.894 172.841 869.385 130.676 1076.91 128.967C1265.79 127.409 1371.06 178.349 1450.41 248.53C1489.56 283.16 1522.41 322.487 1555.9 362.576L1557.29 364.247C1591.26 404.902 1625.97 446.278 1668.61 484.176L1669.52 483.007C1626.97 445.189 1592.33 403.894 1558.36 363.231L1556.93 361.524C1523.47 321.46 1490.55 282.061 1451.32 247.358C1371.69 176.927 1266.08 125.891 1076.92 127.451C869.216 129.161 694.589 171.363 542.4 209.482C537.442 210.724 532.509 211.961 527.598 213.193C381.736 249.775 256.653 281.145 142.522 267.084C66.8937 257.761 -219.331 218.832 -472.093 138.377C-598.484 98.1455 -716.437 47.5523 -795.51 -14.8674C-835.044 -46.075 -864.814 -80.2041 -881.083 -117.425C-897.341 -154.622 -900.134 -194.946 -885.653 -238.628L-886.993 -239.12C-901.599 -195.059 -898.785 -154.324 -882.372 -116.772C-865.969 -79.2448 -836.003 -44.9366 -796.363 -13.6451C-717.088 48.9336 -598.944 99.586 -472.512 139.83Z',
        'M-453.58 137.059C-203.349 212.771 74.8889 250.613 150.986 259.748C266.155 273.554 389.59 244.831 533.151 211.424C537.791 210.344 542.452 209.26 547.134 208.171C696.727 173.405 868.286 134.918 1075.06 134.211C1262.98 133.523 1370.91 182.511 1453.58 249.617C1494.94 283.187 1529.99 321.302 1565.61 360.065L1565.98 360.471C1601.46 399.094 1637.52 438.342 1680.89 474.275L1681.77 473.074C1638.47 437.203 1602.47 398.015 1566.97 359.375L1566.63 358.998C1531.02 320.246 1495.9 282.053 1454.45 248.407C1371.51 181.081 1263.27 132.005 1075.07 132.694C868.133 133.402 696.446 171.921 546.845 206.69C542.206 207.768 537.589 208.843 532.992 209.912C389.315 243.345 266.09 272.019 151.159 258.241C75.084 249.109 -203.062 211.277 -453.179 135.6C-578.247 97.7584 -696.246 50.4713 -778.468 -7.38516C-860.72 -65.2625 -906.921 -133.522 -889.04 -213.316L-890.422 -213.654C-908.522 -132.882 -861.635 -64.0813 -779.259 -6.11667C-696.854 51.8687 -578.687 99.2055 -453.58 137.059Z',
        'M-434.714 134.341C-187.113 205.277 83.0825 242.035 159.632 250.981C275.574 264.552 397.246 238.525 538.782 208.248C543.025 207.341 547.286 206.429 551.565 205.515C698.614 174.095 867.285 139.263 1073.2 139.529C1260.27 139.702 1370.85 186.721 1456.85 250.733C1499.87 282.752 1536.76 319.034 1574.02 355.886L1576.22 358.058C1612.76 394.198 1649.77 430.815 1693.36 464.358L1694.2 463.125C1650.68 429.636 1613.72 393.073 1577.17 356.919L1574.99 354.769C1537.74 317.924 1500.79 281.573 1457.67 249.486C1371.41 185.284 1260.55 138.186 1073.22 138.012C867.148 137.747 698.357 172.606 551.299 204.028C547.06 204.934 542.839 205.837 538.635 206.736C396.996 237.034 275.52 263.019 159.801 249.473C83.2787 240.531 -186.834 203.783 -434.333 132.876C-558.088 97.4208 -676.14 53.4385 -761.503 0.149414C-846.923 -53.1754 -899.322 -115.631 -892.427 -187.952L-893.841 -188.091C-900.829 -114.794 -847.698 -51.895 -762.23 1.46027C-676.703 54.8512 -558.508 98.8747 -434.714 134.341Z',
        'M-415.759 131.566C-170.836 197.721 91.2711 233.381 168.273 242.137C285.079 255.464 404.923 232.146 544.374 205.013C548.23 204.263 552.101 203.51 555.988 202.754C700.447 174.675 866.184 143.503 1071.34 144.77C1257.55 145.842 1370.79 190.909 1460.12 251.835C1504.81 282.309 1543.53 316.759 1582.44 351.701C1583.82 352.942 1585.2 354.184 1586.59 355.426C1624.13 389.149 1662.05 423.215 1705.83 454.438L1706.63 453.174C1662.92 421.998 1625.05 387.98 1587.49 354.245C1586.11 353.009 1584.74 351.772 1583.36 350.537C1544.46 315.598 1505.67 281.088 1460.9 250.555C1371.32 189.466 1257.82 144.327 1071.37 143.254C866.065 141.986 700.215 173.181 555.747 201.262C551.896 202.011 548.061 202.757 544.241 203.5C404.698 230.65 285.036 253.933 168.437 240.629C91.4678 231.876 -170.565 196.226 -415.397 130.095C-537.818 97.0287 -655.898 56.3517 -744.386 7.62671C-788.63 -16.7361 -825.442 -43.0931 -851.698 -71.4892C-877.953 -99.8837 -893.611 -130.271 -895.65 -162.709L-897.068 -162.593C-894.999 -129.677 -879.115 -98.9708 -852.715 -70.4186C-826.316 -41.8679 -789.362 -15.4256 -745.047 8.97577C-656.418 57.779 -538.216 98.4892 -415.759 131.566Z',
        'M-396.827 128.767C-154.512 190.145 99.5535 224.72 176.915 233.295C294.593 246.342 412.594 225.755 549.956 201.791C553.428 201.185 556.912 200.577 560.41 199.968C702.279 175.239 865.085 147.745 1069.49 150.015C1352.3 152.965 1473.46 251.738 1597.25 352.657C1635.71 384.007 1674.42 415.564 1718.31 444.519L1719.06 443.227C1675.31 414.364 1636.68 382.866 1598.27 351.548C1474.41 250.57 1352.84 151.453 1069.52 148.498C1069.52 148.498 1069.52 148.498 1069.52 148.498C864.982 146.228 702.074 173.741 560.194 198.471C556.729 199.075 553.277 199.677 549.837 200.277C412.395 224.255 294.562 244.812 177.076 231.787C99.7505 223.216 -154.25 188.649 -396.486 127.291C-517.607 96.6117 -635.755 59.2417 -727.398 15.0887C-773.221 -6.98798 -812.39 -30.7482 -841.986 -56.1965C-871.589 -81.6501 -891.557 -108.744 -899.081 -137.473L-900.45 -137.051C-892.816 -107.9 -872.602 -80.5641 -842.885 -55.012C-813.162 -29.4545 -773.875 -5.63333 -727.994 16.4719C-636.23 60.6828 -517.982 98.0789 -396.827 128.767Z',
        'M-377.866 126.067C-138.229 182.664 107.748 216.141 185.561 224.526C304.101 237.344 420.353 219.481 555.766 198.675C558.78 198.212 561.803 197.747 564.837 197.281C704.163 175.89 864.081 152.052 1067.63 155.331C1350.38 159.686 1478.23 254.098 1607.63 349.649C1647.01 378.725 1686.53 407.908 1730.6 434.689L1731.31 433.37C1687.37 406.664 1647.92 377.531 1608.58 348.481C1479.12 252.882 1350.88 158.177 1067.67 153.815C863.997 150.534 703.984 174.387 564.649 195.781C561.644 196.242 558.649 196.702 555.664 197.161C420.181 217.978 304.082 235.816 185.717 223.017C107.945 214.637 -137.975 181.167 -377.546 124.586C-497.333 96.2947 -615.508 62.2308 -710.276 22.6453C-757.661 2.85242 -799.174 -18.313 -832.104 -40.8155C-865.044 -63.3246 -889.34 -87.1339 -902.362 -112.189L-903.607 -111.444C-890.422 -86.0754 -865.904 -62.1035 -832.879 -39.5361C-799.844 -16.9619 -758.239 4.24493 -710.806 24.0581C-615.939 63.6847 -497.683 97.7684 -377.866 126.067Z',
        'M-359.023 123.352C-121.994 175.172 115.942 207.565 194.206 215.76C313.808 228.35 428.339 213.23 561.895 195.599L569.264 194.626C706.001 176.584 862.986 156.407 1065.78 160.651C1348.63 166.356 1483.46 256.344 1618.49 346.458C1658.69 373.292 1698.92 400.137 1743.07 424.771L1743.74 423.426C1699.7 398.854 1659.54 372.049 1619.36 345.237C1484.29 255.083 1349.09 164.848 1065.82 159.135C862.921 154.888 705.851 175.078 569.104 193.121L561.811 194.084C428.195 211.723 313.803 226.824 194.358 214.251C116.138 206.06 -121.749 173.674 -358.724 121.866C-477.212 95.9619 -595.455 65.2058 -693.39 30.1967C-742.358 12.6922 -786.236 -5.87103 -822.524 -25.416C-858.82 -44.9657 -887.48 -65.4749 -906.049 -86.8533L-907.096 -85.8165C-888.363 -64.2493 -859.534 -43.6445 -823.174 -24.0609C-786.806 -4.47253 -742.86 14.1169 -693.854 31.6351C-595.841 66.6717 -477.537 97.4419 -359.023 123.352Z',
        'M-340.066 120.575C-105.716 167.615 124.132 198.91 202.847 206.915C323.537 219.241 436.373 206.88 568.104 192.448L573.753 191.83C707.924 177.136 861.978 160.638 1063.92 165.892C1346.74 172.957 1488.58 258.294 1629.22 342.905C1670.27 367.602 1711.22 392.238 1755.54 414.847L1756.17 413.477C1711.94 390.919 1671.05 366.316 1630.03 341.637C1489.35 256.994 1347.16 171.45 1063.97 164.376C1063.97 164.376 1063.97 164.376 1063.97 164.376C861.931 159.119 707.802 175.627 573.622 190.321L568.04 190.933C436.258 205.369 323.545 217.717 202.996 205.405C124.326 197.405 -105.479 166.117 -339.789 119.085C-456.944 95.5693 -575.212 68.1202 -676.272 37.6817C-777.357 7.23575 -861.141 -26.1777 -909.39 -61.5914L-910.203 -60.3392C-861.747 -24.773 -777.762 8.69348 -676.671 39.1414C-575.555 69.5968 -457.241 97.0555 -340.066 120.575Z',
        'M-321.134 117.778C-89.3934 160.04 132.415 190.251 211.487 198.074C333.359 210.196 444.56 200.601 574.685 189.372L578.104 189.077C709.709 177.723 860.876 164.881 1062.06 171.137C1344.52 179.64 1493.59 260.177 1639.73 339.132C1681.65 361.778 1723.32 384.294 1767.83 404.943L1768.41 403.551C1723.99 382.943 1682.36 360.453 1640.47 337.822C1494.3 258.842 1344.92 178.134 1062.12 169.621C860.849 163.362 709.617 176.211 578.004 187.566L574.643 187.856C444.476 199.088 333.382 208.674 211.633 196.564C132.607 188.745 -89.1649 158.541 -320.881 116.283C-552.627 74.0192 -794.203 19.7186 -912.912 -36.2686L-913.497 -34.8806C-794.6 21.1954 -552.844 75.5206 -321.134 117.778Z',
        'M-916.801 -9.37971C-634.934 72.8789 61.1223 174.043 220.134 189.307C343.521 201.2 453.219 194.386 582.008 186.387L582.526 186.355C711.542 178.341 859.776 169.16 1060.21 176.381C1342.19 186.311 1498.49 261.788 1650.05 334.979C1692.94 355.688 1735.44 376.214 1780.31 395.02L1780.84 393.608C1736.05 374.834 1693.59 354.329 1650.73 333.633C1499.13 260.422 1342.55 184.806 1060.27 174.865C1060.27 174.865 1060.27 174.865 1060.27 174.865C859.77 167.642 711.48 176.827 582.458 184.841L581.989 184.87C453.166 192.871 343.558 199.679 220.275 187.796C61.2906 172.535 -634.667 71.3806 -916.413 -10.8427L-916.801 -9.37971Z',
        'M-920.124 16.1019C-593.943 68.4288 68.8552 165.654 228.778 180.539C352.853 192.137 460.475 188.308 586.947 183.641L590.359 183.515C716.035 178.876 860.562 173.541 1058.35 181.699C1339.58 192.996 1503.16 263.201 1660.02 330.524C1703.99 349.395 1747.43 368.039 1792.78 385.096L1793.27 383.665C1747.98 366.634 1704.58 348.007 1660.64 329.146C1503.74 261.808 1339.91 191.491 1058.42 180.183C1058.42 180.183 1058.42 180.183 1058.42 180.183C860.579 172.023 716.01 177.36 590.329 181.999L586.913 182.125C460.433 186.792 352.893 190.617 228.916 179.028C69.0449 164.148 -593.708 66.9308 -919.899 14.6023L-920.124 16.1019Z',
        'M-50.5749 136.727C79.3423 153.402 184.266 166.869 237.604 171.68C362.296 182.996 467.665 182.189 591.55 180.863L601.484 180.756C723.058 179.446 863.146 177.937 1056.68 186.926C1336.7 199.595 1507.64 264.388 1669.6 325.78C1714.79 342.911 1759.28 359.777 1805.26 375.171L1805.69 373.724C1759.78 358.349 1715.32 341.497 1670.15 324.374C1508.17 262.971 1337.01 198.09 1056.75 185.411C863.184 176.421 723.065 177.93 601.488 179.24L591.55 179.347C467.663 180.673 362.356 181.478 237.739 170.169C184.425 165.36 79.5492 151.899 -50.3417 135.227C-116.295 126.762 -188.697 117.469 -264.073 108.061C-487.879 80.1255 -737.88 51.176 -923.008 39.8765L-923.104 41.3904C-738.023 52.687 -488.06 81.6315 -264.252 109.567C-188.905 118.972 -116.52 128.262 -50.5749 136.727Z',
        'M69.0026 142.796C146.554 152.129 208.206 159.548 246.061 162.854C371.366 173.852 474.529 176.062 595.85 178.084C603.28 178.208 610.778 178.331 618.354 178.456C734.475 180.363 868.782 182.569 1054.64 192.186C1332.85 206.237 1510.88 265.287 1677.6 320.584C1724.48 336.135 1770.47 351.389 1817.55 365.262L1817.94 363.8C1770.92 349.943 1724.96 334.699 1678.1 319.155C1511.36 263.85 1333.13 204.732 1054.72 190.671C868.841 181.053 734.513 178.847 618.39 176.939C610.815 176.815 603.317 176.692 595.887 176.568C474.568 174.546 371.446 172.336 246.191 161.343C208.39 158.041 146.792 150.629 69.2922 141.302C-184.552 110.754 -609.001 59.6748 -926.653 65.2811L-926.643 66.7973C-609.103 61.193 -184.864 112.246 69.0026 142.796Z',
        'M123.754 139.945C179.355 146.303 224.127 151.422 254.701 154.012C403.193 166.667 516.997 171.699 666.388 178.304C770.16 182.893 891.105 188.24 1052.78 197.429C1328.49 212.819 1513.45 265.931 1684.52 315.057C1733.56 329.137 1781.45 342.891 1830.02 355.335L1830.36 353.86C1781.85 341.428 1733.98 327.683 1684.96 313.607C1513.87 264.475 1328.75 211.314 1052.87 195.915C891.148 186.723 770.193 181.375 666.422 176.787C517.061 170.184 403.3 165.154 254.828 152.501C224.294 149.914 179.561 144.799 123.997 138.445C-115.86 111.019 -557.547 60.5132 -930.104 90.6722L-930.011 92.1843C-557.61 62.0379 -116.137 112.516 123.754 139.945Z',
        'M158.115 134.426C201.622 139.179 237.304 143.076 263.531 145.225C277.522 146.381 291.174 147.509 304.547 148.615C532.877 167.496 679.917 179.655 1051.11 202.728C1322.43 219.236 1513.31 266.042 1687.85 308.841C1740.29 321.701 1791.26 334.199 1842.49 345.404L1842.79 343.919C1791.6 332.723 1740.66 320.231 1688.24 307.376C1513.68 264.572 1322.66 217.73 1051.2 201.214C680.026 178.141 532.996 165.984 304.671 147.103C291.298 145.997 277.646 144.869 263.655 143.713C237.458 141.567 201.803 137.672 158.322 132.922C-69.1543 108.071 -510.856 59.817 -933.357 116.12L-933.195 117.625C-510.881 61.3465 -69.3956 109.573 158.115 134.426Z',
        'M183.391 127.732C219.019 131.453 248.816 134.566 271.988 136.4C374.383 144.558 456.901 153.47 544.537 162.936C565.646 165.215 587.051 167.527 609.103 169.868C722.707 181.929 853.448 194.765 1049.07 207.989C1314.03 225.546 1509.61 265.771 1686.45 302.142C1743.96 313.971 1799.49 325.392 1854.78 335.493L1855.04 333.998C1799.79 323.905 1744.28 312.489 1686.78 300.663C1509.93 264.289 1314.23 224.039 1049.17 206.475C853.573 193.253 722.849 180.419 609.258 168.36C587.21 166.019 565.808 163.707 544.701 161.428C457.062 151.962 374.524 143.048 272.107 134.888C248.957 133.055 219.179 129.944 183.567 126.224C-33.3457 103.565 -466.682 58.2982 -936.794 141.514L-936.575 143.01C-466.671 59.8308 -33.5472 105.072 183.391 127.732Z',
        'M204.045 120.466C234.117 123.473 259.762 126.037 280.634 127.632C392.855 136.283 479.25 148.377 573.922 161.629C586.958 163.454 600.152 165.301 613.591 167.164C724.63 182.555 852.438 199.035 1047.21 213.232C1301.01 231.439 1497.92 264.628 1674.45 294.382C1740.96 305.591 1804.58 316.314 1867.25 325.564L1867.46 324.061C1804.83 314.818 1741.24 304.099 1674.74 292.892C1498.19 263.136 1301.19 229.931 1047.32 211.719C852.587 197.525 724.809 181.049 613.788 165.66C600.35 163.797 587.158 161.951 574.122 160.126C479.45 146.873 393.015 134.774 280.75 126.12C259.893 124.526 234.26 121.962 204.197 118.956C-3.66583 98.1698 -423.299 56.2063 -940.22 166.982L-939.954 168.469C-423.255 57.7404 -3.8288 99.68 204.045 120.466Z',
        'M222.227 112.788C247.97 115.251 270.349 117.391 289.274 118.79C405.55 127.435 492.043 142.485 588.063 159.192C597.906 160.905 607.848 162.635 617.933 164.377C726.409 183.118 851.334 203.276 1045.35 218.476C1278.67 236.454 1469.83 262.137 1640.63 285.085C1724.37 296.335 1803.21 306.928 1879.73 315.633L1879.89 314.125C1803.42 305.425 1724.6 294.836 1640.88 283.587C1470.06 260.637 1278.83 234.944 1045.47 216.963C851.507 201.768 726.627 181.617 618.174 162.88C608.089 161.138 598.146 159.408 588.302 157.695C492.283 140.988 405.73 125.927 289.386 117.277C270.47 115.879 248.097 113.739 222.359 111.277C22.4974 92.1563 -380.31 53.6206 -943.649 192.375L-943.342 193.853C-380.233 55.1545 22.3735 93.6692 222.227 112.788Z',
        'M238.586 104.931C260.885 106.974 280.644 108.784 297.919 110.022C416.671 118.515 502.218 136.461 597.84 156.519C605.937 158.218 614.105 159.931 622.372 161.656C728.285 183.746 850.326 207.582 1043.68 223.777C1216.58 238.043 1369.63 253.748 1509 268.275C1519.67 269.388 1530.27 270.494 1540.78 271.591C1667.57 284.825 1783.1 296.882 1892.2 305.702L1892.32 304.19C1783.23 295.372 1667.72 283.315 1540.93 270.082C1530.42 268.984 1519.82 267.879 1509.15 266.766C1369.78 252.239 1216.72 236.532 1043.8 222.265C850.526 206.076 728.542 182.251 622.658 160.167C614.39 158.443 606.22 156.729 598.121 155.03C502.501 134.971 416.873 117.009 298.029 108.509C280.757 107.271 261 105.461 238.703 103.418C45.7616 85.7427 -337.418 50.6393 -946.885 217.826L-946.545 219.295C-337.311 52.1718 45.6744 87.2578 238.586 104.931Z',
        'M253.348 96.8215C272.955 98.5349 290.646 100.081 306.56 101.179C427.128 109.499 511.346 130.345 605.836 153.733C612.773 155.45 619.766 157.181 626.833 158.921C730.161 184.372 849.227 211.896 1041.64 229.11L1052.73 230.088C1391.72 259.971 1667.03 284.24 1904.49 295.86L1904.57 294.345C1667.14 282.727 1391.85 258.46 1052.84 228.575L1041.77 227.599C1041.77 227.599 1041.77 227.599 1041.77 227.599C849.452 210.393 730.458 182.886 627.166 157.444C620.096 155.702 613.1 153.971 606.159 152.253C511.671 128.865 427.353 107.994 306.666 99.6661C290.753 98.5676 273.062 97.0218 253.453 95.3085C66.4852 78.9716 -294.775 47.4056 -950.308 243.219L-949.938 244.679C-294.64 48.9359 66.4332 80.4881 253.348 96.8215Z',
        'M267.175 88.6609C284.547 90.1066 300.497 91.4339 315.206 92.4107C437.023 100.527 519.591 124.216 612.447 150.857C618.641 152.634 624.88 154.425 631.181 156.224C731.945 185.007 848.125 216.173 1039.78 234.353C1382.16 266.484 1673.35 281.52 1916.96 285.927L1917 284.41C1673.43 280.004 1382.27 264.971 1039.92 232.843C848.376 214.673 732.282 183.529 631.561 154.759C625.257 152.958 619.014 151.167 612.816 149.389C519.96 122.747 437.271 99.023 315.309 90.8972C300.616 89.9215 284.678 88.5952 267.314 87.1501C185.128 80.3106 70.9944 70.8123 -94.2405 83.251C-294.386 98.3177 -569.503 145.567 -953.724 268.686L-953.328 270.138C-569.192 147.046 -294.18 99.8213 -94.1549 84.7636C70.9659 72.3336 184.99 81.822 267.175 88.6609Z',
        'M280.75 80.3305C296.134 81.5483 310.495 82.6852 324.03 83.5512C447.087 91.4113 527.922 117.975 619.001 147.906C624.55 149.729 630.137 151.565 635.774 153.41C733.952 185.546 847.203 220.396 1038.11 239.579C1378.59 273.453 1682.08 278.942 1929.43 275.993L1929.43 274.477C1682.11 277.425 1378.67 271.937 1038.26 238.07C847.482 218.899 734.331 184.079 636.203 151.96C630.562 150.114 624.97 148.276 619.416 146.451C528.335 116.52 447.359 89.9087 324.13 82.0375C310.608 81.1724 296.256 80.0361 280.878 78.8187C200.048 72.4196 90.8726 63.7766 -75.082 80.455C-272.604 100.306 -550.553 156.024 -956.959 294.061L-956.541 295.506C-550.206 157.493 -272.36 101.803 -74.963 81.9648C90.8651 65.299 199.925 73.9323 280.75 80.3305Z',
        'M293.273 72.0266C306.99 73.0563 319.998 74.0327 332.492 74.8C456.536 82.4331 535.575 111.859 624.734 145.052C629.789 146.934 634.876 148.828 640.006 150.731C735.645 186.206 846.011 224.718 1036.07 244.914C1374.83 280.513 1690.81 276.363 1941.72 266.076L1941.68 264.561C1690.8 274.847 1374.9 278.994 1036.23 243.404C846.317 223.225 736.066 184.752 640.485 149.298C635.35 147.394 630.257 145.497 625.196 143.613C536.033 110.418 456.832 80.9315 332.589 73.2861C320.105 72.5195 307.104 71.5435 293.39 70.5141C213.675 64.53 109.879 56.7383 -56.4032 77.7412C-251.279 102.356 -531.968 166.516 -960.377 319.454L-959.938 320.891C-531.589 167.975 -250.999 103.846 -56.2503 79.2471C109.893 58.2617 213.567 66.0438 293.273 72.0266Z',
        'M305.663 63.5798C317.884 64.4471 329.646 65.2818 341.132 65.9579C466.215 73.3169 543.42 105.632 630.586 142.116C635.188 144.042 639.817 145.98 644.483 147.926C737.558 186.752 844.996 228.949 1034.21 250.157C1371.26 287.556 1699.54 273.784 1954.19 256.142L1954.11 254.629C1699.48 272.27 1371.3 286.035 1034.37 248.649C845.331 227.461 738.023 185.312 645.012 146.513C640.341 144.565 635.706 142.624 631.098 140.696C543.923 104.207 466.538 71.8163 341.225 64.4438C329.746 63.7681 317.989 62.9337 305.77 62.0666C226.964 56.4737 128.939 49.5169 -37.4995 74.97C-229.726 104.367 -513.2 176.991 -963.795 344.846L-963.339 346.277C-512.791 178.439 -229.413 105.848 -37.3119 76.471C128.976 51.041 226.873 57.9885 305.663 63.5798Z',
        'M317.978 55.0723C328.849 55.7995 339.453 56.509 349.956 57.1008C475.918 64.2094 551.138 99.3963 636.104 139.142C640.379 141.142 644.678 143.153 649.01 145.173C739.477 187.354 843.984 233.219 1032.54 255.385C1367.69 294.527 1708.45 271.264 1966.66 246.209L1966.54 244.698C1708.36 269.752 1367.71 293.003 1032.71 253.878C844.348 231.735 739.985 185.93 649.59 143.782C645.252 141.76 640.947 139.746 636.666 137.743C551.688 97.991 476.268 62.7097 350.046 55.5865C339.548 54.995 328.947 54.2857 318.078 53.5586C239.942 48.3312 147.99 42.1795 -18.4796 72.1904C-208.079 106.371 -494.338 187.458 -967.027 370.223L-966.555 371.648C-493.901 188.897 -207.733 107.844 -18.2568 73.6855C148.049 43.7041 239.868 49.8467 317.978 55.0723Z',
        'M329.833 46.6065C339.496 47.2148 349.039 47.8154 358.602 48.3305C485.38 55.1612 558.673 93.2171 641.51 136.228C645.456 138.277 649.424 140.337 653.421 142.406C741.347 187.919 842.97 237.448 1030.68 260.626C1364.11 301.568 1717.18 268.682 1979.13 236.271L1978.98 234.765C1717.05 267.173 1364.12 300.041 1030.86 259.12C843.363 235.968 741.899 186.513 654.052 141.04C650.049 138.968 646.075 136.905 642.122 134.852C559.27 91.833 485.756 53.6624 358.688 46.816C349.129 46.3011 339.588 45.7007 329.926 45.0925C252.235 40.2026 166.68 34.8177 0.359314 69.4703C-186.612 108.425 -475.655 197.976 -970.437 395.687L-969.951 397.106C-475.193 199.405 -186.236 109.889 0.617981 70.9585C166.763 36.3426 252.178 41.7188 329.833 46.6065Z',
        'M341.443 38.1279C349.961 38.6318 358.463 39.1349 367.064 39.5782C494.692 46.1542 565.993 87.0956 646.597 133.379C650.261 135.482 653.944 137.597 657.652 139.721C742.992 188.579 841.684 241.777 1028.64 265.96C1360.36 308.628 1725.91 266.101 1991.41 226.352L1991.23 224.85C1725.74 264.597 1360.35 307.097 1028.83 264.454C842.108 240.303 743.589 187.192 658.334 138.383C654.62 136.256 650.93 134.138 647.26 132.03C566.637 85.735 495.098 44.6562 367.147 38.0636C358.549 37.6204 350.049 37.1175 341.532 36.6137C264.082 32.0318 185.274 27.3695 19.1773 66.7822C-165.141 110.518 -456.97 208.532 -973.846 421.152L-973.347 422.566C-456.484 209.952 -164.737 111.973 19.4724 68.2623C185.381 28.8943 264.044 33.5485 341.443 38.1279Z',
        'M352.964 29.5273C360.458 29.9423 368.008 30.3603 375.704 30.7383C504.066 37.0296 573.392 80.8741 651.775 130.447C655.185 132.603 658.612 134.771 662.062 136.946C744.862 189.164 840.671 246.047 1026.78 271.205C1356.79 315.673 1734.63 263.521 2003.88 216.417L2003.66 214.921C1734.42 262.023 1356.76 314.139 1026.97 269.701C841.125 244.578 745.504 187.8 662.794 135.639C659.339 133.459 655.906 131.288 652.489 129.128C574.083 79.5395 504.501 35.5321 375.784 29.2234C368.101 28.8462 360.561 28.4286 353.072 28.0139C318.07 26.0754 284.203 24.1999 242.33 26.8057C191.492 29.9694 128.861 39.7368 38.0096 63.9936C-143.679 112.504 -438.291 218.979 -977.261 446.545L-976.75 447.955C-437.784 220.39 -143.248 113.948 38.3414 65.4645C129.129 41.2246 191.67 31.4764 242.399 28.3195C284.187 25.719 317.967 27.5894 352.964 29.5273Z',
        'M364.617 20.8831C371.136 21.22 377.751 21.5618 384.529 21.8782C513.697 27.9104 580.99 74.6524 657.08 127.504C660.255 129.71 663.446 131.926 666.657 134.15C746.869 189.714 839.748 250.268 1025.11 276.43C1353.21 322.641 1743.35 260.939 2016.34 206.48L2016.1 204.989C1743.11 259.447 1353.16 321.104 1025.31 274.927C840.234 248.805 747.556 188.374 667.439 132.876C664.223 130.648 661.026 128.428 657.845 126.218C581.728 73.3461 514.161 26.4136 384.606 20.3633C377.839 20.0474 371.231 19.7058 364.716 19.369C329.05 17.5256 296.165 15.8259 255.119 19.2594C206.572 23.3206 146.622 34.5601 57.0978 61.2072C-121.936 114.497 -419.333 229.435 -980.491 471.919L-979.969 473.324C-418.806 230.838 -121.48 115.932 57.4669 62.6676C146.933 36.0376 206.788 24.8223 255.216 20.7711C296.165 17.3457 328.954 19.0401 364.617 20.8831Z',
        'M375.787 12.3212C381.394 12.5911 387.112 12.8663 392.99 13.1267C522.951 18.8628 588.239 68.5074 662.061 124.642C665.018 126.891 667.989 129.15 670.978 131.417C748.649 190.322 838.642 254.544 1023.25 281.674C1349.63 329.685 1752.26 258.341 2028.81 196.544L2028.53 195.06C1751.99 256.855 1349.58 328.144 1023.46 280.171C839.16 253.087 749.381 189.008 671.809 130.178C668.815 127.907 665.838 125.643 662.875 123.39C589.022 67.2315 523.446 17.3663 393.063 11.6116C387.193 11.3516 381.478 11.0764 375.874 10.8065C339.432 9.052 307.618 7.5202 267.517 11.8139C221.245 16.7683 163.959 29.4749 75.7513 58.5084C-100.651 116.571 -400.831 239.97 -984.083 497.401L-983.551 498.801C-400.287 241.365 -100.17 117.995 76.1575 59.957C164.315 30.9401 221.502 18.2633 267.644 13.3228C307.636 9.04071 339.345 10.567 375.787 12.3212Z',
        'M387.149 3.64835C391.858 3.85959 396.675 4.07568 401.63 4.28415C469.497 7.13174 519.158 22.6836 561.213 45.2747C600.168 66.2012 632.612 93.173 666.998 121.759C669.751 124.048 672.516 126.347 675.298 128.654C750.381 190.914 837.444 258.827 1021.21 287.007C1345.88 336.746 1760.8 255.775 2041.09 186.623L2040.78 185.146C1760.49 254.297 1345.8 335.2 1021.43 285.506C837.995 257.377 751.158 189.629 676.177 127.453C673.39 125.142 670.619 122.838 667.859 120.544C633.494 91.9735 600.946 64.9143 561.862 43.9189C519.62 21.2271 469.76 5.62463 401.701 2.76889C396.748 2.56058 391.931 2.34442 387.222 2.13309C349.884 0.457581 319.244 -0.917328 280.199 4.27887C236.23 10.1303 181.625 24.3097 94.7467 55.7324C-79.0003 118.574 -381.963 250.435 -987.312 522.775L-986.771 524.171C-381.404 251.823 -78.4957 119.988 95.1906 57.1681C182.029 25.7599 236.53 11.6167 280.361 5.7836C319.282 0.603851 349.805 1.97311 387.149 3.64835Z'
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
  .wavy-lines-2 {
    width: 100%;
    height: auto;
    margin-bottom: -6px;
    margin-top: -143px;
    @media (min-width: 1600px) {
      margin-top: -226px;
    }
  }
</style>
