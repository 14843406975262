<template>
  <div
    id="filter-container"
    v-click-outside="() => showOptions = false"
  >
    <button
      ref="filterInput"
      class="filter-input"
      aria-haspopup="listbox"
      :data-testid="`filter-trigger-${name}`"
      @click="handleFocusIntoDropdown"
      @keydown.enter.exact.prevent="handleFocusIntoDropdown"
      @keydown.esc.exact.prevent="showOptions = false"
      @keydown.shift.tab.exact="showOptions = false"
      @keydown.down.exact.prevent="handleFocusIntoDropdown"
    >
      <span
        v-if="activeFilters.length"
        class="filter-circle-counter"
      >
        {{ activeFilters.length }}
      </span>
      {{ selectPlaceholder }}
    </button>
    <div
      v-if="showOptions"
      :aria-activedescendant="activeDescendantId"
      role="listbox"
      class="filter-options"
      tabindex="0"
      aria-multiselectable="true"
    >
      <div
        v-for="(filter) in filters"
        :id="filter.id"
        ref="filterOptions"
        :key="filter.id"
        :aria-selected="String(activeFilters.includes(filter.id))"
        class="filter-option"
        role="option"
        tabindex="-1"
        @keydown.enter.exact.prevent="onFilterChange(filter.id)"
        @keydown.space.exact.prevent="onFilterChange(filter.id)"
        @keydown.esc.exact.prevent="showOptions = false"
        @keydown.tab.exact="showOptions = false"
        @keydown.shift.tab.exact.prevent="$refs.filterInput.focus()"
        @keydown="navigateOptions"
        @click.prevent="onFilterChange(filter.id)"
      >
        <label
          :for="`${filter.id}-input`"
          class="container"
        >
          {{ filter.name }}
          <input
            :id="`${filter.id}-input`"
            :checked="activeFilters.includes(filter.id)"
            :name="filter.name"
            type="checkbox"
          >
          <span
            class="checkmark"
            :data-testid="`filter-item-${filter.id}`"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterDropdown',
  props: {
    name: {
      type: String,
      default: ''
    },
    filters: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showOptions: false,
      activeFilters: [],
      activeDescendantId: '',
    };
  },
  computed: {
    selectPlaceholder() {
      if (this.placeholder === 'Sports') {
        return this.activeFilters.length > 0 ? this.$t('pages.athletics.selectedSports') : this.$t('pages.athletics.selectSports');
      }
      if (this.placeholder === 'departments') {
        return this.activeFilters.length > 0 ? this.$t('pages.staff.selectedDepartments') : this.$t('pages.staff.selectDepartments');
      }
      return `${this.activeFilters.length > 0 ? this.$t('pages.shared.selected') : this.$t('pages.shared.select')} ${this.placeholder}`;
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.filter_ids) {
      this.activeFilters = this.$route.query.filter_ids.split ? this.$route.query.filter_ids.split(',').map((x) => +x) : this.$route.query.filter_ids.map((x) => +x);
    } else if (this.$route.query && this.$route.query.section_ids) {
      this.activeFilters = this.$route.query.section_ids.split(',').map((x) => +x);
    }
  },
  methods: {
    navigateOptions(evt) {
      switch (evt.key) {
        case 'ArrowUp':
          evt.preventDefault();
          if (evt.currentTarget.previousElementSibling) {
            this.activeDescendantId = evt.currentTarget.previousElementSibling.id;
            evt.currentTarget.previousElementSibling.focus();
          }
          break;
        case 'ArrowDown':
          evt.preventDefault();
          if (evt.currentTarget.nextElementSibling) {
            this.activeDescendantId = evt.currentTarget.nextElementSibling.id;
            evt.currentTarget.nextElementSibling.focus();
          }
          break;
        default:
      }
    },
    onFilterChange(filterId) {
      const checked = this.activeFilters.includes(filterId);
      if (checked) {
        this.activeFilters.splice(this.activeFilters.indexOf(filterId), 1);
      } else {
        this.activeFilters.push(filterId);
      }
      this.activeDescendantId = filterId;
      this.$emit('onFilter', this.activeFilters);
    },
    handleFocusIntoDropdown(e) {
      this.showOptions = !this.showOptions;
      if (!this.showOptions) return;
      e.preventDefault();
      const itemsSelected = !!this.activeFilters.length;
      this.$nextTick(() => {
        const firstFocusableElement = itemsSelected ? this.$el.querySelector('[aria-selected="true"]') : this.$refs.filterOptions[0];
        firstFocusableElement.focus();
        this.activeDescendantId = firstFocusableElement.id;
      });
    }
  }
};
</script>

<style scoped lang="scss">
  #filter-container {
    width: auto;
    float: right;
    outline: none;
    position: relative;
    .filter-input {
      line-height: 22px;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 250px;
      padding: 5px 8px;
      padding-right: 12px;
      border: none;
      appearance: none;
      background: url('../../assets/small_arrow_down.png') no-repeat 100% 50%;
      background-color: #FBFBFB;
      background-origin: content-box;
      font-size: 16px;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: center;
      .filter-circle-counter {
        border: 1px solid var(--primary-color);
        border-radius: 14px;
        margin-right: 5px;
        padding: 2px 8px;
        text-align: center;
        color: var(--primary-color);
      }
    }
    .filter-options {
      z-index: 1;
      max-height: 400px;
      overflow-y: auto;
      position: absolute;
      background: #FFFFFF;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 100%;
      height: auto;
      outline: none;
      .filter-option {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .container {
          outline: none;
          display: block;
          position: relative;
          padding-left: 25px;
          margin-bottom: 12px;
          margin-top: 12px;
          margin-left: 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.4px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: #FFFFFF;
            border: 1px solid var(--primary-color);
            border-radius: 2px;
            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 5px;
              top: 1px;
              width: 3px;
              height: 8px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
        .container input:checked ~ .checkmark {
          background-color: var(--primary-color);
        }
        .container input:checked ~ .checkmark:after {
          display: block;
        }
      }
    }
  }
</style>
