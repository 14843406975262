<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div
      v-else-if="!!content.length"
      id="news-container"
    >
      <news
        :theme="themeSettings"
        :content="content"
        :news-config="config"
        :component-id="componentId"
        :path-prefix="pathPrefix"
        :fallback-image="fallbackImage"
        :is-preview="isThemePreview"
      />
      <DotsRectangle
        v-if="dotsRectangle.show"
        :fill-color="dotsRectangle.color"
        :flip-horizontal="dotsRectangle.flip"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LoadingIndicator from '../../global/LoadingIndicator.vue';
import DotsRectangle from '../theme_accents/svg/DotsRectangle.vue';
import HomepageFetchHelper from '../../../helpers/homepage-fetcher.js';

export default {
  name: 'ViewNews',
  components: {
    LoadingIndicator,
    DotsRectangle,
  },
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    dotsRectangle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      content: [],
      config: {},
    };
  },
  computed: {
    ...mapState([
      'homepage',
      'theme',
      'pathPrefix',
      'isThemePreview',
      'featureFlags',
      'settings',
      'staticFilesConfig',
      'mediaPreviewContent',
      'globals',
    ]),
    ...mapGetters(['getNewsApi', 'getS3UrlForMedia', 'getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    defaultData() {
      return this.mediaPreviewContent?.newsPreviewContent || [];
    },
    themeSettings() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('news'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    componentId() {
      if (this.isThemePreview) {
        return 'preview_id';
      }

      return `${this.containerId}_${this.componentIdx}`;
    },
    useS3ForMedia() {
      return this.featureFlags.cms_stored_data_news_enabled;
    },
    fallbackImage() {
      const newsDefaultFallbackImage = this.globals?.fallback_images?.find((item) => item.feature === 'news') || {};
      const settings = this.settings || {};
      const newsFallbackImage = settings.newsFallbackImage || {};
      return Object.keys(newsFallbackImage).length === 0 ? newsDefaultFallbackImage : newsFallbackImage;
    },
  },
  mounted() {
    this.config = this.homepage.news || {};
    this.getContent();
  },
  methods: {
    async getContent() {
      if (this.isThemePreview) {
        this.setupThemePreview();
        return;
      }

      if (this.useS3ForMedia) {
        this.content = await this.fetchContentFromS3AndValidate();
      } else {
        this.content = await this.fetchContentFromThrillshare();
      }
      this.loading = false;
    },
    setupThemePreview() {
      this.content = this.defaultData;
      this.loading = false;
    },
    async fetchContentFromS3AndValidate() {
      try {
        const s3UrlForContent = this.getS3UrlForMedia('news');
        const contentResponse = await this.$staticFetch.get(s3UrlForContent);

        const lastS3FileUpdate = contentResponse.data?.meta?.last_static_update;
        const lastMediaUpdate =
          this.staticFilesConfig?.last_static_update?.news ||
          this.staticFilesConfig?.homepage?.last_static_update?.news;
        const isStaticDataFresh = HomepageFetchHelper.validateLastStaticUpdate(lastS3FileUpdate, lastMediaUpdate);
        const newsArticles = contentResponse?.data?.articles;

        if (!isStaticDataFresh || !newsArticles) {
          return await this.fetchContentFromThrillshare();
        }

        return newsArticles;
      } catch (e) {
        console.error(e);
        return await this.fetchContentFromThrillshare();
      }
    },
    async fetchContentFromThrillshare() {
      try {
        const { url, params } = this.getNewsApi(this.$axios.defaults.baseURL);
        const contentResponse = await this.$axios.get(url, { params, cache: { ignoreCache: false } });
        return contentResponse?.data?.articles || [];
      } catch (e) {
        console.error(e);
        return [];
      }
    },
  },
};
</script>

<style>
#news-container {
  height: 100%;
}
</style>
