<template>
  <div class="result">
    <div class="link">
      {{ link }}
    </div>
    <div class="title">
      <a :href="link">
        {{ item.name }}
      </a>
    </div>
    <p class="content">
      {{ item.snippet }}
    </p>
    <p
      v-if="!searchCurrentSiteVal"
      class="org-name"
    >
      {{ item.secondary_organization.name }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CMSPagePreview',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isMobile: Boolean,
    searchCurrentSiteVal: Boolean,
  },
  computed: {
    link() {
      return `${this.item.link || ''}/page/${this.item.slug}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../global/scss/mixins/_breakpoints.scss';

.result {
  margin-right: 24px;
  .link {
    color: #747474;
    font-size: 14px;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.4px;
    margin: 20px 0 16px 12px;
    a {
      color: var(--link-color);
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
  }
  .content,
  .org-name,
  .link {
    letter-spacing: 0.4px;
    margin-left: 12px;
    font-weight: 450;
  }
  .content {
    color: #333;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .org-name {
    color: #333;
    font-size: 14px;
    line-height: 12px;
  }
}
@include screen('xs') {
  .result {
    .title {
      margin: 20px 0 8px 12px;
    }
    .content {
      margin-bottom: 24px;
    }
  }
}
</style>
