<template>
  <div>
    <cms-timeline
      v-if="timelineSetUp"
      :theme="timelineOptions"
      :timeline="homepage.timeline[id]"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'ViewTimeline',
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['homepage', 'theme', 'isThemePreview']),
    ...mapGetters(['getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation', 'getGlobalTranslations']),
    id() {
      if (this.isThemePreview) {
        return 'preview_id';
      }
      return `${this.containerId}_${this.componentIdx}`;
    },
    timelineOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: {
          ...this.getComponentTranslation('spotlight'),
          ...this.getGlobalTranslations,
        },
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    timelineSetUp() {
      return !isEmpty(this.homepage?.timeline?.[this.id]?.items);
    },
  },
};
</script>
