import tinycolor from 'tinycolor2';

/**
 * Converts a hexadecimal color code to RGB format.
 *
 * @param {string} hexCode - The hexadecimal color code to convert.
 * @returns {Object|null} An object containing the RGB values (r, g, b) if the conversion is successful, or null if the input is invalid.
 */
export const hexToRgb = (hexCode) => {
  const color = tinycolor(hexCode).toRgb();
  if (color.r === undefined || color.g === undefined || color.b === undefined) {
    return null;
  }
  return { r: color.r, g: color.g, b: color.b };
};

/**
 * Converts RGB color values to a hexadecimal color code.
 *
 * @param {Object} color - An object containing the RGB color values.
 * @param {number} color.r - The red color value (0-255).
 * @param {number} color.g - The green color value (0-255).
 * @param {number} color.b - The blue color value (0-255).
 * @returns {string} The hexadecimal color code.
 */
export const rgbToHex = ({ r, g, b }) => {
  return tinycolor({ r, g, b }).toHexString();
};

/**
 * Blend RGBA to RGB.
 * @param {Object} blendingColor - RGBA color wanted to transform to regular RBG with no alpha.
 * @param {Object} bgColor - RGB background color code underneath desired blending color.
 */
export const blendAlpha = ({ r, g, b, a = 0 }, asHex = false, bgColor = { r: 255, g: 255, b: 255 }) => {
  const alpha = 1 - a;
  const result = {
    r: Math.round((a * (r / 255) + alpha * (bgColor.r / 255)) * 255),
    g: Math.round((a * (g / 255) + alpha * (bgColor.g / 255)) * 255),
    b: Math.round((a * (b / 255) + alpha * (bgColor.b / 255)) * 255),
  };

  if (asHex) {
    return rgbToHex(result);
  }

  return result;
};

/**
 * Calculates the contrast color based on the given hex color.
 *
 * @param {string} hex - The hex color value.
 * @returns {string} The contrast color (either '#ffffff' or '#000000').
 */
export const getContrastColor = (hex) => {
  const color = tinycolor(hex);
  const bestADAColorContrast = tinycolor.mostReadable(color, ['#ffffff', '#000000']);
  return bestADAColorContrast.toHexString();
};
