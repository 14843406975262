<template>
  <cms-custom-component
    :theme="themeOptions"
    :content="content"
  >
    <template #input="{ customName, link, inLoop, loopName, loopIndex }">
      <a
        v-if="link && getCustomValue(inLoop, loopName, loopIndex, 'input', customName, 'href')"
        :href="getCustomValue(inLoop, loopName, loopIndex, 'input', customName, 'href')"
        :target="getCustomValue(inLoop, loopName, loopIndex, 'input', customName, 'target')"
        >{{ getCustomValue(inLoop, loopName, loopIndex, 'input', customName, 'text') || '' }}</a
      >
      <template v-else>{{ getCustomValue(inLoop, loopName, loopIndex, 'input', customName, 'text') || '' }}</template>
    </template>
    <template #textarea="{ customName, link, inLoop, loopName, loopIndex }">
      <a
        v-if="link && getCustomValue(inLoop, loopName, loopIndex, 'textarea', customName, 'href')"
        :href="getCustomValue(inLoop, loopName, loopIndex, 'textarea', customName, 'href')"
        :target="getCustomValue(inLoop, loopName, loopIndex, 'textarea', customName, 'target')"
        >{{ getCustomValue(inLoop, loopName, loopIndex, 'textarea', customName, 'text') }}</a
      >
      <template v-else>{{ getCustomValue(inLoop, loopName, loopIndex, 'textarea', customName, 'text') }}</template>
    </template>
    <template #richtextarea="{ customName, inLoop, loopName, loopIndex }">
      <span v-html="getCustomValue(inLoop, loopName, loopIndex, 'rich_textarea', customName, 'text')" />
    </template>
    <template #image="{ customName, link, aspectRatio, inLoop, loopName, loopIndex }">
      <a
        v-if="link && getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'href')"
        :href="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'href')"
        :target="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'target')"
      >
        <img
          :src="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'url')"
          :alt="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'alt_text')"
          :style="{ aspectRatio }"
        />
      </a>
      <img
        v-else
        :src="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'url')"
        :alt="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'alt_text')"
        :style="{ aspectRatio }"
      />
    </template>
    <template #icon="{ customName, link, inLoop, loopName, loopIndex }">
      <a
        v-if="link && getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'href')"
        :href="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'href')"
        :target="getCustomValue(inLoop, loopName, loopIndex, 'image', customName, 'target')"
      >
        <span v-html="getCustomValue(inLoop, loopName, loopIndex, 'icon', customName, 'icon')" />
      </a>
      <span
        v-else
        v-html="getCustomValue(inLoop, loopName, loopIndex, 'icon', customName, 'icon')"
      />
    </template>
  </cms-custom-component>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';

export default {
  name: 'ViewCustomComponent',
  props: {
    containerId: {
      type: String,
      default: null,
    },
    containerIdx: {
      type: Number,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview']),
    ...mapGetters('translation', ['getComponentTranslation']),
    id() {
      return `${this.containerId}_${this.componentIdx}`;
    },
    themeOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('custom_component'),
      };
    },
    content() {
      return this.isThemePreview ? this.homepage.custom_component.preview_id : this.homepage.custom_component[this.id];
    },
  },
  methods: {
    getCustomValue(inLoop, loopName, loopIndex, type, name, field) {
      const paramsObj = { inLoop, loopName, loopIndex, type, name, field };
      const sanitizedName = this.sanitizeName(paramsObj);
      const existingFields = this.getExistingFields(paramsObj);
      return get(existingFields, `${type}.${sanitizedName}.${field}`, '');
    },
    sanitizeName({ inLoop, type, name, loopIndex }) {
      if (!this.isThemePreview) return name;
      if (inLoop && loopIndex === undefined) return 'cms-loop';
      return `cms-${type}`;
    },
    getExistingFields({ inLoop, loopName, loopIndex }) {
      if (!inLoop) return this.content;
      const loopedContent = this.isThemePreview
        ? this.content.loop['cms-loop'][0]
        : this.content.loop[loopName]?.[loopIndex];
      return loopedContent;
    },
  },
};
</script>
